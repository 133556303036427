import React, { useState, useRef, useContext } from "react";
import MaterialTable, { Options } from "material-table";
import { withRouter } from "react-router";
import { Company } from "../models/Company";
import { deleteCompany } from "../services/admin.service";
import { loadItems, useTitle } from "../store/utils";
import * as admin from "../services/admin.service";
import { routeTo, urls } from "../navigation";
import { editDeleteColumn, withoutSorting } from "../components/edit-delete-column";
import { translations } from "../translates";
import { PagingContext } from "../contexts";
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             padding: "2px 4px",
//             display: "flex",
//             alignItems: "center",
//             width: 400,
//         },
//         input: {
//             marginLeft: theme.spacing(1),
//             flex: 1,
//         },
//         iconButton: {
//             padding: 10,
//         },
//     }),
// );
export default withRouter(function (p) {
    // const classes = useStyles();
    useTitle("Companies");
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: true,
        searchFieldStyle: {
            display: "none"
        },
        pageSize: pagingContext.companyPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    const tableRef = useRef<any>(null);
    const trans = translations.companyList;
    async function removeCompany(r: Company) {
        const confirmation = window.confirm(translations.confirm(r.name));
        if (confirmation) {
            await deleteCompany(r.id);
        }
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
    }

    return <div className="ArtGallery-List-CompanyList table-list-pagination-bottom">
        <MaterialTable<Company>
            tableRef={tableRef}
            title="Owners"
            options={pagination}
            columns={[
                ...withoutSorting<Company>(
                    { title: trans.name, field: "name" },
                    { title: trans.country, render: r => r.country && r.country.name, field: "country.name" },
                ),
                editDeleteColumn<Company>({
                    onEdit: r => routeTo(p, urls.companyEdit, { name: r.name.replace(" ", "-"), id: r.id }),
                    onDelete: async r => {
                        await removeCompany(r);
                    },
                }), 
            ]}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, companyPage: { ...pagingContext.companyPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, companyPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e })
            }}
            data={e => loadItems(admin.searchCompany)({ ...e, page: pagingContext.companyPage.page })}
            actions={[{
                icon: "delete",
                tooltip: "Delete selected Items",
                onClick: async (event, rowData) => {
                    const confirmation = window.confirm("Are you sure you want to delete the selected items?");
                    if (confirmation && Array.isArray(rowData)) {
                        for (const i of rowData) {
                            await deleteCompany(i.id);
                        }
                        if (tableRef.current) {
                            tableRef.current.onQueryChange();
                        }
                    }
                }
            }, {
                icon: "add_circle",
                tooltip: "Add new Company",
                isFreeAction: true,
                onClick: () => routeTo(p, urls.companyAdd),
            }
            ]}
        />
    </div>;
});