import { CreateArtItemRequest } from "../models/CreateArtItemRequest";
import { ArtItem } from "../models/ArtItem";
import { ResponseEntity } from "../models/ResponseEntity";
import { CreateArtistRequest } from "../models/CreateArtistRequest";
import { Artist } from "../models/Artist";
import { AutoCompleteRequest } from "../models/AutoCompleteRequest";
import { AutocompleteData } from "../models/AutocompleteData";
import { AutoCompleteDeleteRequest } from "../models/AutoCompleteDeleteRequest";
import { CitySearchRequest } from "../models/CitySearchRequest";
import { CreateCompanyRequest } from "../models/CreateCompanyRequest";
import { Company } from "../models/Company";
import { CompanySearchRequest } from "../models/CompanySearchRequest";
import { PageCompany } from "../models/PageCompany";
import { Country } from "../models/Country";
import { SearchRequest } from "../models/SearchRequest";
import { PageCountry } from "../models/PageCountry";
import { ArtItemLabelsRequest } from "../models/ArtItemLabelsRequest";
import { CreateFloorRequest } from "../models/CreateFloorRequest";
import { Floor } from "../models/Floor";
import { FloorSearchRequest } from "../models/FloorSearchRequest";
import { CreateLocationRequest } from "../models/CreateLocationRequest";
import { Location } from "../models/Location";
import { LocationSearchRequest } from "../models/LocationSearchRequest";
import { PageLocation } from "../models/PageLocation";
import { CreateRoomRequest } from "../models/CreateRoomRequest";
import { Room } from "../models/Room";
import { RoomSearchRequest } from "../models/RoomSearchRequest";
import { SecurityLogSearchRequest } from "../models/SecurityLogSearchRequest";
import { PageSecurityLog } from "../models/PageSecurityLog";
import { CreateUserRequest } from "../models/CreateUserRequest";
import { User } from "../models/User";
import { UserLocationSearchRequest } from "../models/UserLocationSearchRequest";
import { UserLocationsRequest } from "../models/UserLocationsRequest";
import { PageUser } from "../models/PageUser";
import { baseUrl, headers } from "./config";
import { AxiosResponse } from "axios";
import axios from "axios";
export function createArtItem(createArtItemRequest: CreateArtItemRequest): Promise<AxiosResponse<ArtItem>> {
    const url = `${baseUrl}/api/admin/art-item`;
    return axios.put<ArtItem>(url, createArtItemRequest, { headers });
}
export function getArtItem(artItemId: number): Promise<AxiosResponse<ArtItem>> {
    const url = `${baseUrl}/api/admin/art-item/${artItemId}`;
    return axios.get<ArtItem>(url, { headers });
}
export function updateArtItem(artItemId: number, createArtItemRequest: CreateArtItemRequest): Promise<AxiosResponse<ArtItem>> {
    const url = `${baseUrl}/api/admin/art-item/${artItemId}`;
    return axios.put<ArtItem>(url, createArtItemRequest, { headers });
}
export function deleteArtItem(artItemId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/art-item/${artItemId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function createArtist(createArtistRequest: CreateArtistRequest): Promise<AxiosResponse<Artist>> {
    const url = `${baseUrl}/api/admin/artist`;
    return axios.put<Artist>(url, createArtistRequest, { headers });
}
export function getArtist(artistId: number): Promise<AxiosResponse<Artist>> {
    const url = `${baseUrl}/api/admin/artist/${artistId}`;
    return axios.get<Artist>(url, { headers });
}
export function updateArtist(artistId: number, createArtistRequest: CreateArtistRequest): Promise<AxiosResponse<Artist>> {
    const url = `${baseUrl}/api/admin/artist/${artistId}`;
    return axios.put<Artist>(url, createArtistRequest, { headers });
}
export function deleteArtist(artistId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/artist/${artistId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function searchAutoCompleteData(autoCompleteRequest: AutoCompleteRequest): Promise<AxiosResponse<AutocompleteData[]>> {
    const url = `${baseUrl}/api/admin/auto-complete/search`;
    return axios.post<AutocompleteData[]>(url, autoCompleteRequest, { headers });
}
export function deletesearchAutoCompleteData(autoCompleteDeleteRequest: AutoCompleteDeleteRequest, id: number): Promise<AxiosResponse<AutocompleteData[]>> {
    const url = `${baseUrl}/api/admin/auto-complete/${id}`;
    return axios.post<AutocompleteData[]>(url, autoCompleteDeleteRequest, { headers });
}
export function searchCities(data: CitySearchRequest): Promise<AxiosResponse<AutocompleteData[]>> {
    const url = `${baseUrl}/api/admin/city/search`;
    return axios.post<AutocompleteData[]>(url, data, { headers });
}
export function addCompany(createCompanyRequest: CreateCompanyRequest): Promise<AxiosResponse<Company>> {
    const url = `${baseUrl}/api/admin/company`;
    return axios.put<Company>(url, createCompanyRequest, { headers });
}
export function getCompanies(): Promise<AxiosResponse<Company[]>> {
    const url = `${baseUrl}/api/admin/company/list`;
    return axios.get<Company[]>(url, { headers });
}
export function getOwners(): Promise<AxiosResponse<Company[]>> {
    const url = `${baseUrl}/api/admin/company/list/owners`;
    return axios.get<Company[]>(url, { headers });
}
export function searchCompany(data: CompanySearchRequest): Promise<AxiosResponse<PageCompany>> {
    const url = `${baseUrl}/api/admin/company/search`;
    return axios.post<PageCompany>(url, data, { headers });
}
export function getCompany(companyId: number): Promise<AxiosResponse<Company>> {
    const url = `${baseUrl}/api/admin/company/${companyId}`;
    return axios.get<Company>(url, { headers });
}
export function updateCompany(companyId: number, createCompanyRequest: CreateCompanyRequest): Promise<AxiosResponse<Company>> {
    const url = `${baseUrl}/api/admin/company/${companyId}`;
    return axios.put<Company>(url, createCompanyRequest, { headers });
}
export function deleteCompany(companyId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/company/${companyId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function getAllCountries(): Promise<AxiosResponse<Country[]>> {
    const url = `${baseUrl}/api/admin/country/list`;
    return axios.get<Country[]>(url, { headers });
}
export function searchCountries(data: SearchRequest): Promise<AxiosResponse<PageCountry>> {
    const url = `${baseUrl}/api/admin/country/search`;
    return axios.post<PageCountry>(url, data, { headers });
}
export function generateArtItemCatalogHtml(data: ArtItemLabelsRequest): Promise<AxiosResponse<string>> {
    const url = `${baseUrl}/api/admin/document/catalog-html`;
    return axios.post<string>(url, data, { headers });
}
export function generateArtItemCatalog(data: ArtItemLabelsRequest): Promise<AxiosResponse<string>> {
    const url = `${baseUrl}/api/admin/document/catalog-pdf`;
    return axios.post<string>(url, data, { headers });
}
export function generateArtItemLabelsAsHtml(data: ArtItemLabelsRequest): Promise<AxiosResponse<string>> {
    const url = `${baseUrl}/api/admin/document/labels-html`;
    return axios.post<string>(url, data, { headers });
}
export function generateArtItemLabels(data: ArtItemLabelsRequest): Promise<AxiosResponse<string>> {
    const url = `${baseUrl}/api/admin/document/labels-pdf`;
    return axios.post<string>(url, data, { headers });
}
export function addFloor(createFloorRequest: CreateFloorRequest): Promise<AxiosResponse<Floor>> {
    const url = `${baseUrl}/api/admin/floor`;
    return axios.put<Floor>(url, createFloorRequest, { headers });
}
export function searchFloors(data: FloorSearchRequest): Promise<AxiosResponse<Floor[]>> {
    const url = `${baseUrl}/api/admin/floor/search`;
    return axios.post<Floor[]>(url, data, { headers });
}
export function getFloor(floorId: number): Promise<AxiosResponse<Floor>> {
    const url = `${baseUrl}/api/admin/floor/${floorId}`;
    return axios.get<Floor>(url, { headers });
}
export function updateFloor(createFloorRequest: CreateFloorRequest, floorId: number): Promise<AxiosResponse<Floor>> {
    const url = `${baseUrl}/api/admin/floor/${floorId}`;
    return axios.put<Floor>(url, createFloorRequest, { headers });
}
export function deleteFloor(floorId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/floor/${floorId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function importFromOldDatabase(): Promise<AxiosResponse<string>> {
    const url = `${baseUrl}/api/admin/import`;
    return axios.get<string>(url, { headers });
}
export function addLocation(createLocationRequest: CreateLocationRequest): Promise<AxiosResponse<Location>> {
    const url = `${baseUrl}/api/admin/location`;
    return axios.put<Location>(url, createLocationRequest, { headers });
}
export function searchLocations(locationSearchRequest: LocationSearchRequest): Promise<AxiosResponse<PageLocation>> {
    const url = `${baseUrl}/api/admin/location/search`;
    return axios.post<PageLocation>(url, locationSearchRequest, { headers });
}
export function getLocation(locationId: number): Promise<AxiosResponse<Location>> {
    const url = `${baseUrl}/api/admin/location/${locationId}`;
    return axios.get<Location>(url, { headers });
}
export function updateLocation(createLocationRequest: CreateLocationRequest, locationId: number): Promise<AxiosResponse<Location>> {
    const url = `${baseUrl}/api/admin/location/${locationId}`;
    return axios.put<Location>(url, createLocationRequest, { headers });
}
export function deleteLocation(locationId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/location/${locationId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function logs(): Promise<any> {
    const url = `${baseUrl}/api/admin/logs`;
    return axios.post(url, null, { headers });
}
export function addRoom(createRoomRequest: CreateRoomRequest): Promise<AxiosResponse<Room>> {
    const url = `${baseUrl}/api/admin/room`;
    return axios.put<Room>(url, createRoomRequest, { headers });
}
export function searchRooms(data: RoomSearchRequest): Promise<AxiosResponse<Room[]>> {
    const url = `${baseUrl}/api/admin/room/search`;
    return axios.post<Room[]>(url, data, { headers });
}
export function getRoom(roomId: number): Promise<AxiosResponse<Room>> {
    const url = `${baseUrl}/api/admin/room/${roomId}`;
    return axios.get<Room>(url, { headers });
}
export function updateRoom(createRoomRequest: CreateRoomRequest, roomId: number): Promise<AxiosResponse<Room>> {
    const url = `${baseUrl}/api/admin/room/${roomId}`;
    return axios.put<Room>(url, createRoomRequest, { headers });
}
export function deleteRoom(roomId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/room/${roomId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
export function searchSecurityLog(securityLogSearchRequest: SecurityLogSearchRequest): Promise<AxiosResponse<PageSecurityLog>> {
    const url = `${baseUrl}/api/admin/security-log/search`;
    return axios.post<PageSecurityLog>(url, securityLogSearchRequest, { headers });
}
export function createUser(createUserRequest: CreateUserRequest): Promise<AxiosResponse<User>> {
    const url = `${baseUrl}/api/admin/user`;
    return axios.put<User>(url, createUserRequest, { headers });
}
export function getUserLocations(userLocationSearchRequest: UserLocationSearchRequest): Promise<AxiosResponse<PageLocation>> {
    const url = `${baseUrl}/api/admin/user/location/search`;
    return axios.post<PageLocation>(url, userLocationSearchRequest, { headers });
}
export function addUserLocation(userLocationsRequest: UserLocationsRequest): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/user/locations/add`;
    return axios.post<ResponseEntity>(url, userLocationsRequest, { headers });
}
export function searchUsers(data: SearchRequest): Promise<AxiosResponse<PageUser>> {
    const url = `${baseUrl}/api/admin/user/search`;
    return axios.post<PageUser>(url, data, { headers });
}
export function getUser(userId: number): Promise<AxiosResponse<User>> {
    const url = `${baseUrl}/api/admin/user/${userId}`;
    return axios.get<User>(url, { headers });
}
export function updateUser(createUserRequest: CreateUserRequest, userId: number): Promise<AxiosResponse<User>> {
    const url = `${baseUrl}/api/admin/user/${userId}`;
    return axios.put<User>(url, createUserRequest, { headers });
}
export function deleteUser(userId: number): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/admin/user/${userId}`;
    return axios.delete<ResponseEntity>(url, { headers });
}
