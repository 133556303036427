import React, { useState, useEffect } from "react";
import Downshift, { GetItemPropsOptions, DownshiftState, StateChangeOptions } from "downshift";
import { TextField, Chip, MenuItem, Paper, TextFieldProps, makeStyles, Theme, Checkbox, MenuItemProps } from "@material-ui/core";
import { useLoadData, withSuppressLoading } from "../store/utils";
type RenderInputProps = TextFieldProps & {
    classes: ReturnType<typeof useStyles>;
    // color: "primary" | "secondary" | undefined;
    ref?: React.Ref<HTMLDivElement>;
    name?: string;
};
type DownshiftSuggestion<T> = T & { selected?: boolean }
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flexGrow: 1,
        position: "relative",
    },
    paper: {
        overflow: "auto",
        maxHeight: "75vh",
        minWidth:"25vw",
        position: "absolute",
        zIndex: 200000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0
    },
}));

function renderChipList<T extends { id: number }>(inputProps: {
    classes: any,
    selectedItem: T[],
    onRemoveItem: (o: T) => void,
    display: (o: T) => string;
}) {
    const { classes, selectedItem, onRemoveItem, display } = inputProps;
    return (
        <div className={classes.chipContainer}>
            {selectedItem.length > 0 &&
                selectedItem.map(item => (
                    <Chip
                        title={display(item)}
                        key={item.id}
                        className={classes.chip}
                        label={display(item).length < 10 ? display(item) : `${display(item).substr(0, 10)} ...`}
                        // deleteIcon={<CancelIcon />}
                        onDelete={() => onRemoveItem(item)}
                        onClick={() => onRemoveItem(item)}
                    />
                ))}
        </div>
    );
}

function renderInput(p: RenderInputProps) {
    p.inputProps!.value = p.inputProps!.value || "";
    const { InputProps, classes, ref, ...other } = p;
    return (
        <TextField
            InputProps={{
                inputRef: ref,
                ...InputProps,
            }}
            {...other}
        />
    );
}
const renderSuggestion = <T extends { selected?: boolean, id: number }>(params: {
    item: T;
    allItems: T[];
    setItems: React.Dispatch<React.SetStateAction<T[]>>;
    index: number;
    itemProps: MenuItemProps<"div", { button?: any }>;
    highlightedIndex: number | null,
    display: (o: T) => string;
    onSelectItem: (item: T) => void;
    classes: any;
}) => {

    const { item, index, itemProps, highlightedIndex, allItems, setItems, display, onSelectItem } = params;
    const isHighlighted = highlightedIndex === index;
    itemProps.onClick = () => {
        onSelectItem(item);
        setItems(allItems.map(e => e.id === item.id ? { ...item, selected: !item.selected } : e));
    };
    return <MenuItem
        style={{padding: "6px 8px"}}
        {...itemProps}
        key={item.id}
        selected={isHighlighted}
        component="div"
    >
        <Checkbox
            style={{marginRight: "4px"}}
            checked={item.selected!}
            value="uncontrolled"
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
        />
        <div title={display(item)} style={{ overflow: "hidden", textOverflow: "ellipsis", marginTop: "2px" }}>{display(item)}</div>
        {/* <Typography variant="inherit" noWrap>
            {display(item)}
        </Typography> */}
    </MenuItem>;

};
function AutocompleteMenu<T extends { id: number }>(p: {
    classes: any,
    searchText: string | null;
    selectedItems: DownshiftSuggestion<T>[],
    getSuggestions: (searchTerm: string) => Promise<Array<T>>;
    getSuggestionsDeps?: any[];
    getItemProps: (options: GetItemPropsOptions<any>) => any;
    highlightedIndex: number | null;
    display: (o: T) => string;
    getSelectedItems: (o: DownshiftSuggestion<T>[]) => void;
}) {
    const { classes, searchText, getSuggestions, getItemProps, display, highlightedIndex } = p;
    const [suggestions, setSuggestions] = useState<DownshiftSuggestion<T>[]>([]);
    const [selectedItems, setSelectedItems] = useState<DownshiftSuggestion<T>[]>(p.selectedItems);
    useEffect(() => {
        if (selectedItems !== p.selectedItems)
            setSelectedItems(p.selectedItems);
    //eslint-disable-next-line
    }, [p.selectedItems]);

    useLoadData(async () => {
        const result = await withSuppressLoading(() => getSuggestions(p.searchText || ""));
        if (selectedItems.length) {
            const newWithSelected = result.map((e) => {
                return selectedItems.find(o => o.id === e.id) ?? {
                    ...e, selected: false,
                };
            });
            setSuggestions(newWithSelected);
        } else {
            setSuggestions(result.map(e => {
                return {
                    ...e, selected: false,
                };
            }));
        }

    }, [searchText, p.getSuggestionsDeps]);
    useEffect(() => {
        p.getSelectedItems(selectedItems);
        //eslint-disable-next-line
    }, [selectedItems]);

    const handleItemClick = (item: DownshiftSuggestion<T>) => {
        let itemExists = selectedItems.some(e => e.id === item.id);
        if (itemExists) {
            if (item.selected === true) {
                setSelectedItems(selectedItems.filter(e => e.id !== item.id));
            }
        } else {
            if (item.selected === false) {
                setSelectedItems([...selectedItems, { ...item, selected: true }]);
            }
        }
    };
    return <Paper className={classes.paper} square>
        {suggestions.map((item, index) =>
            renderSuggestion({
                item,
                display: display,
                allItems: suggestions,
                setItems: setSuggestions,
                index,
                itemProps: getItemProps({
                    item: item,
                }),
                highlightedIndex,
                onSelectItem: handleItemClick,
                classes: classes,
            })
        )}
    </Paper>;
}
export function MultipleAutocompleteSelect<T extends { id: number }>(props: {
    getSuggestions: (searchTerm: string) => Promise<Array<T>>,
    getSuggestionsDeps?: any[];
    onSelect: (data: T[]) => void,
    selectedItems: T[];
    label?: string,
    display: (obj: T) => string;

}) {
    const classes = useStyles();
    const [items, setItems] = useState<DownshiftSuggestion<T>[]>(props.selectedItems);
    useEffect(() => setItems(props.selectedItems), [props.selectedItems]);

    function stateReducer(state: DownshiftState<T>, changes: StateChangeOptions<T>) {
        switch (changes.type) {
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.blurInput:
            handleSelect();
            return changes;
        default:
            return changes;
        }
    }
    const removeItem = (item: T) => {
        setItems(items.filter(e => e.id !== item.id));
        props.onSelect(items.filter(e => e.id !== item.id));
    };
    const handleSelect = () => {
        props.onSelect(items);
    };
    return (
        <Downshift stateReducer={stateReducer}>
            {({
                getInputProps,
                getItemProps,
                inputValue,
                highlightedIndex,
                openMenu,
                closeMenu,
                isOpen,
            }) => {

                const { onBlur, onFocus, ...inputProps } = getInputProps({
                    onBlur: "",
                    onFocus: openMenu,

                    placeholder: "Make a selection",
                });
                return <div className={classes.container}>
                    {renderChipList({
                        classes,
                        onRemoveItem: (t) => { closeMenu(); removeItem(t); },
                        selectedItem: items,
                        display: props.display
                    })}
                    {renderInput({
                        classes,
                        InputProps: { onBlur, onFocus },
                        inputProps,
                        label: props.label || "Choose multiple items",
                    })}

                    {isOpen && (
                        <AutocompleteMenu
                            selectedItems={items}
                            searchText={inputValue}
                            classes={classes}
                            display={props.display}
                            getItemProps={getItemProps}
                            getSuggestions={props.getSuggestions}
                            getSuggestionsDeps={props.getSuggestionsDeps}
                            highlightedIndex={highlightedIndex}
                            getSelectedItems={items => setItems(items)}
                        />
                    )}
                </div>;
            }}
        </Downshift>
    );
}
