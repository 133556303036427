import { Action, ReducersMapObject, Dispatch } from "redux";
import { useEffect, useState, useRef } from "react";
import { localStorageUserKey, headers } from "../services/config";
import { Query, QueryResult } from "material-table";
import { AxiosResponse } from "axios";
import { Pageable } from "../models/Pageable";
import { ArtItemFilterRequest } from "../pages/art-item-location-filter";

export enum DisplayMode {
    List = "list",
    Tiles = "tiles"
}

export const a = <A extends Action<T>, T extends string>(a: A) => a;


export type ParameterFromMap<T extends ReducersMapObject<any, any>, I extends 0 | 1> = {
    [P in keyof T]: Exclude<Parameters<T[P]>[I], undefined>
}
export type StateFromMap<T extends ReducersMapObject<any, any>> = ParameterFromMap<T, 0>
export type ActionFromMap<T extends ReducersMapObject<any, any>> = ParameterFromMap<T, 1>[keyof T]


export type ActionsOf<T extends ReducersMapObject<any, any>> = {
    [P in keyof T]: T[P] extends ((...a: any) => infer A) ? A extends Action<any> ? A : never : never
}[keyof T]

export type DispatchOf<T extends ReducersMapObject<any, any>> = Dispatch<ActionsOf<T>>

export function withPreventDefault(fn: (e: React.FormEvent<HTMLFormElement>) => void) {
    return function (e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        fn(e);
    };
}

export function updateState<T, K extends keyof T>(value: T, set: (o: T) => void, key: K) {
    return function (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        set({ ...value, [key]: e.target.value });
    };
}

export function useLoadData(fn: () => Promise<void>, deps: readonly any[] = []) {
    useEffect(() => {
        fn().catch(e => {
            if (e.response && e.response.status === 500) {
                window.localStorage.removeItem(localStorageUserKey);
                window.location.href = "/login";
            }
            console.log(e);
        });
        // eslint-disable-next-line
    }, deps);
}

const isPageable = function isPageable(arg: any): arg is Pageable {
    return arg.pageNumber !== undefined;
};

type SearchFunction<T> = (req: {
    key: string,
    pageNumber: number,
    pageSize: number,
    sortColumn?: string,
    sortDirection?: "ASC" | "DESC",
    ignoreCase?: boolean,
    locationId?: number,
    floorId?: number,
    roomId?: number,
}) => Promise<AxiosResponse<{
    content: T[],
    totalElements: number,
    totalPages: number,
    pageable: Pageable | "INSTANCE"
}>>
export function loadArtItems<T extends object>(search: SearchFunction<T>, locationFilter?: ArtItemFilterRequest) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {

        let result: AxiosResponse<{
            content: T[];
            totalElements: number;
            totalPages: number;
            pageable: Pageable | "INSTANCE";
        }>;
        if (q.orderBy) {
            result = await search({
                ...locationFilter,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
                ignoreCase: q.orderBy.type !== "numeric"
            });
        } else {
            result = await search({
                ...locationFilter,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;

        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;
        }
        return {
            data: result.data.content,
            page: pageNumber,
            totalCount: result.data.totalElements,
        };
    };
}
export function loadItems<T extends object>(search: SearchFunction<T>) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {

        let result: AxiosResponse<{
            content: T[];
            totalElements: number;
            totalPages: number;
            pageable: Pageable | "INSTANCE";
        }>;
        if (q.orderBy) {
            result = await search({
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
                ignoreCase: q.orderBy.type !== "numeric"
            });
        } else {
            result = await search({
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;
        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;
        }

        return {
            data: result.data.content,
            page: pageNumber,
            totalCount: result.data.totalElements,

        };
    };
}
export function loadSearchItems<T extends object>(search: SearchFunction<T>, key: string) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {
        let result: AxiosResponse<{
            content: T[];
            totalElements: number;
            totalPages: number;
            pageable: Pageable | "INSTANCE";
        }>;
        if (q.orderBy) {
            result = await search({
                key: key,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
            });
        } else {
            result = await search({
                key,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;
        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;

        }
        return {
            data: result.data.content,
            page: pageNumber,
            totalCount: result.data.totalElements,
        };
    };
}

export function loadPlacements<T extends object>(search: SearchFunction<T>) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {

        let result: AxiosResponse<{
            content: T[];
            totalElements: number;
            totalPages: number;
            pageable: Pageable | "INSTANCE";
        }>;
        if (q.orderBy) {
            result = await search({
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
            });
        } else {
            result = await search({
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;
        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;
        }
        return {
            data: result.data.content,
            page: pageNumber,
            totalCount: result.data.totalElements,
        };
    };
}


type SearchHistoryFunction<T> = (req: {
    entityName?: string;
    linkId?: number;
    key?: string;
    pageNumber?: number;
    pageSize?: number;
    unpaged?: boolean;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
}) => Promise<AxiosResponse<{
    content?: T[];
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    pageable?: Pageable | "INSTANCE";
    size?: number;
    sort?: {
        empty?: boolean;
        sorted?: boolean;
        unsorted?: boolean;
    };
    totalElements?: number;
    totalPages?: number;
}>>
export function loadHistoryItems<T extends object>(search: SearchHistoryFunction<T>, entityName: string, linkId?: number) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {

        // const result = await search({
        //     entityName,
        //     key: q.search,
        //     pageNumber: q.page,
        //     pageSize: q.pageSize,
        // });
        let result: AxiosResponse<{
            content?: T[];
            empty?: boolean;
            first?: boolean;
            last?: boolean;
            number?: number;
            numberOfElements?: number;
            pageable?: Pageable | "INSTANCE";
            size?: number;
            sort?: {
                empty?: boolean;
                sorted?: boolean;
                unsorted?: boolean;
            };
            totalElements?: number;
            totalPages?: number;
        }>;
        if (q.orderBy) {
            result = await search({
                entityName,
                linkId,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
            });
        } else {
            result = await search({
                entityName,
                linkId,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;
        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;
        }
        return {
            data: result.data.content!,
            page: pageNumber,
            totalCount: result.data.totalElements!,
        };
    };
}
type SearchSecurityFunction<T> = (req: {
    action?: "LOGIN" | "LOGOUT";
    date?: string;
    key?: string;
    startDate?: string;
    endDate?: string;
    pageNumber?: number;
    pageSize?: number;
    unpaged?: boolean;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
}) => Promise<AxiosResponse<{
    content?: T[];
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    pageable?: Pageable | "INSTANCE";
    size?: number;
    sort?: {
        empty?: boolean;
        sorted?: boolean;
        unsorted?: boolean;
    };
    totalElements?: number;
    totalPages?: number;
}>>
export function loadSecurityItems<T extends object>(search: SearchSecurityFunction<T>, action?: "LOGIN" | "LOGOUT", startDate?: Date | null, endDate?: Date | null) {
    return async function (q: Query<T>): Promise<QueryResult<T>> {
        console.log(startDate?.toISOString());
        console.log(endDate?.toISOString());
        // const result = await search({
        //     entityName,
        //     key: q.search,
        //     pageNumber: q.page,
        //     pageSize: q.pageSize,
        // });
        let result: AxiosResponse<{
            content?: T[];
            empty?: boolean;
            first?: boolean;
            last?: boolean;
            number?: number;
            numberOfElements?: number;
            pageable?: Pageable | "INSTANCE";
            size?: number;
            sort?: {
                empty?: boolean;
                sorted?: boolean;
                unsorted?: boolean;
            };
            totalElements?: number;
            totalPages?: number;
        }>;
        if (q.orderBy) {
            result = await search({
                action,
                startDate: startDate?.toISOString(),
                endDate: endDate?.toISOString(),
                // date,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
                sortColumn: q.orderBy.field ? q.orderBy.field.toString() : "",
                sortDirection: q.orderDirection.toUpperCase() as "ASC" | "DESC",
            });
        } else {
            result = await search({
                action,
                startDate: startDate?.toISOString(),
                endDate: endDate?.toISOString(),
                // date,
                key: q.search,
                pageNumber: q.page,
                pageSize: q.pageSize,
            });
        }
        let pageNumber = 0;
        if (isPageable(result.data.pageable)) {
            pageNumber = (result.data.pageable as Pageable).pageNumber;
        }
        return {
            data: result.data.content!,
            page: pageNumber,
            totalCount: result.data.totalElements!,
        };
    };
}
export function withSuppressLoading<T>(fn: () => T): T {
    try {
        headers.SuppressLoading = true;
        return fn();
    } finally {
        delete headers.SuppressLoading;
    }
}

// It used to be used in formik forms:
// export function preventDefaultOnEnter(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
//     if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
//         keyEvent.preventDefault();
//     }
// }

export function useTitle(initialTitle: string): (title: string) => void {
    const oldTitle = useRef<string>();
    const [title, setTitle] = useState(initialTitle);
    useEffect(() => {
        if (!oldTitle.current) {
            oldTitle.current = document.title;
        }
        document.title = `Art gallery - ${title}`;
        return () => {
            document.title = oldTitle.current!;
        };
    }, [title]);
    return setTitle;
}
