/* eslint-disable indent */
import React, { useState } from "react";
import * as admin from "../services/admin.service";
import {
    Grid,
    ListItemText,
    ListItem,
    List,
    ListItemSecondaryAction,
    IconButton,
    Icon
} from "@material-ui/core";
import { useLoadData } from "../store/utils";
import AutocompleteSelect from "../components/autocomplete-selector";
import { Location } from "../models/Location";
import DeleteIcon from "@material-ui/icons/Delete";


export function UserLocationEdit(p: {
    id?: string;
    setSaveLocations: (fn: (id: number) => Promise<void>) => void;
    hasLocation: (fn: () => boolean) => void;
    error: boolean;
}) {
    const [locations, setLocations] = useState<Location[]>([]);
    const [auxLocation, setAuxLocation] = useState<Location>();
    useLoadData(async () => {
        if (p.id) {
            const result = await admin.getUserLocations({ userId: +p.id });
            setLocations(result.data.content);
        }
    }, []);
    p.setSaveLocations(handleSubmit);
    function deleteAtIndex(index: number) {
        const auxArray = locations.map(e => e);
        auxArray.splice(index, 1);
        setLocations(auxArray);
    }
    p.hasLocation(hasLocations);
    function hasLocations() {
        return locations.length > 0;
    }
    function addLocation() {
        const newList: Location[] = [];
        locations.forEach(item => newList.push(item));
        newList.push(auxLocation!);
        setAuxLocation(undefined);
        setLocations(newList);
    }
    async function locationSuggestions(key: string) {
        const result = (await admin.searchLocations({
            key,
            unpaged: true,
            sortDirection: "ASC",
            sortColumn: "name",
        })).data.content;
        return locations.length ? result.filter(e => locations.some(loc => loc.id !== e.id)) : result;
    }
    async function handleSubmit(userId: number) {
        const locationIds: number[] = locations.map(e => e.id);
        await admin.addUserLocation({ locations: locationIds, userId });
    }
    return (
        <Grid container className="inputStyle inputStyle--clearPadding">
            <Grid item xs={12} sm={11}>
                <AutocompleteSelect
                    getSuggestions={locationSuggestions}
                    label="Add User Location(s)"
                    display={o => o && o.name}
                    required={false}
                    key="user-locations"
                    error={{
                        expression: p.error && !hasLocations(),
                        helperText: "Please select at least a location"
                    }}
                    onSelect={location => {
                        if (location) {
                            setAuxLocation(location);
                        }
                    }}
                />
            </Grid>
            {auxLocation ? (
                <Grid item xs={12} sm={1}>
                    <IconButton onClick={addLocation}>
                        <Icon>add</Icon>
                    </IconButton>
                </Grid>
            ) : (
                    <div></div>
                )}
            <Grid item xs={12}>
                <List>
                    {locations.map((location, i) => (
                        <ListItem key={location.id} role={undefined} dense button style={{ border: "1.5px dashed lightgray", margin: "5px 0", padding: "8px" }}>
                            <ListItemText primary={location.name} />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={e => {
                                        deleteAtIndex(i);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Grid>
        </Grid>
    );
}