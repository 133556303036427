import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import { importFromOldDatabase } from "../services/admin.service";
import { useTitle } from "../store/utils";


export const ImportOldData = () => {
    useTitle("Import Data");
    const [response, setResponse] = useState<any>();
    const importData = async () => {
        const result = await importFromOldDatabase();
        setResponse(result.data);
    };
    return <div>
        <Button variant="contained" color="primary" onClick={importData}>
            Import Data
        </Button>
        {response && <Typography >{response}</Typography>}
    </div>;
};