import React, { useRef, useState, useContext } from 'react'
import MaterialTable, { Options, Query } from 'material-table';
import { withoutSorting } from '../components/edit-delete-column';
import { searchAutoCompleteData, deletesearchAutoCompleteData } from '../services/admin.service';
import { Select, MenuItem, TextField, Button, IconButton, Icon, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { PagingContext } from '../contexts';
import { AutocompleteData } from '../models/AutocompleteData';
export const AttributesLog = () => {

    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: false,
        sorting: false,
        searchFieldStyle: {
            display: "none",
        },
        search: true,
        pageSize: pagingContext.atributesPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100]
    });
    const [dataType, setDataType] = useState<"FRAMING" | "MATERIAL" | "TECHNIQUE">("FRAMING");
    const [updatedValue, setUpdatedValue] = useState<string>("");
    function renderTitle() {
        return <div style={{ display: "flex", flexDirection: "row" }}>
            <h6 className="MuiTypography-root MuiTypography-h6">Autocomplete data list</h6>
            <Select
                style={{ marginLeft: 15, marginRight: 20 }}
                value={dataType}
                onChange={async (e: React.ChangeEvent<any>) => {
                    setDataType(e.target.value);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
            >
                <MenuItem value="FRAMING">Framing</MenuItem>
                <MenuItem value="MATERIAL">Material</MenuItem>
                <MenuItem value="TECHNIQUE" >Technique</MenuItem>
            </Select>
        </div>;
    }
    const [open, setOpen] = React.useState(false);
    const [selectedAutocomplete, setSelectedAutocomplete] = useState<AutocompleteData>();
    const handleClickOpen = (r: AutocompleteData) => () => {
        setSelectedAutocomplete(r);
        setOpen(true);

    };
    const handleClose = () => {
        setOpen(false);
    };
    const [updateTextError, setupdateTextError] = useState("");
    const tableRef = useRef<any>(null);
    return <div className="ArtGallery-List-SecurityLogList table-list-pagination-bottom">
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Replace value or remove</DialogTitle>
            <DialogContent>
                <TextField
                    label="Replacement value"
                    value={updatedValue}
                    error={updateTextError.length > 0}
                    helperText={updateTextError.length > 0 ? updateTextError : ""}
                    onChange={e => {
                        setUpdatedValue(e.target.value);
                    }}
                    style={{ paddingBottom: "8px" }}
                />
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}>
                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
                        <Button onClick={async () => {
                            if (updatedValue.length > 0) {
                                if (selectedAutocomplete) {
                                    await deletesearchAutoCompleteData({ replacement: updatedValue }, selectedAutocomplete.id);
                                    handleClose()
                                }
                            } else {
                                setupdateTextError("Please provide a replacement")
                            }

                        }} color="primary">Replace</Button>
                    </div>
                    <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                        <Button onClick={async () => {
                            if (selectedAutocomplete) {
                                await deletesearchAutoCompleteData({ replacement: "" }, selectedAutocomplete.id);
                                handleClose()
                            }
                        }} color="secondary">Remove</Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
        <MaterialTable<AutocompleteData>
            title={renderTitle()}
            tableRef={tableRef}
            options={pagination}
            columns={[
                ...withoutSorting<AutocompleteData>(
                    { title: "Value", render: (rowData) => <div>{rowData.value}</div> }),
                {
                    title: "",
                    cellStyle: { textAlign: "right" },
                    render: r => <div>
                        <IconButton onClick={handleClickOpen(r)}>
                            <Icon>edit</Icon>
                        </IconButton>
                    </div>
                }
            ]}
            data={e => (async function (q: Query<AutocompleteData>) {
                console.log(q);
                const result = await searchAutoCompleteData({ dataType, text: e.search });
                const data = result.data;
                return {
                    data: data.slice(q.page * q.pageSize, (q.page + 1) * q.pageSize),
                    page: pagingContext.atributesPage.page,
                    totalCount: data.length,
                }
            })({ ...e, page: pagingContext.atributesPage.page })}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, atributesPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e });
            }}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, atributesPage: { ...pagingContext.atributesPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
        />
    </div>;
}