import React, { useState, FunctionComponent } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { ArtItem as IArtItem } from "../../models/ArtItem";

import ArtItem from "./art-item";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollableContainer: {
            minHeight: 500,
            maxHeight: 500,
            overflowY: "auto"
        }
    })
);

interface ArtItemPickerProps {
    items?: IArtItem[];
    onChange?: (ids: number[]) => void;
}

const ArtItemPicker: FunctionComponent<ArtItemPickerProps> = ({
    items = [],
    onChange
}) => {
    const classes = useStyles();

    const initialSelection = items.reduce(
        (o, item) => Object.assign(o, { [item.id]: true }),
        {}
    );

    const [selection, updateSelection] = useState<any>(initialSelection);

    const onSelectItem = (id: number) => () => {
        const newSelection = Object.assign({}, selection, { [id]: true });
        updateSelection(newSelection);
        onChange &&
            onChange(
                Object.keys(newSelection)
                    .filter(k => newSelection[k])
                    .map(Number)
            );
    };

    const onDeselectItem = (id: number) => () => {
        const newSelection = Object.assign({}, selection, { [id]: false });
        updateSelection(newSelection);
        onChange &&
            onChange(
                Object.keys(newSelection)
                    .filter(k => newSelection[k])
                    .map(Number)
            );
    };

    return (
        <Grid container direction="column" style={{ marginTop: "0.5rem" }}>
            <Grid item className={classes.scrollableContainer}>
                {items.map(item => {
                    let onSelect;
                    let onDeselect;
                    if (selection[item.id]) {
                        onDeselect = onDeselectItem(item.id);
                    } else {
                        onSelect = onSelectItem(item.id);
                    }

                    return (
                        <ArtItem
                            key={item.id}
                            item={item}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                        />
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default ArtItemPicker;
