import React, { useState, FunctionComponent, useRef, useContext } from "react";
import { withRouter } from "react-router";

import MaterialTable, { Options, Column } from "material-table";

import { Placement } from "../../models/Placement";
import * as placementService from "../../services/placement.service";

import { route, urls, routeTo } from "../../navigation";
import { loadPlacements, useTitle } from "../../store/utils";
import { IconButton, Icon } from "@material-ui/core";
import { useSelector } from "../../store";
import { deletePlacement } from "../../services/placement.service";
import { format } from "date-fns";
import { PagingContext } from "../../contexts";


const PlacementList: FunctionComponent<any> = ({ history }) => {
    useTitle("Placements");
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const tableColumns: Column<Placement>[] = [
        { title: "Type", field: "type" },
        { title: "Status", field: "status" },
        { title: "Description", field: "description", render: e => <div title={e.description}>{e.description && e.description.length > 20 ? `${e.description.slice(0, 20)}...` : e.description}</div> },
        { title: "Created Date", render: e => <div>{format(new Date(e.created || ""), "dd-MM-yyyy")}</div>, field: "created" },
        { title: "Finalized Date", render: e => <div>{e.finalized ? format(new Date(e.finalized || ""), "dd-MM-yyyy") : ""}</div>, field: "finalized" },
        { title: "Location", field: "locationName" },
        { title: "Proposed By", render: (e) => <div>{e.creator === "admin" ? "admin" : e.creatorFullName}</div>, field: "creatorFullName" },
        {
            title: "",
            cellStyle: {
                textAlign: "right"
            },
            render: rowData => <>
                {(rowData.type !== "BORROW" || isAdmin) && <IconButton title="Edit placement" onClick={e => routeTo(history, () => urls.placementsEdit({ id: rowData.id! }))}><Icon>edit</Icon></IconButton>}
                {isAdmin ? <IconButton disabled={!(rowData.status === "DRAFT" || rowData.status === "FINALIZED")} onClick={async e => {
                    const confirmation = window.confirm("Are you sure you want to delete this placement?");
                    if (confirmation)
                        await deletePlacement(rowData.id!);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}><Icon title="Delete placement" >delete</Icon></IconButton> : null}
            </>
        }
    ];
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        searchFieldStyle: {
            display: "none",
        },
        pageSize: pagingContext.placementPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";
    const tableRef = useRef<any>(null);

    return (
        <div className="ArtGallery-List-Placements table-list-pagination-bottom">
            <MaterialTable<Placement>
                tableRef={tableRef}
                options={pagination}
                columns={tableColumns}
                data={e => loadPlacements(placementService.search)({ ...e, page: pagingContext.placementPage.page })}
                // data={loadPlacements(placementService.search)}
                title="Placements"
                onChangeRowsPerPage={e => {
                    setPagingContext({ ...pagingContext, placementPage: { page: 0, pageSize: e } });
                    setPagination({ ...pagination, pageSize: e })
                }}
                onChangePage={e => {
                    setPagingContext({ ...pagingContext, placementPage: { ...pagingContext.placementPage, page: e } });
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
                actions={[
                    {
                        icon: "add_circle",
                        tooltip: "Create placement",
                        isFreeAction: true,
                        onClick: () => history.push(route(urls.placementsAdd))
                    },
                ]}
            />
        </div>
    );
};

export default withRouter(PlacementList);
