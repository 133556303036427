import React, { useEffect, useState } from "react";
import { Tabs, AppBar, Tab } from "@material-ui/core";
import "./MenuBar.css";
import { Link, withRouter } from "react-router-dom";
import { urls, route } from "../navigation";
import { useSelector } from "../store";
import { makeStyles } from "@material-ui/core/styles";
import { Palette, Send, History, Storefront, Room, Stars, PeopleAlt, Security,Keyboard } from "@material-ui/icons";
// import SecurityIcon from '@material-ui/icons/Security';

const useStyles = makeStyles(theme => ({
    label: {
        display: "none"
    },
    MainMenu: {
        backgroundColor: "#0287C0",
        "& div div": {
            width: "92%",
            margin: "0 auto"
        },
        "& div div div a": {
            transition: "background-color 0.5s ease-in-out"
        },
        "& div div div a[aria-selected='true']": {
            backgroundColor: "rgba(255, 255, 255, 0.2)"
        },
        "& div div div a:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            color: "white"
        },
        "@media (max-width: 600px)": {
            position: "fixed",
            bottom: 0,
        }
    },
    tab: {
        "@media (max-width: 1220px)": {
            minWidth: "48px"
        }
    },
    textLabel: {
        "@media (max-width: 1220px)": {
            display: "none"
        }
    }
}));

export default withRouter(function (p) {
    const displayModes = useSelector(s => s.displayMode);
    const roles = useSelector(app => app.loginUser.roles);
    const defaultToken = urls.artItemsView().split("/")[2];
    const [path, setPath] = useState<string>();
    useEffect(() => {
        const pathToken = p.location.pathname.split("/")[2];
        const searchToken = urls.searchAllView({ word: "" }).split("/")[2];
        if (pathToken !== searchToken) {
            setPath(pathToken);
        }
    }, [p.location.pathname]);
    const classes = useStyles();
    if (!roles) {
        return <div></div>;
    }
    const visitorTabs = () => [
        <Tab className={classes.tab}
            icon={<Palette />}
            label={<p className={classes.textLabel}>Art items</p>}
            component={Link}
            to={route(urls.artItemTiles)}
            value="art-item"
            key="Art Items-v"
        />,
        <Tab className={classes.tab}
            icon={<Stars />}
            label={<p className={classes.textLabel}>Artists</p>}
            component={Link}
            to={route(urls.artistTiles)}
            value="artist"
            key="Artists -v"
        />];
    const localTabs = () => [
        <Tab className={classes.tab}
            icon={<Palette />}
            label={<p className={classes.textLabel}>Art items</p>}
            component={Link}
            to={route(urls.artItemsView, [], displayModes)}
            value="art-item"
            key="Art Items-l"
        />,
        <Tab className={classes.tab}
            icon={<Send />}
            label={<p className={classes.textLabel}>Placements</p>}
            component={Link}
            to={route(urls.placementsList)}
            value="placements"
            key="Placements -l"
        />,
        <Tab className={classes.tab}
            icon={<Room />}
            label={<p className={classes.textLabel}>Locations</p>}
            component={Link}
            to={route(urls.locationList)}
            value="location"
            key="Locations -l"
        />,
        <Tab className={classes.tab}
            icon={<Stars />}
            label={<p className={classes.textLabel}>Artists</p>}
            component={Link}
            to={route(urls.artistsView, [], displayModes)}
            value="artist"
            key="Artists -l"
        />];
    const defaultTabs = () => [
        <Tab className={classes.tab}
            icon={<Palette />}
            label={<p className={classes.textLabel}>Art items</p>}
            component={Link}
            to={route(urls.artItemsView, [], displayModes)}
            value="art-item"
            key="Art Items"
        />,
        <Tab className={classes.tab}
            icon={<Send />}
            label={<p className={classes.textLabel}>Placements</p>}
            component={Link}
            to={route(urls.placementsList)}
            value="placements"
            key="Placements"
        />,
        <Tab className={classes.tab}
            icon={<History />}
            label={<p className={classes.textLabel}>History</p>}
            component={Link}
            to={route(urls.history, [])}
            value="history"
            key="History"
        />,
        <Tab className={classes.tab}
            icon={<Storefront />}
            label={<p className={classes.textLabel}>Owners</p>}
            component={Link}
            to={route(urls.companyList)}
            value="owner"
            key="Owners"
        />,
        <Tab className={classes.tab}
            icon={<Room />}
            label={<p className={classes.textLabel}>Locations</p>}
            component={Link}
            to={route(urls.locationList)}
            value="location"
            key="Locations"
        />,
        <Tab className={classes.tab}
            icon={<Stars />}
            label={<p className={classes.textLabel}>Artists</p>}
            component={Link}
            to={route(urls.artistsView, [], displayModes)}
            value="artist"
            key="Artists"
        />,
        <Tab className={classes.tab}
            icon={<PeopleAlt />}
            label={<p className={classes.textLabel}>Users</p>}
            component={Link}
            to={route(urls.userList)}
            value="user"
            key="Users"
        />];
    const adminOnly = () => [
        /* Do not show /import-old-data
        <Tab className={classes.tab}
            icon={<ImportExport />}
            label={<p className={classes.textLabel}>Import Data</p>}
            component={Link}
            to={route(urls.importOld)}
            value="import-old-data"
            key="Import"
        />,*/
        <Tab className={classes.tab}
            icon={<Security />}
            label={<p className={classes.textLabel}>Security Log</p>}
            component={Link}
            to={route(urls.securityLog)}
            value="security-log"
            key="security"
        />,
        <Tab className={classes.tab}
            icon={<Keyboard />}
            label={<p className={classes.textLabel}>Autocomplete Field</p>}
            component={Link}
            to={route(urls.autocompleteData)}
            value="attributes"
            key="attributes"
        />,
    ]
    return (
        <AppBar id="AppBar" position="static" className={classes.MainMenu}>
            <Tabs
                variant="fullWidth"
                value={path || defaultToken}
                aria-label="nav tabs example"
            // onChange={clearSearch}
            >
                {roles[0] === "visitor" ? visitorTabs() : (roles[0] === "local" ? localTabs() : (roles[0] === "admin" ? [...defaultTabs(), ...adminOnly()] : defaultTabs()))}
            </Tabs>
        </AppBar>
    );
});
