import LocalizedStrings from "react-localization";
export let translations = new LocalizedStrings({
    en: {
        locationList: {
            title: "Locations",
            name: "Name",
            company: "Owner",
            city: "City",
            region: "Region",
            adress: "Address",
        },
        locationEdit: {
            modify: "Modify",
            createNewLocation: "Create new Location",
            publish: "Publish",
            discard: "Discard",
            locationNameLabel: "Location Name",
            addressLabel: "Address line",
            cityLabel: "City",
            regionLabel: "State/Province/Region",
            companyLabel: "Owner",
            postalCodeLabel: "Zip / Postal code",
            uploadImage: "Add Picture",
            delete: "Delete",
            confirmDelete: "Are you sure you want to delete this location",
        },
        confirm: (name: string) => `Are you sure you want to delete ${name}?`,
        artItemList: {
            title: "Art Items",
            image: "Image",
            artTitle: "Title",
            artist: "Artist",
            owner: "Owner",
            location: "Location",
            status: "Status",
            inventory: "Inventory",
            technique: "Technique",
            floor: "Floor",
            room: "Room",
        },
        artItemEdit: {
            itemNotes:"Art Item Notes",
            inventoryNumber: "Inventory Number",
            editing: "Editing",
            uploadImage: "Add Picture",
            itemDetail: "Art Item Details",
            nameLabel: "Name",
            typeLabel: "Art type",
            artistLabel: "Artist",
            framingLabel: "Framing",
            materialLabel: "Material",
            tehniqueLabel: "Technique",
            locationLabel: "Location",
            floorLabel: "Floor",
            roomLabel: "Room",
            depthLabel: "Depth",
            widthLabel: "Width",
            heightLabel: "Height",
            yearOfCreationLabel: "Year of Creation",
            yearOfAquisitionLabel: "Year of Acquisition",
            artItemLocationAssign: "Art Item location",
            description: "Art Item Description",
            owner: "Owner",
            mainInfo: "Art Item Main Info",
            dimensions: "Dimensions",
            publish: "Publish",
            discard: "Close",
            confirmDelete: "Are you sure you want to delete this art item?",
            delete: "Delete"
        },
        companyList: {
            title: "Companies",
            id: "Id",
            name: "Name",
            parentCompany: "Parent Company",
            country: "Country",
        },
        companyEdit: {
            loading: "Loading Company",
            editing: "Editing",
            companyNameLabel: "Owner Name",
            countryLabel: "Country",
            publish: "Publish",
            discard: "Discard",
            confirmDelete: "Are you sure you want to delete this company?",
            delete: "Delete",
        },
        artistList: {
            title: "Artists",
            firstName: "First Name",
            lastName: "Last Name",
            country: "Country",
            description: "Description",
            kind: "Kind",
            slug: "Slug",
        },
        artistEdit: {
            editing: "Editing",
            addNew: "Add new Artist",
            pictureLabel: "Artist Picture",
            infoLabel: "Artist Info",
            descriptionLabel: "Artist Description",
            firstName: "First Name",
            lastName: "Last Name",
            kind: "Kind",
            description: "Description",
            country: "Country",
            slug: "Slug",
            uploadImage: "Add Picture",
            publish: "Publish",
            discard: "Discard",
            confirmDelete: "Are you sure you want to delete this artist?",
            delete: "Delete",
        },
        userList: {
            title: "Users",
            id: "ID",
            firstName: "First Name",
            lastName: "Last Name",
            userName: "User Name",
            allLocations: "all"
        },
        userEdit: {
            loading: "Loading ...",
            modify: "Modify",
            createNewUser: "Create new User",
            infoUser: "User Info",
            accessInfoUser: "Access Info",
            loginInfoUser: "Login Info",
            firstNameLabel: "First Name",
            lastNameLabel: "Last Name",
            emailLabel: "Email",
            companyLabel: "Owner",
            changePassword: "Change Password",
            currentPasswordLabel: "Current Password",
            newPasswordLabel: "New Password",
            confirmNewPasswordLabel: "Confirm New Password",
            confirmDelete: "Are you sure you want to delete this user?",
            passwordLabel: "Password",
            usernameLabel: "Username",
            roles: "Roles",
            role: "Role",
            publish: "Publish",
            discard: "Discard",
            delete: "Delete",
        },
        loginPage: {
            signIn: "Sign in",
            userNameLabel: "Username",
            passwordLabel: "Password",
            rememberMe: "Remember me",
            forgotPassword: "Forgot password?",
            invalidUserOrPassword: "Invalid username or password",
            signUp: "Don't have an account? Sign Up",
        },
        history: {
            title: "History",
            id: "ID",
            createdBy: "Created by",
            createdDate: "Created Date",
            entityId: "Entity ID",
            action: "Action",
        },
        errors: {
            enterOnlyCharacters: "Please enter only characters",
            thePasswordsMustMatch: "The passwords must match!",
        },
    }
});