import { Column } from "material-table";
import React from "react";
import { IconButton, Icon } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import { IsAdmin } from "./Roles";

const cellStyle: CSSProperties = {
    textAlign: "right"
};
export function withoutSorting<T extends object>(...data: Column<T>[]) {
    data.forEach(c => c.customSort = () => 0);
    return data;
}
export function editDeleteColumn<T extends object>(p: { onEdit: (r: T) => void, onDelete: (r: T) => void }): Column<T> {
    return {
        title: "",
        cellStyle: cellStyle,
        render: rowData => <div>
            <IsAdmin>
                <IconButton title="Edit" onClick={e => p.onEdit(rowData)}><Icon>edit</Icon></IconButton>
                <IconButton title="Delete" onClick={e => p.onDelete(rowData)}><Icon>delete</Icon></IconButton>
            </IsAdmin>
        </div>
    };
}
