import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { userLogin } from "../store/server-api";
import { withRouter } from "react-router";
import { withPreventDefault, updateState } from "../store/utils";
import { useDispatch, useSelector } from "../store";
import { setLoginUser } from "../store/actions/user";
import { routeTo, startUrl } from "../navigation";
import { localStorageUserKey } from "../services/config";
import { translations } from "../translates";

const useStyles = makeStyles(theme => ({
    "@global": {
        body: {
            //   backgroundColor: theme.palette.common.white
            backgroundImage: "linear-gradient(to bottom, #0c69b1, transparent), url('/museum.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundPosition: "center bottom"
        }
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: "100%",
        "& div:first-child": {
            margin: 0
        }
    },
    formField: {
        backgroundColor: "white",
        "& > div.MuiInputBase-formControl": {
            backgroundColor: "transparent"
        }
    },
    submit: {
        marginTop: theme.spacing(1),
        backgroundColor: "#005DA8",
        transition: "background-color 0.35s ease-out",
        "&:hover": {
            backgroundColor: "#2F8AD1"
        }
    },
    siteLogo: {
        alignSelf: "center",
        backgroundImage: "url('/KROHNE_artgallery_logo.svg')",
        width: "300px",
        height: "50px",
        textIndent: "-100000px",
        backgroundRepeat: "no-repeat",
        margin: "0 auto",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    checkSquare: {
        color: "white",
        "& svg": {
            color: "white"
        }
    },
    invalid: {
        marginBottom: 0,
        color: "white",
        backgroundColor: "#a90000",
        padding: "0.5rem 1rem",
        borderRadius: "1rem"
    }
}));

export default withRouter(props => {
    const classes = useStyles();
    const [user, setUser] = useState({ username: "", password: "" });
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const displayModes = useSelector(s => s.displayMode);

    let trans = translations.loginPage;

    let handleSubmit = async () => {
        try {
            let result = await userLogin(user);
            window.localStorage.setItem(localStorageUserKey, JSON.stringify(result));
            dispatch(setLoginUser(result));
            routeTo(props, startUrl, [], displayModes);
        } catch (e) {
            setError(e);
        }
    };
    return (
        <>
            <div className={classes.siteLogo}>Logo</div>
            <Container
                component="main"
                maxWidth="xs"
                className="ArtGallery-Form-LoginPage"
                style={{
                    backgroundColor: "rgba(255,255,255,0.2)",
                    padding: "1.5rem",
                    borderRadius: "0.5rem",
                    border: "2px solid #0c69b1",
                    boxShadow: "0 3px 20px rgba(0, 0, 0, 0.25)"
                }}
            >
                <div className={classes.paper}>
                    <form
                        className={classes.form}
                        onSubmit={withPreventDefault(handleSubmit)}
                    >
                        <TextField
                            className={classes.formField}
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="user"
                            label={trans.userNameLabel}
                            autoFocus
                            onChange={updateState(user, setUser, "username")}
                        />
                        <TextField
                            className={classes.formField}
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={trans.passwordLabel}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={updateState(user, setUser, "password")}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                        className={classes.checkSquare}
                                    />
                                }
                                label="Remember me"
                                style={{ fontSize: "1rem", color: "white" }}
                            />
                            <Link
                                href="#"
                                variant="body2"
                                style={{ color: "white", fontSize: "1rem" }}
                            >
                                {trans.forgotPassword}
                            </Link>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {trans.signIn}
                        </Button>
                    </form>
                    {error ? (
                        <div>
                            <p className={classes.invalid}>{trans.invalidUserOrPassword}</p>
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </Container>
        </>
    );
});
