import React, { useState, SetStateAction } from "react";
import { Tabs, Tab, IconButton, Icon, TextField, Grid, Typography, Theme, makeStyles, createStyles } from "@material-ui/core";
import { useLoadData } from "../store/utils";
import * as admin from "../services/admin.service";
import ImageApi from "../components/ImageApi";
import { Room } from "../models/Room";
import { FloorWithRooms, RoomWithDeleted } from "../services/service.helpers";
import { useDispatch } from "../store";
import { Location } from "../models/Location";
import { setArtItemsFilter } from "../store/actions/artItemsFilter";
import { routeTo, urls } from "../navigation";
import { withRouter, RouteComponentProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightIcon: {
            marginLeft: theme.spacing(1)
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        }
    })
);
function createArraySetter<T>(index: number, setter: (v: SetStateAction<T[] | undefined>) => void) {
    return function (newValue: SetStateAction<T>) {
        setter(original => {
            const clone = [...original!];
            clone[index] = newValue instanceof Function ? newValue(clone[index]) : newValue;
            return clone;
        });
    };
}
export function AllFloorsEdit(p: { location: Location, locationId: number, openImage: (imageId: string | undefined) => () => Promise<void> }) {
    const [floors, setFloors] = useState<FloorWithRooms[] | undefined>();
    const [currentFloor, setCurrentFloor] = useState<number>();
    useLoadData(async () => {
        if (p.locationId) {
            const result = await admin.searchFloors({
                locationIds: [p.locationId]
            });
            setFloors(result.data);
            setCurrentFloor(result.data.length ? 0 : undefined);
        } else {
            setFloors([{ id: 0, name: "New Floor", locationId: p.locationId }]);
            setCurrentFloor(0);
        }
    }, [p.locationId]);

    if (typeof currentFloor === "undefined") {
        return <div></div>;
    }
    if (floors) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 96px" }}>
                        <Tabs
                            textColor="secondary"
                            variant="scrollable"
                            value={currentFloor}
                            aria-label="nav tabs example"
                            scrollButtons="auto"
                        >
                            {floors.map((o, i) => { return !o.isDeleted && <Tab key={i} label={o.name} value={i} onClick={() => setCurrentFloor(i)}></Tab>; })}
                        </Tabs>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    {currentFloor == null ? <></> :
                        <FloorEdit Location={p.location} floor={floors[currentFloor]} setFloor={createArraySetter(currentFloor, setFloors)} openImage={p.openImage}></FloorEdit>}
                </Grid>
            </Grid>
        );
    } else {
        return <div></div>;
    }
}
const FloorEdit = withRouter(function (p: RouteComponentProps<{}> & { Location: Location, floor: FloorWithRooms, openImage: (imageId: string | undefined) => () => Promise<void>, setFloor: (value: SetStateAction<FloorWithRooms>) => void }) {
    const classes = useStyles();
    const { Location, floor, openImage, setFloor } = p;
    useLoadData(async () => {
        if (floor.rooms) return;
        const rooms = await admin.searchRooms({ floorIds: [floor.id] });
        if (floor.rooms) return;
        if (rooms.data.length) {
            setFloor({ ...floor, rooms: rooms.data });
        } 
    }, [floor.id]);
    const dispatch = useDispatch();
    const setRooms = (r: Room) => {
        dispatch(setArtItemsFilter({ floor: [floor], room: [r], artist: [], technique: [], location: [Location] }));
        routeTo(p, urls.artItemList);
    }
    return <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1" gutterBottom>
                <IconButton onClick={e => {
                    dispatch(setArtItemsFilter({ floor: [floor], room: [], artist: [], technique: [], location: [Location] }));
                    routeTo(p, urls.artItemList);
                }}><Icon>visibility</Icon></IconButton>
                View {floor.name}
            </Typography>
            {floor.picture && (
                <Grid item className={`inputStyle inputStyle--clearPadding ${classes.pictureContainer}`}>
                    <ImageApi src={floor.picture} imageType="Tile" onClick={openImage(floor.picture)}></ImageApi>
                </Grid>
            )
            }
        </Grid>
        <Grid item xs={12} sm={6} >
            {!floor.rooms ? <></> : <AllRoomsEdit rooms={floor.rooms} setRooms={setRooms}></AllRoomsEdit>}
        </Grid>
    </Grid>;
});
function AllRoomsEdit({ rooms, setRooms }: { rooms: RoomWithDeleted[], setRooms: (rooms: Room) => void }) {
    return <>
        <div style={{ display: "flex", justifyItems: "left", alignItems: "center", marginBottom: "1rem" }}>
            <Typography variant="subtitle2" style={{ marginRight: "0.25rem" }}>Rooms on this floor*</Typography>
        </div>

        {rooms.length === 0
            ? <tr><td colSpan={4}>No rooms in this floor</td></tr>
            : rooms
                .map((room, index) => [room, index] as const)
                .filter(([r]) => !r.isDeleted)
                .map(([r, i]) => <RoomEdit key={i} room={r} setRoom={setRooms}></RoomEdit>)}
    </>;
}
function RoomEdit({ room, setRoom }: { room: RoomWithDeleted, setRoom: (rooms: Room) => void }) {
    return <>
        <div style={{ display: "flex", justifyItems: "left", alignItems: "center", marginBottom: "1rem" }}>
            <IconButton onClick={e => setRoom(room)}><Icon>visibility</Icon></IconButton>
            <TextField disabled fullWidth value={room.number}></TextField>
        </div>
    </>;
}
