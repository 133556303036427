import * as actions from "../actions/user";
import { ActionsOf } from "../utils";
import { init } from "./utils";
import { headers, localStorageUserKey } from "../../services/config";

export function loginUser(
    state = init<{ username?: string; token?: string; roles?: Array<"admin" | "global" | "local" | "visitor"> }>({}),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_USER":
        return action.user;
    case "DELETE_USER":
        delete headers.Authorization;
        window.localStorage.removeItem(localStorageUserKey);
        return { roles:[] };
    default:
        return state;
    }
}
