import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useTitle, useLoadData, withSuppressLoading } from "../store/utils";
import { makeStyles, Typography, FormLabel, Grid, Dialog, CircularProgress, Button } from "@material-ui/core";
import ImageApi from "../components/ImageApi";
import { getArtist } from "../services/admin.service";
import { Artist } from "../models/Artist";
import { getImage, getQRCode } from "../services/media.service";
const useStyles = makeStyles(theme => ({
    progress: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100px!important",
        height: "100px!important"
    },
    viewRoot: {
        "& label": {
            color: "gray",
            textTransform: "capitalize",
            fontSize: "1rem",
            fontWeight: 400
        },
        "& span": {
        },
        "& legend": {
            color: "#0C69B1", //krohneblue
            margin: "0.75rem 0 0.25rem",
            textTransform: "uppercase",
            fontSize: "0.9rem",
            fontWeight: 500,
            borderBottom: "1px solid #c0c0c0",
            paddingBottom: 7
        }
    },
    qrCodeImageImageContainer: {
        margin: 0,
        padding: 0,
        width: "25%",
        maxHeight: "85vh",
        display: "flex",
        justifyContent: "center"
    },
    qrCodeImg: {
        backgroundColor: "lightgray",
        objectFit: "contain",
    },
    imageContainer: {
        margin: 0,
        padding: 0,
        width: "75%",
        minHeight: "300px",
        maxHeight: "85vh",
        display: "flex",
        justifyContent: "center"
    },
    mainImg: {
        backgroundColor: "lightgray",
        width: "100%",
        height: "100%",
        objectFit: "contain", // use "scale-down" to see picture at real size (not as the size of the) else "containt" to scale to parent size
    }
}));

export default withRouter(function (p: RouteComponentProps<{ id: string, name: string }>) {
    useTitle("Artist");
    const classes = useStyles();
    const [dialogImage, setDialogImage] = React.useState<string>();
    const [artist, setArtist] = React.useState<Artist>();
    const [qrCode, setQrCode] = React.useState<string>();

    useLoadData(async () => {
        let result = await getArtist(+p.match.params.id);

        let qr = await getQRCode(p.location.pathname.substr(1));
        const myBlob = new Blob([qr.data]);
        setQrCode(URL.createObjectURL(myBlob));

        setArtist(result.data);
    }, []);
    // dialog stuff
    const handleOpen = (imageId: string | undefined) => async () => {
        if (imageId) {
            try {
                setLoaded(false);
                if (!open) setOpen(true);
                const result = await withSuppressLoading(() => getImage(imageId, "Normal"));
                const blob = new Blob([result.data]);
                setDialogImage(URL.createObjectURL(blob));
                setLoaded(true);
            } catch (e) {
                setOpen(false);
            }
        }
    };
    const handleOpenQRCode = () => {
        setLoaded(false);
        setOpen(true);
        setDialogImage(qrCode);
        setLoaded(true);
    }

    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);

    const handleClose = () => {
        setDialogImage("");
        setOpen(false);
    };

    if (!artist) {
        return null;
    }
    return (
        <div className="ArtGallery-Artist-View">
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
                fullScreen
                open={open}
                onClose={handleClose}>
                <div className="PopupImageContainer">
                    <CircularProgress className={classes.progress} style={loaded ? { display: "none" } : { display: "block" }} />
                    <img onClick={handleClose} alt="" src={dialogImage} />
                </div>
            </Dialog>
            <Grid container className={classes.viewRoot} spacing={6}>
                <Grid item className={classes.imageContainer} xs={12} sm={8} lg={9} style={{ backgroundColor: "" }}>
                    {!artist.picture ?
                        <img src="/error_loading.png" alt="" /> :
                        <ImageApi className={classes.mainImg} src={artist.picture!} imageType="Tile" onClick={handleOpen(artist.picture!)} />}
                </Grid>

                <Grid item xs={12} sm={4} lg={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom>
                                {`${artist.firstName + " " + artist.lastName}`}
                            </Typography>
                        </Grid>
                        <Grid item style={{ display: "flex", justifyContent: "end" }} xs={6}>
                            <Button
                                title="Get the QR Code of this item"    
                                onClick={handleOpenQRCode}
                                variant="outlined"
                                color="primary">
                                QR Code
                            </Button>
                        </Grid>

                        {artist.kind &&
                            <Grid item xs={12}>
                                <FormLabel component="legend">Artist kind</FormLabel>
                                <Typography>
                                    <span>{artist.kind}</span>
                                </Typography>
                            </Grid>}

                        <Grid item xs={12}>
                            <FormLabel component="legend">Country</FormLabel>
                            <Typography>
                                <span>{artist.country!.name}</span>
                            </Typography>
                        </Grid>

                        {artist.description &&
                            <Grid item xs={12}>
                                <FormLabel component="legend">Description</FormLabel>
                                <Typography>
                                    <span>{artist.description}</span>
                                </Typography>
                            </Grid>}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
});
