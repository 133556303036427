import React from "react";
import AutocompleteSelect from "./autocomplete-selector";
import { AutocompleteData } from "../models/AutocompleteData";
import { AxiosResponse } from "axios";
import { DownshiftState, StateChangeOptions } from "downshift";
import Downshift from "downshift";


export const handleTextAutoCompleteChange = <T,>(name: keyof T, values: T, setValues: (v: T) => void) => (e: string | undefined) => {
    setValues({ ...values, [name]: e });
};

export function TextAutoCompleteSelector(props: {
    getSuggestions: (key: string) => Promise<AxiosResponse<AutocompleteData[]>>;
    initialSelectedItem?: string;
    onSelect: (s: undefined | string) => void;
    label: string;
    name?: string;
    required: boolean;
    placeholder?: string;
    disabled?: boolean;
}) {
    function stateReducer(
        state: DownshiftState<string>,
        changes: StateChangeOptions<string>
    ): Partial<StateChangeOptions<string>> {
        switch (changes.type) {
            case Downshift.stateChangeTypes.changeInput:
                return {
                    ...changes,
                    selectedItem: changes.inputValue
                };
            case Downshift.stateChangeTypes.blurInput:
                return {
                    ...changes,
                    inputValue: state.inputValue,
                    selectedItem: state.inputValue
                };
            default:
                return changes;
        }
    }
    return <AutocompleteSelect
        {...props}
        stateReducer={stateReducer}
        placeholder={props.placeholder || `Type an ${props.label} or pick from the list`}
        getSuggestions={async text => (await props.getSuggestions(text)).data.map(d => d.value!)}
        initialSelectedItem={props.initialSelectedItem}
        disabled={props.disabled}
        display={s => s} />;
}