import { PropsWithChildren } from "react";
import { useSelector } from "../store";
import React from "react";

export function IsAdmin(p: PropsWithChildren<{}>) {
    const roles = useSelector(app => app.loginUser.roles);
    if (roles && (roles.includes("admin") || roles.includes("global"))) {
        return <>{p.children}</>;
    } else {
        return <></>;
    }
}
export function AdminOnly(p: PropsWithChildren<{}>) {
    const roles = useSelector(app => app.loginUser.roles);
    if (roles && (roles.includes("admin"))) {
        return <>{p.children}</>;
    } else {
        return <></>;
    }
}
export function IsVisitor(p: PropsWithChildren<{}>) {
    const roles = useSelector(app => app.loginUser.roles);
    if (roles && roles.includes("visitor")) {
        return <>{p.children}</>;
    } else {
        return <></>;
    }
}