import React, {
    FunctionComponent,
    useEffect,
    useState,
    useCallback
} from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
    Grid,
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    Card,
    CardContent,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import { Formik, FormikProps, Form } from "formik";
import { format } from "date-fns";

import { useSelector } from "../../store";

import * as adminService from "../../services/admin.service";
import * as placementService from "../../services/placement.service";
import { Placement as IPlacement } from "../../models/Placement";
import { Message as IMessage } from "../../models/Message";

import Messages from "./messages";
import ArtItemPicker from "./art-item-picker";
import ArtItemAssign from "./art-item-assign";
import ArtItemSelector from "./art-item-selector";
import ArtItem from "./art-item";
import { useTitle } from "../../store/utils";
import { Prompt } from "react-router-dom";
import AutocompleteSelect from "../../components/autocomplete-selector";
import { CreatePlacementRequest } from "../../models/CreatePlacementRequest";
import { ArtItemBorrowAssign } from "./art-item-borrow-assign";
import { TextAutoCompleteSelector } from "../../components/autocomplete-selector-text";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacingBox: {
            marginRight: "5px",
        },
        btnBox: {
            flexWrap: "wrap-reverse",
            marginBottom: "1rem",
            "& button:last-child": {
                marginLeft: "1rem"
            }
        }
    }
    ));
type EditPlacementParams = { id: string };

interface EditPlacementForm extends Omit<CreatePlacementRequest, "items"> {
    creator?: string;
}

const EditPlacement: FunctionComponent<
    RouteComponentProps<EditPlacementParams>
> = ({ match: { params } }) => {
    useTitle("Edit Placement");
    const classes = useStyles();
    const placementId = parseInt(params.id, 10);
    const username = useSelector(s => s.loginUser.username);

    React.useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [modified, setModified] = useState<boolean>(false);
    const [placement, setPlacement] = useState<IPlacement | null>(null);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [location, setLocation] = useState();
    const allLocations = async (key: string) => {
        const result = await adminService.searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC" });
        return result.data.content;
    };
    const myLocations = async (key: string) => {
        const result = await adminService.searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC", onlyMyLocations: true });
        return result.data.content;
    };
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const loadMessages = useCallback(async () => {
        try {
            const response = await placementService.getPlacementMessages(placementId);
            setMessages(response.data);
        } catch (e) {
            // TOOO: hwo to handle error
            // alert("TBD: handle loading placement messages error");
        }
    }, [placementId]);

    const loadPlacement = useCallback(async () => {
        try {
            const response = await placementService.getPlacement(placementId);
            setPlacement(response.data);
            setSelectedItems(response.data.items!.map(i => i.artItem!.id));

            const result = await adminService.getLocation(response.data!.locationId!);
            setLocation(result.data);
            await loadMessages();
        } catch (e) {
            // TOOO: hwo to handle error
            // alert("TBD: handle loading placement error");
        }
    }, [placementId, loadMessages]);
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        loadPlacement();
    }, [loadPlacement]);

    if (!placement || !allLocations.length || !myLocations.length) {
        return null;
    }

    const placementItems = placement.items!.map(i => i.artItem!);

    const onSendMessage = async (message: string) => {
        await placementService.sendMessages({
            message,
            placementId
        });
        await loadMessages();
    };

    const onRequestApproval = async () => {
        await placementService.requestPlacementApproval({ placementId });
        await loadPlacement();
    };
    const onResendNotification = async () => {
        await placementService.sendEmailNotification({ placementId });
        await loadPlacement();
    };
    const onAcceptPlacement = async () => {
        await placementService.approvePlacement({
            placementId,
            items: selectedItems
        });
        await loadPlacement();
    };
    const onSendToDestination = async () => {
        await placementService.sendPlacement({ placementId });
        await loadPlacement();
    };
    const onReceivePlacement = async () => {
        await placementService.receivePlacement({ placementId });
        await loadPlacement();
    };
    const onFinalizePlacement = async () => {
        await placementService.finalizePlacement({ placementId });
        await loadPlacement();
    };
    const onBorrowProposal = async () => {
        await placementService.notifyBorrower({ placementId });
        await loadPlacement();
    };


    const sendBorrowArtItems = (
        <Button color="secondary" variant="contained" onClick={onSendToDestination}>
            Send Art Items
        </Button>
    );
    const sendBorrowProposal = (disabled?: boolean) => (
        <Button color="primary" disabled={disabled || false} variant="contained" onClick={onBorrowProposal}>
            Send Borrow Proposal
        </Button>
    );
    const requestApproval = (disabled: boolean) => (
        <Button variant="contained" color="secondary" onClick={onRequestApproval} disabled={disabled}>
            Request approval
        </Button>
    );
    const resendNotification = (
        <Button variant="contained" onClick={onResendNotification}>
            Resend notification
        </Button>
    );
    const acceptPlacement = (
        <Button
            color="secondary"
            variant="contained"
            onClick={onAcceptPlacement}
            disabled={!selectedItems.length}
        >
            Accept the placement
        </Button>
    );

    const closePlacement = (
        <Button variant="contained" color="secondary" onClick={onFinalizePlacement} disabled={!placementItems.every(i => i.status === "AVAIL")}>
            Close Placement
        </Button>
    );
    const sendToDestination = (
        <Button variant="contained" color="primary" onClick={onSendToDestination}>
            Send to destination
        </Button>
    );
    const receivePlacement = (
        <Button variant="contained" onClick={onReceivePlacement}>
            Receive
        </Button>
    );

    const canFinalize = placementItems.every(i => i.roomId !== null); //&& placementItems.every(i => i.status === "AVAIL");
    const finalizePlacement = (
        <Button
            color="secondary"
            variant="contained"
            onClick={onFinalizePlacement}
            disabled={!canFinalize}
        >
            Finalize placement
        </Button>
    );

    const { status, creator } = placement;
    const isOwnedPlacement = creator === username;

    const renderHeader = (formikProps: FormikProps<EditPlacementForm>) => {
        return (
            <>
                <Typography variant="h5" style={{ marginBottom: 20 }}>
                    Edit placement
                </Typography>
                <Box display="flex" style={{ flexWrap: "wrap-reverse" }}>
                    <Box flexGrow={1}>
                        <strong>Status:</strong> {status}
                        <br />
                        <strong>Date:</strong>{" "}
                        {placement
                            ? format(new Date(placement.created!), "dd/MM/yyyy HH:mm")
                            : ""}
                        <br />
                        {placement.finalized && <>
                            <strong>Finalized Date: </strong> {format(new Date(placement.finalized), "dd/MM/yyyy HH:mm")}
                        </>}
                    </Box>
                    <Box alignItems="flex-end" className={classes.btnBox}>
                        {status === "DRAFT" && (
                            <Button
                                disabled={
                                    !Object.values(formikProps.touched).some(Boolean)
                                }
                                variant="contained"
                                color="primary"
                                type="submit"
                                onClick={() => formikProps.handleSubmit()}
                            >
                                Update placement
                            </Button>
                        )}
                        {status === "DRAFT" &&
                            placement.type === "BORROW" &&
                            <>
                                {sendBorrowProposal(Object.values(formikProps.touched).some(Boolean))}
                            </>
                        }
                        {status === "WAITING_SENDING" &&
                            placement.type === "BORROW" &&
                            <>
                                <span className={classes.spacingBox}>
                                    {sendBorrowProposal(Object.values(formikProps.touched).some(Boolean))}
                                </span>
                                <span className={classes.spacingBox}>
                                    {sendBorrowArtItems}
                                </span>

                            </>}
                        {status === "DRAFT" &&
                            isOwnedPlacement && placement.type !== "BORROW" &&
                            requestApproval(Object.values(formikProps.touched).some(Boolean))}
                        {status === "WAITING_ACCEPTANCE" &&
                            isOwnedPlacement &&
                            resendNotification}

                        {status === "WAITING_ACCEPTANCE" &&
                            !isOwnedPlacement &&
                            acceptPlacement}

                        {status === "WAITING_SENDING" && placement.type! === "SEND" &&
                            isOwnedPlacement &&
                            sendToDestination}

                        {status === "WAITING_SENDING" && placement.type! === "REQUEST" &&
                            !isOwnedPlacement &&
                            sendToDestination}


                        {status === "SENT_TO_DESTINATION" &&
                            placement.type === "BORROW" &&
                            closePlacement}
                        {status === "SENT_TO_DESTINATION" && placement.type! === "SEND" &&
                            !isOwnedPlacement &&
                            receivePlacement}
                        {status === "SENT_TO_DESTINATION" && isOwnedPlacement &&
                            placement.type! === "REQUEST" &&
                            receivePlacement}

                        {status === "RECEIVED" && isOwnedPlacement && placement.type! === "REQUEST" &&
                            finalizePlacement}
                        {status === "RECEIVED" && !isOwnedPlacement && placement.type! === "SEND" &&
                            finalizePlacement}
                    </Box>
                </Box>
            </>
        );
    };

    const renderDetails = (formikProps: FormikProps<EditPlacementForm>) => {
        const {
            values,
            errors,
            touched,
            setValues,
            handleChange,
            setFieldValue,
            setFieldTouched
        } = formikProps;
        const handleDescriptionChange = (e: any) => {
            e.preventDefault();
            handleChange(e);
            setFieldTouched("description", true);
        };
        const handleEmailChange = (e: any) => {
            handleChange(e);
            setFieldTouched("receiverEmail", true);
        };
        const locationsSuggestions =
            placement.status === "DRAFT"
                ? placement.type === "SEND"
                    ? allLocations
                    : myLocations
                : allLocations;
        const userLabel =
            placement.type === "SEND"
                ? "Placement proposed by"
                : "Placement requested by";

        return (
            <Grid container spacing={2} style={{ marginTop: "0.5rem" }}>
                <Grid item xs={6}>
                    <TextField disabled label="Placement ID" value={placement.id} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={6}>
                    <TextField disabled label="Type" value={placement.type} style={{ width: "100%" }} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <FormControl fullWidth>
                        {placement.type === "BORROW" ? <>
                            <TextField className="inputStyle"
                                disabled={placement.status !== "DRAFT"}
                                variant="outlined"
                                fullWidth
                                type="email"
                                id="receiverEmail"
                                name="receiverEmail"
                                label="Receiver email address"
                                placeholder="Add email..."
                                value={values.receiverEmail}
                                onChange={handleEmailChange}
                            />
                            <TextAutoCompleteSelector
                                disabled={placement.status !== "DRAFT"}
                                label="Borrow Destination"
                                required={false}
                                initialSelectedItem={values.destination}
                                getSuggestions={text => adminService.searchAutoCompleteData({ text, dataType: "BORROW_DESTINATION" })}
                                onSelect={e => {
                                    setFieldTouched("destination", true);
                                    setFieldValue("destination", e ?? "")
                                }}
                            />
                        </> :
                            <AutocompleteSelect
                                selectedItem={location || ""}
                                getSuggestions={locationsSuggestions}
                                display={e => e ? e.name : ""}
                                disabled={placement.status !== "DRAFT"}
                                label={placement.type! === "SEND" ? "Send to Location" : "Receive for Location"}
                                onSelect={e => {
                                    if (e) {
                                        setValues({ ...values, locationId: e!.id });
                                        setLocation(e);
                                        setFieldTouched("locationId", true);
                                    }
                                }}
                                required={false}
                            />}
                    </FormControl>
                </Grid>
                {placement.type !== "BORROW" &&
                    <Grid item xs={12}>
                        <TextField
                            disabled
                            label={userLabel}
                            value={placement.creator}
                            fullWidth
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        disabled={placement.status !== "DRAFT"}
                        fullWidth
                        multiline
                        rows="3"
                        rowsMax="10"
                        id="description"
                        name="description"
                        helperText={touched.description ? errors.description : ""}
                        error={touched.description && Boolean(errors.description)}
                        label="Description"
                        placeholder="Add description..."
                        value={formikProps.values.description}
                        onChange={handleDescriptionChange}
                    />
                </Grid>
            </Grid>
        );
    };

    const onSelectorChange = async (ids: number[]) => {
        await placementService.updatePlacement(placementId, {
            // description:placement.description,
            // locationId:placement.locationId,
            items: ids
        });
        await loadPlacement();
    };

    const renderItems = () => {
        if (status === "DRAFT" && placement.type === "BORROW") {
            return (
                <ArtItemSelector
                    initialSelection={placementItems}
                    locations={allLocations}
                    onChange={onSelectorChange}
                    placementType={placement.type}
                />
            );
        }
        if (status === "DRAFT" && isOwnedPlacement) {
            return (
                <ArtItemSelector
                    initialSelection={placementItems}
                    locations={placement.type === "SEND" ? myLocations : allLocations}
                    onChange={onSelectorChange}

                />
            );
        }
        if (status === "WAITING_ACCEPTANCE" && isOwnedPlacement) {
            return (
                <ArtItemSelector
                    initialSelection={placementItems}
                    locations={placement.type === "SEND" ? myLocations : allLocations}
                    onChange={onSelectorChange}

                />
            )
        }
        if (status === "WAITING_ACCEPTANCE" && !isOwnedPlacement) {
            return (
                <ArtItemPicker items={placementItems} onChange={setSelectedItems} />
            );
        }
        if (status === "WAITING_SENDING" && placement.type === "BORROW") {
            return (
                <ArtItemSelector
                    initialSelection={placementItems}
                    locations={allLocations}
                    onChange={onSelectorChange}
                    placementType={placement.type}
                />
            );
        }
        if (status === "SENT_TO_DESTINATION" && placement.type === "BORROW") {
            return (
                <ArtItemBorrowAssign
                    initialSelection={placementItems}
                    placementId={placement.id!}
                    onChange={async () => await loadPlacement()}
                />
            );
        }

        if ((status === "RECEIVED" && isOwnedPlacement && placement.type! === "REQUEST") || (status === "RECEIVED" && !isOwnedPlacement && placement.type! === "SEND")) {
            return (
                <ArtItemAssign
                    items={placementItems}
                    onChange={loadPlacement}
                    locationId={placement.locationId!}
                    placementId={placementId}
                />
            );
        }

        return placementItems.map((item, i) => <ArtItem item={item} key={item.name + i} />);
    };
    return (
        <React.Fragment>
            <Prompt
                when={modified}
                message='You have unsaved changes, are you sure you want to leave?'
            />
            <Formik<EditPlacementForm>
                initialValues={{
                    locationId: placement.locationId!,
                    description: placement.description!,
                    receiverEmail: placement.receiverEmail!,
                    placementType: placement.type!,
                    creator: placement.creator,
                    destination: placement.destination,
                }}
                validateOnChange={false} validateOnBlur={false}
                // validationSchema={validationSchema}
                onSubmit={async (values: EditPlacementForm, { resetForm }) => {
                    resetForm(values);
                    await placementService.updatePlacement(placementId, {
                        items: placementItems.map(i => i.id),
                        locationId: values.locationId,
                        description: values.description,
                        receiverEmail: values.receiverEmail,
                        destination: values.destination,
                    });
                    await loadPlacement();
                }}
            >
                {formikProps => {
                    if (!formikProps.isSubmitting) {
                        setModified(formikProps.dirty);
                    } else {
                        setModified(false);
                    }
                    setModified(formikProps.dirty);
                    return (
                        <Form>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={3} direction="row">
                                        <Grid item md={12} style={{ width: "100%" }}>
                                            {renderHeader(formikProps)}
                                        </Grid>
                                        <Grid item md={12}>
                                            <Grid container spacing={3}>
                                                <Grid item md={4} lg={3}>
                                                    <Typography variant="h6">Details:</Typography>
                                                    {renderDetails(formikProps)}
                                                </Grid>
                                                {placement.type === "BORROW" ?
                                                    <Grid item md={8} lg={9} style={{ width: "100%" }}>
                                                        <Typography variant="h6">Items:</Typography>
                                                        {renderItems()}
                                                    </Grid>
                                                    :
                                                    <>
                                                        <Grid item md={8} lg={6}>
                                                            <Typography variant="h6">Items:</Typography>
                                                            {renderItems()}
                                                        </Grid>
                                                        <Grid item xs={12} md={4} lg={3}>
                                                            <Typography variant="h6">Messages:</Typography>
                                                            <Messages
                                                                messages={messages}
                                                                readonly={placement.status === "FINALIZED"}
                                                                username={placement.creatorFullName!}
                                                                onSend={onSendMessage}
                                                            />
                                                        </Grid>
                                                    </>}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
};

export default withRouter(EditPlacement);
