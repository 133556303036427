import { Resource } from "../models/Resource";
import { ResponseEntity } from "../models/ResponseEntity";
import { UploadFileResponse } from "../models/UploadFileResponse";
import { baseUrl, headers } from "./config";
import { appendFormData } from "../utils";
import { AxiosResponse } from "axios";
import axios from "axios";
export function downloadFile(fileStorageId: string, storageImageType: "Normal" | "Tile" | "Thumbnail"): Promise<AxiosResponse<Resource>> {
    const url = `${baseUrl}/api/media/download-file/${fileStorageId}/${storageImageType}`;
    return axios.get<Resource>(url, { headers });
}
export function getImage(fileStorageId: string, storageImageType: "Normal" | "Tile" | "Thumbnail"): Promise<AxiosResponse<Blob>> {
    const url = `${baseUrl}/api/media/image/${fileStorageId}/${storageImageType}`;
    return axios.get<Blob>(url, { responseType: "blob", headers });
}
export function getQRCode(path: string): Promise<AxiosResponse<Blob>> {
    const url = `${baseUrl}/api/media/qrcode?path=${path}`;
    return axios.get<Blob>(url, { responseType: "blob", headers });
}
export function regenerateTiles(): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/media/regenerate-tiles`;
    return axios.get<ResponseEntity>(url, { headers });
}
export function uploadFile(file: File): Promise<AxiosResponse<UploadFileResponse>> {
    const data = new FormData();
    appendFormData(data, "file", file);
    const url = `${baseUrl}/api/media/upload-file`;
    return axios.post<UploadFileResponse>(url, data, { headers });
}
export function uploadMultipleFiles(files: File[]): Promise<AxiosResponse<UploadFileResponse[]>> {
    const data = new FormData();
    appendFormData(data, "files", files);
    const url = `${baseUrl}/api/media/upload-multiple-files`;
    return axios.post<UploadFileResponse[]>(url, data, { headers });
}
