import "./m-table-toolbar-patch";
import React, { useState, useEffect } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import MenuBar from "./components/MenuBar";
import ArtItemsList from "./pages/art-item-list";
import ArtItemsEdit from "./pages/art-item-edit";
import LoginPage from "./pages/login-page";
import { Grid, Dialog, Container } from "@material-ui/core";
import Header from "./components/Header";
import CompanyList from "./pages/company-list";
import CompanyEdit from "./pages/company-edit";
import LocationList from "./pages/locations-list";
import LocationEdit from "./pages/locations-edit";
import ArtistEdit from "./pages/artists-edit";
import UserList from "./pages/user-list";
import UserEdit from "./pages/user-edit";

import {
    List as PlacementsList,
    Add as AddPlacement,
    Edit as EditPlacement
} from "./pages/placement";
import { useDispatch, useSelector } from "./store";
import { route, urls as u, routeTo, startUrl } from "./navigation";
import { headers, localStorageUserKey } from "./services/config";
import { ArtItemTiles } from "./pages/art-item-tile";
import { ArtistTiles } from "./pages/artist-tile";
import { setLoginUser, deleteLoginUser } from "./store/actions/user";
import { useLoadData, withSuppressLoading } from "./store/utils";
import * as admin from "./services/admin.service";
import * as auth from "./services/auth.service";
import ArtistList from "./pages/artists-list";
import { setMyLocations } from "./store/actions/location";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropagateLoader from "react-spinners/PropagateLoader";
import { IsAdmin, AdminOnly } from "./components/Roles";
import History from "./pages/history";
import SearchItems from "./pages/search-all-list";
import ArtItemView from "./pages/art-item-view";
import ArtistView from "./pages/artist-view";
import { SearchAllTiles } from "./pages/search-all-tiles";
import { PaginationContext, defaultPages, PagingContext, tablePages, ArtItemCloneContext } from "./contexts";
import LocationView from "./pages/location-view";
import { ImportOldData } from "./pages/import-old";
import { SecurityLogPage } from "./pages/security-log";
import { AttributesLog } from "./pages/attributes-list";
import { ArtItem } from "./models/ArtItem";

interface ISuppressedConfig {
    SuppressLoading: boolean;
}

function HeaderAndMenu() {
    return <>
        <Header></Header>
        <MenuBar></MenuBar>
    </>;
}

const App = withRouter(p => {
    const dispatch = useDispatch();
    const [isUserChecked, setIsUserChecked] = useState(false);
    const [loadingCount, setLoadingCount] = useState(0);
    const [pagingState, setPagingState] = useState(tablePages);
    const [cloneState, setCloneState] = useState<ArtItem>(null!);
    const pageState = useState(defaultPages);
    useEffect(() => {
        axios.interceptors.request.use(r => {
            if (r && r.headers && r.headers.SuppressLoading !== true) {
                setTimeout(() => setLoadingCount(v => v + 1), 1);
            }
            return r;
        });
        const id = axios.interceptors.response.use(
            r => {
                if (r && r.config && r.config.headers.SuppressLoading !== true) {
                    setLoadingCount(v => v - 1);
                }
                return r;
            },
            e => {
                if (
                    (e && e.config && (e.config.headers as ISuppressedConfig)).SuppressLoading !== true
                ) {
                    setLoadingCount(v => v - 1);
                    if (e.response.data.status === 401) {
                        dispatch(deleteLoginUser());
                        routeTo(p, u.login);
                    }
                    else {
                        if (e.response.data.code !== 412) {
                            if (e.response.data.message) {
                                toast(`Error: ${e.response.data.message}`);
                            } else {
                                toast(`An error ocurred communicating with the server: ${e}`);
                            }
                        } else {
                            toast(
                                "The action cannot be done because this item is linked with another item"
                            );
                        }
                    }
                }
                if (e.response.data.status === 401) {
                    dispatch(deleteLoginUser());
                    routeTo(p, u.login);
                }
                return Promise.reject(e);
            }
        );
        return () => axios.interceptors.response.eject(id);
        //eslint-disable-next-line
    }, []);
    useLoadData(async () => {
        const savedUser = window.localStorage.getItem(localStorageUserKey);
        if (!savedUser) {
            routeTo(p, u.login);
        } else {
            const user: {
                username: string;
                token: string;
                roles: Array<"admin">;
            } = JSON.parse(savedUser);
            Object.assign(headers, { Authorization: `Bearer ${user.token}` });
            try {
                await withSuppressLoading(auth.ping);
                await dispatch(setLoginUser(user));
                const result = await withSuppressLoading(() => admin.searchLocations({ onlyMyLocations: true }));
                dispatch(setMyLocations(result.data.content));
                if (p.location.pathname === "/") {
                    routeTo(p, startUrl, [], displayMode);
                }
            } catch (e) {
                delete headers.Authorization;
                routeTo(p, u.login);
            }
        }
        setIsUserChecked(true);
    });
    const displayMode = useSelector(app => app.displayMode);
    const searchToken = u.searchView().split("/")[2];
    const pathToken = p.location.pathname.split("/")[2];
    if (!isUserChecked) return <div></div>;

    return (
        <PaginationContext.Provider value={pageState}>
            <Grid container direction="column" alignItems="center">
                <Dialog className="loadingAnimationDialog" open={loadingCount > 0}>
                    <div
                        style={{
                            padding: "150px",
                            backgroundColor: "rgba(255,0,0,0)"
                        }}
                    >
                        <PropagateLoader size={25} color={"#F54B00"} loading />
                    </div>
                </Dialog>
                <Grid item container alignItems="stretch" direction="column">
                    <Route path="/app" component={HeaderAndMenu} ></Route>
                    {pathToken === searchToken ? (<>
                        {/* <SearchItems></SearchItems> */}

                        <Container style={{ maxWidth: "92%", padding: "2rem 0 8rem" }}>
                            <Route
                                path={route(u.searchAllList, ["word"])}
                                exact
                                component={SearchItems}
                            ></Route>
                            <Route
                                path={route(u.searchAllTiles, ["word"])}
                                exact
                                component={SearchAllTiles}
                            ></Route>
                        </Container>
                    </>
                    ) : (
                            <>
                                <Container style={{ maxWidth: "92%", padding: "2rem 0 8rem" }}>
                                    {searchToken === pathToken ? <Redirect to={route(u.artItemTiles)} /> : null}
                                    <Route
                                        path={route(u.artistView, ["id", "name"])}
                                        component={ArtistView}
                                    ></Route>
                                    <Route
                                        path={route(u.artItemView, ["id"])}
                                        component={ArtItemView}
                                    ></Route>
                                    <Route
                                        path={route(u.artItemTiles)}
                                        exact
                                        component={ArtItemTiles}
                                    ></Route>
                                    <Route
                                        path={route(u.login, ["id", "name"])}
                                        component={LoginPage}
                                    ></Route>
                                    <Route
                                        path={route(u.artistTiles)}
                                        exact
                                        component={ArtistTiles}
                                    ></Route>
                                    <Route
                                        path={route(u.placementsAdd)}
                                        exact
                                        component={AddPlacement}
                                    ></Route>
                                    <Route
                                        path={route(u.placementsEdit, ["id"])}
                                        component={EditPlacement}
                                    ></Route>
                                    <PagingContext.Provider value={[pagingState, setPagingState]}>
                                        <Route
                                            path={route(u.placementsList)}
                                            exact
                                            component={PlacementsList}
                                        ></Route>
                                        <Route
                                            path={route(u.artistList)}
                                            exact
                                            component={ArtistList}
                                        ></Route>
                                        <Route
                                            path={route(u.locationList)}
                                            exact
                                            component={LocationList}
                                        ></Route>
                                        <ArtItemCloneContext.Provider value={[cloneState, setCloneState]}>
                                            <Route
                                                path={route(u.artItemList)}
                                                exact
                                                component={ArtItemsList}
                                            ></Route>
                                            <Route
                                                path={route(u.artItemAdd)}
                                                exact
                                                component={ArtItemsEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.artItemEdit, ["name", "id"])}
                                                component={ArtItemsEdit}
                                            ></Route>
                                        </ArtItemCloneContext.Provider>
                                        <AdminOnly>
                                            <Route
                                                path={route(u.securityLog)}
                                                exact
                                                component={SecurityLogPage}
                                            ></Route>
                                            <Route
                                                path={route(u.importOld)}
                                                exact
                                                component={ImportOldData}
                                            ></Route>
                                            <Route
                                                path={route(u.autocompleteData)}
                                                exact
                                                component={AttributesLog}
                                            ></Route>
                                        </AdminOnly>
                                        <IsAdmin>
                                            <Route
                                                path={route(u.locationView, ["id"])}
                                                component={LocationView}
                                            ></Route>
                                            <Route
                                                path={route(u.companyList)}
                                                exact
                                                component={CompanyList}
                                            ></Route>
                                            <Route
                                                path={route(u.companyEdit, ["id", "name"])}
                                                component={CompanyEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.companyAdd)}
                                                exact
                                                component={CompanyEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.locationAdd)}
                                                exact
                                                component={LocationEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.locationEdit, ["id", "name"])}
                                                component={LocationEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.artistEdit, ["id", "name"])}
                                                component={ArtistEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.artistAdd)}
                                                exact
                                                component={ArtistEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.userList)}
                                                exact
                                                component={UserList}
                                            ></Route>
                                            <Route
                                                path={route(u.userEdit, ["id", "name"])}
                                                component={UserEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.userAdd)}
                                                exact
                                                component={UserEdit}
                                            ></Route>
                                            <Route
                                                path={route(u.history, ["linkId", "defaultEntity"])}
                                                component={History}
                                            ></Route>
                                            <Route
                                                path={route(u.history, [])}
                                                exact
                                                component={History}
                                            ></Route>
                                        </IsAdmin>
                                    </PagingContext.Provider>
                                </Container>
                            </>
                        )}
                </Grid>
                <ToastContainer></ToastContainer>
            </Grid>
        </PaginationContext.Provider>
    );
});

export default App;
