import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Button, Grid, Card, CardContent, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import AutocompleteSelect from "../components/autocomplete-selector";
import * as admin from "../services/admin.service";
import { useLoadData, useTitle } from "../store/utils";
import { CreateCompanyRequest } from "../models/CreateCompanyRequest";
import { Country } from "../models/Country";
import { routeTo, urls } from "../navigation";
import { Company } from "../models/Company";
import { Formik, Form } from "formik";
import { getKeys, setAttr, confirmAndDelete, isValidText } from "../utils";
import { translations } from "../translates";
import { Prompt } from "react-router-dom";

export default withRouter(function (p: RouteComponentProps<{ id?: string }>) {
    const [company, setCompany] = useState<
        CreateCompanyRequest & { id?: number; country?: Country; parent?: Company }
    >();

    useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [modified, setModified] = useState<boolean>(false);
    const setTitle = useTitle("");
    const keysObj: Record<string, 1> = {
        countryId: 1,
        name: 1,
        parentId: 1,
        owner: 1,
        type: 1,
    };

    const keys = getKeys<CreateCompanyRequest>(keysObj);
    const trans = translations.companyEdit;

    useLoadData(async () => {
        if (p.match.params.id) {
            const result = await admin.getCompany(+p.match.params.id);
            setTitle(`Edit Company - ${result.data.name}`);
            setCompany(result.data);
        } else {
            setTitle("Add Company");
            setCompany({
                name: "",
                owner: false,
                countryId: undefined!,
                type: "PRIVATE_PERSON",
            });
        }
    });
    if (!company) {
        return <div></div>;
    }

    const validate = (values: CreateCompanyRequest) => {
        const errors = {} as CreateCompanyRequest;
        if (values.name) {
            if (!isValidText(values.name)) {
                errors.name = translations.errors.enterOnlyCharacters;
            }
        }
        return errors;
    };

    async function countrySuggestions(key: string) {
        return (await admin.searchCountries({
            key,
            unpaged:true,
        })).data.content;
    }

    async function onSubmit(o: CreateCompanyRequest) {
        const companyRequest = setAttr<CreateCompanyRequest>(keysObj, o);
        if (p.match.params.id) {
            await admin.updateCompany(+p.match.params.id, companyRequest);
        } else {
            await admin.addCompany(companyRequest);
        }
        routeTo(p, urls.companyList);
    }
    if (company == null) {
        return <div>{trans.loading}</div>;
    } else {
        return (
            <React.Fragment>
                <Prompt
                    when={modified}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Formik<CreateCompanyRequest>
                    initialValues={company}
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({ values, handleChange, setValues, errors, dirty, isSubmitting }) => {
                        if (!isSubmitting) {
                            setModified(dirty);
                        } else {
                            setModified(false);
                        }
                        return (
                            <Form className="ArtGallery-Form-CompanyEdit">
                                <Card style={{ overflow: "visible" }}>
                                    <CardContent>
                                        <Grid container spacing={3} justify="space-between">
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="h5" gutterBottom>
                                                    {trans.editing} {values.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="edit-buttons">
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                    >
                                                        {trans.publish}
                                                    </Button>
                                                    <Button
                                                        onClick={e => {
                                                            routeTo(p, urls.companyList);
                                                        }}
                                                        fullWidth
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        {trans.discard}
                                                    </Button>
                                                    {!company.id ? <></> :
                                                        <Button
                                                            variant="outlined" color="secondary"
                                                            onClick={confirmAndDelete(trans.confirmDelete, async () => {
                                                                await admin.deleteCompany(company.id!);
                                                                routeTo(p, urls.companyList);
                                                            })}
                                                        >
                                                            {trans.delete}
                                                        </Button>}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" spacing={8}>
                                            <Grid item xs={12} md={6} lg={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <TextField
                                                    required
                                                    label={trans.companyNameLabel}
                                                    fullWidth
                                                    value={values.name}
                                                    id={keys.name}
                                                    onChange={handleChange}
                                                    error={errors.name ? true : false}
                                                    helperText={errors.name ? errors.name : null}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <AutocompleteSelect
                                                    getSuggestions={countrySuggestions}
                                                    label={trans.countryLabel}
                                                    initialSelectedItem={company.country}
                                                    display={o => o && o.name}
                                                    required={true}
                                                    onSelect={o => {
                                                        setValues({
                                                            ...values,
                                                            countryId: (o && o.id)!
                                                        });
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <FormControl>
                                                    <InputLabel >Owner Type</InputLabel>
                                                    <Select
                                                        placeholder="Select Type"
                                                        value={values.type}
                                                        onChange={(e) => setValues({ ...values, type: e.target.value as "COMPANY" | "PRIVATE_PERSON" })}
                                                    >
                                                        <MenuItem value="COMPANY">Company</MenuItem>
                                                        <MenuItem value="PRIVATE_PERSON">Private Person</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={values.owner}
                                                            id={keys.owner}
                                                            onChange={handleChange}
                                                            value="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Can own art items"
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Form>
                        );
                    }}
                />
            </React.Fragment>
        );
    }
});
