import React, { useState, useEffect, useRef } from "react";
import * as media from "../services/media.service";
import { withSuppressLoading } from "../store/utils";
type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    src: string;
    imageType: "Normal" | "Tile" | "Thumbnail";
    onSrcChange?: (src: string) => void
}
const ImageApi: React.FC<Props> = ({ imageType, src, onSrcChange, ...rest }) => {
    const [image, setImage] = useState<string>("");
    const _isMounted = useRef(true);
    useEffect(() => {
        _isMounted.current = true;
        (async function image() {
            try {
                if (src === null || !src) {
                    throw new Error();
                }
                const result = await withSuppressLoading(() => media.getImage(src, imageType));
                const myBlob = new Blob([result.data]);
                const url = URL.createObjectURL(myBlob);
                if (_isMounted.current)
                    setImage(url);
                if (onSrcChange) {
                    onSrcChange(url);
                }
            } catch (e) {
                if (_isMounted.current)
                    setImage("/error_loading.png");
            }
        }());
        return () => {
            _isMounted.current = false;
        };
        // eslint-disable-next-line
    }, [src, imageType]);
    return <img {...rest} alt="" src={image}></img>;
};
export default ImageApi;