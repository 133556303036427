import { a } from "../utils";
import { Location } from "../../models/Location";
import { Floor } from "../../models/Floor";
import { Room } from "../../models/Room";
import { Artist } from "../../models/Artist";
import { AutocompleteData } from "../../models/AutocompleteData";
export interface ArtItemsFilter {
    location?: Location[],
    floor?: Floor[],
    room?: Room[],
    artist?: Artist[],
    technique: AutocompleteData[],
    pictureQuality?: "LOW" | "MEDIUM" | "HIGH";
}
export const setArtItemsFilter = (obj: ArtItemsFilter) => a({ type: "SET_ART_ITEMS_FILTER", obj });