import { CreatePlacementRequest } from "../models/CreatePlacementRequest";
import { Placement } from "../models/Placement";
import { ApprovePlacementRequest } from "../models/ApprovePlacementRequest";
import { AssignNewLocationRequest } from "../models/AssignNewLocationRequest";
import { FinalizePlacementRequest } from "../models/FinalizePlacementRequest";
import { SendMessageRequest } from "../models/SendMessageRequest";
import { Message } from "../models/Message";
import { SendPlacementNotificationRequest } from "../models/SendPlacementNotificationRequest";
import { ChangeStatusForPlacementRequest } from "../models/ChangeStatusForPlacementRequest";
import { ReturnBorrowedItemsRequest } from "../models/ReturnBorrowedItemsRequest";
import { SearchPlacementRequest } from "../models/SearchPlacementRequest";
import { PagePlacement } from "../models/PagePlacement";
import { UpdatePlacementRequest } from "../models/UpdatePlacementRequest";
import { baseUrl, headers } from "./config";
import { AxiosResponse } from "axios";
import axios from "axios";
export function createPlacement(createPlacementRequest: CreatePlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement`;
    return axios.put<Placement>(url, createPlacementRequest, { headers });
}
export function approvePlacement(approvePlacementRequest: ApprovePlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/approve`;
    return axios.post<Placement>(url, approvePlacementRequest, { headers });
}
export function assignNewLocation(assignNewLocationRequest: AssignNewLocationRequest): Promise<AxiosResponse<void>> {
    const url = `${baseUrl}/api/admin/placement/assignNewLocation`;
    return axios.post<void>(url, assignNewLocationRequest, { headers });
}
export function finalizePlacement(finalizePlacementRequest: FinalizePlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/finalize`;
    return axios.post<Placement>(url, finalizePlacementRequest, { headers });
}
export function getLastArtItemPlacement(artItemId: number): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/forArtItem/${artItemId}`;
    return axios.get<Placement>(url, { headers });
}
export function sendMessages(sendMessageRequest: SendMessageRequest): Promise<AxiosResponse<Message>> {
    const url = `${baseUrl}/api/admin/placement/message`;
    return axios.put<Message>(url, sendMessageRequest, { headers });
}
export function getPlacementMessages(placementId: number): Promise<AxiosResponse<Message[]>> {
    const url = `${baseUrl}/api/admin/placement/messages/${placementId}`;
    return axios.get<Message[]>(url, { headers });
}
export function notifyBorrower(sendPlacementNotificationRequest: SendPlacementNotificationRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/notifyBorrower`;
    return axios.post<Placement>(url, sendPlacementNotificationRequest, { headers });
}
export function receivePlacement(changeStatusForPlacementRequest: ChangeStatusForPlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/receiveItems`;
    return axios.post<Placement>(url, changeStatusForPlacementRequest, { headers });
}
export function requestPlacementApproval(changeStatusForPlacementRequest: ChangeStatusForPlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/requestApproval`;
    return axios.post<Placement>(url, changeStatusForPlacementRequest, { headers });
}
export function returnBorrowedItems(returnBorrowedItemsRequest: ReturnBorrowedItemsRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/returnBorrowedItems`;
    return axios.post<Placement>(url, returnBorrowedItemsRequest, { headers });
}
export function search(searchPlacementRequest: SearchPlacementRequest): Promise<AxiosResponse<PagePlacement>> {
    const url = `${baseUrl}/api/admin/placement/search`;
    return axios.post<PagePlacement>(url, searchPlacementRequest, { headers });
}
export function sendPlacement(changeStatusForPlacementRequest: ChangeStatusForPlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/sendItems`;
    return axios.post<Placement>(url, changeStatusForPlacementRequest, { headers });
}
export function sendEmailNotification(sendPlacementNotificationRequest: SendPlacementNotificationRequest): Promise<AxiosResponse<void>> {
    const url = `${baseUrl}/api/admin/placement/sendNotification`;
    return axios.post<void>(url, sendPlacementNotificationRequest, { headers });
}
export function getPlacement(placementId: number): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/${placementId}`;
    return axios.get<Placement>(url, { headers });
}
export function updatePlacement(placementId: number, updatePlacementRequest: UpdatePlacementRequest): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/${placementId}`;
    return axios.put<Placement>(url, updatePlacementRequest, { headers });
}
export function deletePlacement(placementId: number): Promise<AxiosResponse<Placement>> {
    const url = `${baseUrl}/api/admin/placement/${placementId}`;
    return axios.delete<Placement>(url, { headers });
}
