import { ArtItemSearch } from "../models/ArtItemSearch";
import { PageArtItem } from "../models/PageArtItem";
import { ArtistSearchRequest } from "../models/ArtistSearchRequest";
import { PageArtist } from "../models/PageArtist";
import { SearchRequest } from "../models/SearchRequest";
import { PageGalleryItem } from "../models/PageGalleryItem";
import { baseUrl, headers } from "./config";
import { AxiosResponse } from "axios";
import axios from "axios";
export function searchArtItems(artItemSearch: ArtItemSearch): Promise<AxiosResponse<PageArtItem>> {
    const url = `${baseUrl}/api/visitor/art-item/search`;
    return axios.post<PageArtItem>(url, artItemSearch, { headers });
}
export function searchArtist(artistSearchRequest: ArtistSearchRequest): Promise<AxiosResponse<PageArtist>> {
    const url = `${baseUrl}/api/visitor/artist/search`;
    return axios.post<PageArtist>(url, artistSearchRequest, { headers });
}
export function searchItems(searchRequest: SearchRequest): Promise<AxiosResponse<PageGalleryItem>> {
    const url = `${baseUrl}/api/visitor/search`;
    return axios.post<PageGalleryItem>(url, searchRequest, { headers });
}
