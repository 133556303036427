import { Floor } from "../models/Floor";
import { Room } from "../models/Room";
import { addFloor, deleteFloor, updateFloor, addRoom, deleteRoom, updateRoom } from "./admin.service";
import { setAttr } from "../utils";
import { CreateFloorRequest } from "../models/CreateFloorRequest";
import { CreateRoomRequest } from "../models/CreateRoomRequest";

export type FloorWithRooms = Floor & {
    isDeleted?: boolean
    rooms?: RoomWithDeleted[]
}

export type RoomWithDeleted = Room & {
    isDeleted?: boolean
}

async function saveFloor(floor: FloorWithRooms) {
    if (!floor.id) {
        if (!floor.rooms!.length) return;
        if (floor.isDeleted) return; // Added and removed in the same edit, ignore
        var r = await addFloor(setAttr<CreateFloorRequest>({
            locationId: 1,
            name: 1,
            picture: 1
        }, floor));
        // eslint-disable-next-line require-atomic-updates
        floor.id = r.data.id;

    } else if (floor.isDeleted) {
        await deleteFloor(floor.id);
    } else {
        await updateFloor(setAttr<CreateFloorRequest>({
            locationId: 1,
            name: 1,
            picture: 1
        }, floor), floor.id);
    }
}

async function saveRoom(room: RoomWithDeleted) {
    if (!room.id) {
        if (room.isDeleted) return; // Added and removed in the same edit, ignore
        await addRoom(setAttr<CreateRoomRequest>({
            floorId: 1,
            name: 1,
            number: 1
        }, room));

    } else if (room.isDeleted) {
        await deleteRoom(room.id);
    } else {
        await updateRoom(setAttr<CreateRoomRequest>({
            floorId: 1,
            name: 1,
            number: 1
        }, room), room.id);
    }
}

async function saveAllRooms(floor: FloorWithRooms) {
    if (floor.isDeleted || !floor.rooms || !floor.rooms.length) return;
    for (const room of floor.rooms) {
        room.floorId = floor.id;
        await saveRoom(room);
    }
}


export async function saveAllFloors(locationId: number, floors: FloorWithRooms[]) {
    for (const floor of floors) {
        floor.locationId = locationId;
        await saveFloor(floor);
        await saveAllRooms(floor);
    }
}
