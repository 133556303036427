import { RouteComponentProps } from "react-router";
import H from "history";
import { DisplayMode } from "./store/utils";

export const urls = {
    companyEdit: (p: { name: string, id: number }) => `/app/owner/edit/${p.id}`,
    companyList: () => "/app/owner/list",
    companyAdd: () => "/app/owner/add",
    artItemEdit: (p: { name: string, id: number }) => `/app/art-item/edit/${p.id}`,
    artItemAdd: () => "/app/art-item/add",
    artItemsView: () => "/app/art-item/",
    artItemList: () => "/app/art-item/list",
    artItemTiles: () => "/app/art-item/tiles",
    artItemView: (p: { id: number }) => `/app/art-item/view/${p.id}`,

    locationEdit: (p: { name: string, id: number }) => `/app/location/edit/${p.id}`,
    locationAdd: () => "/app/location/add",
    locationList: () => "/app/location/list",
    locationView: (p: { id: number }) => `/app/location/view/${p.id}`,

    placementsList: () => "/app/placements/list",
    placementsAdd: () => "/app/placements/add",
    placementsEdit: (p: { id: number }) => `/app/placements/${p.id}/edit`,

    login: () => "/login",

    artistView: (p: { name: string, id: number }) => `/app/artist/view/${p.id}/${p.name}`,
    artistsView: () => "/app/artist/",
    artistList: () => "/app/artist/list",
    artistTiles: () => "/app/artist/tiles",
    artistEdit: (p: { name: string, id: number }) => `/app/artist/edit/${p.id}`,
    artistAdd: () => "/app/artist/add",

    userList: () => "/app/user/list",
    userEdit: (p: { name: string, id: number }) => `/app/user/${p.id}/${p.name}`,
    userAdd: () => "/app/user/add",

    securityLog: () => "/app/security-log",
    importOld: () => "/app/import-old-data",
    autocompleteData:() =>"/app/attributes",
    history: (p: { linkId?: number, defaultEntity?: string }) => p.linkId && p.defaultEntity ? `/app/history/${p.defaultEntity}/${p.linkId}` : "/app/history",

    searchView: () => "/app/search-all/",
    searchAllView: (p: { word: string }) => `/app/search-all/${p.word}/`,
    searchAllList: (p: { word: string }) => `/app/search-all/${p.word}/list`,
    searchAllTiles: (p: { word: string }) => `/app/search-all/${p.word}/tiles`,
};
export const startUrl = urls.artItemsView;

export function routeTo<T>(p: RouteComponentProps<any> | H.History, fn: () => string): void
export function routeTo<T>(p: RouteComponentProps<any> | H.History, fn: (p: T) => string, params: T): void
export function routeTo<T>(p: RouteComponentProps<any> | H.History, fn: (p: T) => string, params: T, displayModes: Map<string, DisplayMode>): void
export function routeTo<T>(p: RouteComponentProps<any> | H.History, fn: (p?: T) => string, params?: T, displayModes?: Map<string, DisplayMode>) {
    const h = "history" in p ? p.history : p;
    h.push(fn(params) + (displayModes ? displayModes.get(fn(params)) : ""));
}

export function route(fn: () => string): string
export function route<T>(fn: (p: T) => string, params: Array<keyof T>): string
export function route<T>(fn: (p: T) => string, params: Array<keyof T>, displayModes: Map<string, DisplayMode>): string
export function route<T>(fn: (p: T) => string, params: Array<keyof T> = [], displayModes?: Map<string, DisplayMode>) {
    var parameter = Object.fromEntries(params.map(p => [p, ":" + p]));
    return fn(parameter as any) + (displayModes ? displayModes.get(fn(parameter as any)) : "");
}
