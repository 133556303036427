import React, { useState, useEffect, useContext } from "react";
import MaterialTable, { Options, Query } from "material-table";
import * as history from "../services/history.service";
import { withRouter } from "react-router-dom";
import { Select, MenuItem, Table, TableHead, TableCell, TableRow, TableBody } from "@material-ui/core";
import { useLoadData, loadHistoryItems, useTitle } from "../store/utils";
import { HistoryRecord } from "../models/HistoryRecord";
import { translations } from "../translates";
import ImageApi from "../components/ImageApi";
import { routeTo, urls } from "../navigation";
import { isNullOrUndefined } from "util";
import { PagingContext } from "../contexts";
export default withRouter(function (p) {
    useTitle("History");
    const [entities, setEntities] = useState<string[]>();
    const [selectedEntity, setSelectedEntity] = useState<string>();
    const [historyPage, setHistoryPage] = useState<HistoryRecord[]>([]);
    const [tableQuery, setTableQuery] = useState<Query<HistoryRecord>>();

    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const [pagination, setPagination] = useState<Options>({
        sorting: false,
        actionsColumnIndex: -1,
        searchFieldStyle: {
            display: "none",
        },
        pageSize: pagingContext.historyPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    const trans = translations.history;
    const tableRef = React.createRef<any>();
    useLoadData(async () => {
        const result = await history.getEntities();
        setEntities(result.data);
        setSelectedEntity(p.match.params.defaultEntity ?? "ArtItem"); // set default to be ArtItem 
    });
    if (!selectedEntity) {
        return <div></div>;
    }
    if (!entities) {
        return <div></div>;
    }
    function compareOld(data: any, oldData?: any) {
        if (oldData) {
            return !(oldData === data === null);
        }
        if (data === null) {

        }
        return data !== null;
    }
    function dataToString(data: any, key: string, oldData?: any) {
        if (data === null || oldData === null) {
            return <div>
                <div style={{ backgroundColor: "rgba(0, 255, 0, 0.3)" }}>
                    {data ? data.toString() : <div>&nbsp;</div>}
                </div>
                <div style={{ backgroundColor: "rgba(255, 0, 0, 0.3)" }}>
                    {oldData ? oldData.toString() : <div>&nbsp;</div>}
                </div>
            </div>
        }
        if (typeof data === "object") {
            if (Array.isArray(data)) {
                if (data.length === 0) {
                    return "";
                }
                data = data[0];
            }
            const key = Object.keys(data);
            return data[key[0]].toString();
        } else {
            if (typeof data === "string" && key === "picture") {
                if (data.length > 0) {
                    return <ImageApi src={data} imageType="Thumbnail"></ImageApi>;
                }
                return "Picture unavailable";
            }
        }
        if (!isNullOrUndefined(oldData)) {
            if (data.toString() !== oldData.toString() && key !== "id" && key !== "action") {
                // console.log(data.toString(), oldData.toString());
                return <div>
                    <div style={{ backgroundColor: "rgba(0, 255, 0, 0.3)" }}>
                        {data.toString()}
                    </div>
                    <div style={{ backgroundColor: "rgba(255, 0, 0, 0.3)" }}>
                        {oldData.toString()}
                    </div>
                </div>
            }
        }
        return data.toString();
    }
    function renderTitle() {
        return <div style={{ display: "flex", flexDirection: "row" }}>
            <h6 className="MuiTypography-root MuiTypography-h6">{trans.title}</h6>
            <Select
                style={{ marginLeft: 15 }}
                value={selectedEntity}
                onChange={async (e: React.ChangeEvent<any>) => {
                    setSelectedEntity(e.target.value);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
            >
                {entities!.map((entities, index) => <MenuItem value={entities} key={index}>{entities}</MenuItem>)}
            </Select>
        </div>;
    }
    function navTo(name: string, id?: number) {
        if (id) {
            switch (selectedEntity) {
                case "Artist":
                    routeTo(p, urls.artistView, { name, id });
                    break;
                case "PlacementItem":
                case "ArtItem":
                    routeTo(p, urls.artItemView, { id });
                    break;
                case "Room":
                case "Floor":
                case "Location":
                    routeTo(p, urls.locationEdit, { name, id });
                    break;
                case "Company":
                    routeTo(p, urls.companyEdit, { name, id });
                    break;
                case "UserLocation":
                case "User":
                    routeTo(p, urls.userEdit, { name, id });
                    break;
                case "Placement":
                    routeTo(p, urls.placementsEdit, { id });
                    break;
            }

        }
    }
    const DetailPanel = (p: { rowData: any, linkId?: any }) => {
        const { rowData, linkId } = p;
        const [anteriorHistory, setAnteriorHistory] = useState<any[]>();
        useEffect(() => {
            (async function () {
                if (linkId) {
                    const index = historyPage.map(e => e.id).indexOf(rowData.id);
                    if (index < historyPage.length - 1)
                        setAnteriorHistory(JSON.parse(historyPage[index + 1].data!));
                    else {
                        const result = await loadHistoryItems(history.searchRecords, selectedEntity, linkId)({ ...tableQuery!, page: tableQuery!.page + 1 });
                        if (result.data.length > 0) {
                            setAnteriorHistory(JSON.parse(result.data[0].data!));
                        } else {
                            setAnteriorHistory([]);
                        }
                    }
                } else
                    setAnteriorHistory([]);
            }())
            // eslint-disable-next-line
        }, []);
        const data = JSON.parse(rowData.data!);
        const keys = Object.keys(data);
        console.log(data);
        const id = data["linkId"];
        const name = data["name"] ? data["name"] : data["fullName"];
        if (!anteriorHistory) {
            return <div>
            </div>;
        }
        return (
            <div className="detail-panel-history-table" style={{ overflowX: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {keys.filter(key => key.slice(-2) !== "Id")
                                .filter(key => compareOld(data[key], anteriorHistory[key as any]))
                                .map((key) => <TableCell key={key}>{key.charAt(0).toUpperCase() + key.slice(1) ?? ""}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow onClick={() => navTo(name, id)}>
                            {keys.filter(key => key.slice(-2) !== "Id")
                                .filter(key => compareOld(data[key], anteriorHistory[key as any]))
                                .map((key) => <TableCell key={key}>{dataToString(data[key], key, anteriorHistory ? anteriorHistory[key as any] : null)}</TableCell>)}
                        </TableRow>
                    </TableBody>
                </Table>
            </div>
        );
    }
    return <div className="ArtGallery-List-History table-list-pagination-bottom">
        <MaterialTable<HistoryRecord>
            title={renderTitle()}
            tableRef={tableRef}
            options={pagination}
            onRowClick={(event, rowData, togglePanel) => {
                if (togglePanel) {
                    togglePanel();
                }
            }}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, historyPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e })
            }}
            columns={[
                { title: trans.id, field: "id" },
                { title: trans.createdBy, field: "createdByUser" },
                { title: trans.createdDate, render: r => new Date(r.createdDate!).toDateString(), field: "createdDate" },
                { title: trans.entityId, field: "linkId" },
                { title: trans.action, field: "action" }
            ]}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, historyPage: { ...pagingContext.historyPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            data={async e => {
                const historyData = await loadHistoryItems(history.searchRecords, selectedEntity, +p.match.params.linkId)({ ...e, page: pagingContext.historyPage.page });
                setHistoryPage(historyData.data);
                setTableQuery(e);
                return historyData;
            }}
            detailPanel={rowData => {
                return <DetailPanel rowData={rowData} linkId={p.match.params.linkId} />
            }
            }
        />
    </div>;
});