import * as actions from "../actions/display-mode";
import { ActionsOf, DisplayMode } from "../utils";
import { init } from "./utils";
import { urls } from "../../navigation";

function initDisplayModes() :Map<string, DisplayMode> {
    const modes = new Map<string, DisplayMode>();
    modes.set(urls.artItemsView(), DisplayMode.Tiles);
    modes.set(urls.artistsView(), DisplayMode.List);
    // modes.set(urls.searchView(),DisplayMode.Tiles);
    return modes;
}

export function displayMode(
    state = init<Map<string, DisplayMode>>(initDisplayModes()),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_DISPLAY_MODE":
        return action.displayMode;
    default:
        return state;
    }
}
