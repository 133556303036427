import React, { useState, useContext, useEffect } from "react";
import "./art-item-list.css";
import { withRouter } from "react-router";
import ImageApi from "../components/ImageApi";
import { useLoadData, withSuppressLoading, useTitle } from "../store/utils";
import { searchItems } from "../services/visitor.service";
import { Grid, Card, makeStyles, CardHeader, CardMedia, CardContent } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import * as media from "../services/media.service";
import { routeTo, urls } from "../navigation";
import { useSelector } from "../store";
import { GalleryItem } from "../models/GalleryItem";
import { PaginationContext } from "../contexts";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles(theme => ({
    root: {
        display: "none"
    },
    footer: {
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 10000,
        paddingRight: "1rem",
        "@media screen and (max-width: 600px)": {
            bottom: 48
        }
    },
    insideFooter: {
        backgrounColor: "#e4e4e4",
        borderTop: "1px solid #bebebe",
        "& div": {
            padding: "0 1rem",
            "@media screen and (max-width: 600px)": {
                padding: 0
            }
        }
    },
    card: {
        "& :hover": {
            cursor: "pointer"
        },
        "& div.MuiCardImage": {
            transition: "transform 0.5s ease",
            transform: "scale(1)",
            "& :hover": {
                transform: "scale(1.035)",
                transition: "transform 0.5s ease",
            }
        }
    },
    cardHeader: {
        marginBottom: 8,
        filter: "drop-shadow(2px 2px 3px black)",
        "& div span": {
            lineHeight: "1.6rem",
        },
    },
    secondaryInfo: {
        filter: "drop-shadow(2px 2px 5px black)",
        paddingBottom: "16px !important"
    },
    editbuttonsContainer: {
        "& button": {
            backgroundColor: "rgba(12, 105, 177, 0.15)"
        },
        "& button:hover": {
            backgroundColor: "rgba(12, 105, 177, 0.85)"
        },
        "& button > span": {
            padding: 12,
            "& span": {
                filter: "drop-shadow(0 0 6px #0C69B1)"
            }
        }
    },
    media: {
        margin: "10px 10%",
        width: "80%",
        maxHeight: 250
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    avatar: {
        backgroundColor: red[500],
    },
    progress: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100px!important",
        height: "100px!important"
    },
}));

export function CardMediaApi(p: React.ComponentProps<typeof CardMedia> & { imageType: "Normal" | "Thumbnail" }) {
    const [image, setImage] = useState<string>("");
    useLoadData(async () => {
        const result = await withSuppressLoading(() => media.getImage(p.image!, p.imageType));
        const myBlob = new Blob([result.data]);
        setImage(URL.createObjectURL(myBlob));
    }, [p.src]);

    return <CardMedia {...p} image={image}></CardMedia>;
}

export const SearchAllTiles = withRouter(function (p) {
    useTitle("Art items");
    const classes = useStyles();
    const [pageSize,] = useContext(PaginationContext);
    const [searchedItems, setSearchItems] = useState<{ data?: GalleryItem[], page: number, total: number }>({
        page: 0,
        total: 0
    });

    const loader = <div className="loader">Loading ...</div>;
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const searchWord = useSelector(a => a.searchWord);
    function loadScroll() {
        setLoading(!loading);
    }
    useEffect(() => {
        setSearchItems({ page: 0, total: 0 });
        setPage(0);
        loadScroll();
        // eslint-disable-next-line
    }, [searchWord]);
    async function loadSearchedItems(q: { search?: string, page: number, pageSize: number }) {
        const result = await searchItems({
            key: q.search || "",
            pageNumber: q.page,
            pageSize: q.pageSize,
        });
        setPage(page + 1);
        if (result.data.content.length < pageSize.tilePageSize) {
            setHasMore(false);
        }
        setSearchItems({
            ...searchedItems,
            data: searchedItems.data ? searchedItems.data.concat(result.data.content) : result.data.content,
            page: q.page,
            total: result.data.totalElements,
        });
    }
    useLoadData(() => {
        return loadSearchedItems({
            search: searchWord,
            page: page,
            pageSize: pageSize.tilePageSize
        });
    }, [loading]);
    const routeToView = (e: GalleryItem) => {
        // dispatch(setSearchWord(""));
        switch (e.itemType) {
        case "ARTIST":
            routeTo(p, urls.artistView, { id: e.id!, name: e.name! });
            break;
        case "ART_ITEM":
            routeTo(p, urls.artItemView, { id: e.id!, name: e.name! });
            break;
        }
    };
    if (searchedItems.data != null) {
        return <InfiniteScroll
            dataLength={searchedItems.data.length}
            next={loadScroll}
            hasMore={hasMore}
            style={{ overflow: "none" }}
            loader={loader}
            endMessage={<p style={{color: "gray", padding: "20px 0", margin: 0}}>No more items</p>}>
            <div className="MuiTileGrid">
                <Grid container spacing={2}>
                    {searchedItems.data.map(a =>
                        <Grid xs={12} sm={6} md={4} lg={3} item key={a.id! + a.name! + a.itemType}>
                            <Card className={classes.card}>
                                <CardHeader className={classes.cardHeader} title={a.name}></CardHeader>
                                <div className="MuiCardImage">
                                    {!a.picture ?
                                        <img src="/error_loading.png" alt="" /> :
                                        <ImageApi src={a.picture} imageType="Tile" onClick={() => routeToView(a)} /*onClick={handleOpen(a.picture)}*/ />
                                    }
                                </div>
                                <div className="MuiCardBackground" onClick={() => routeToView(a)} /*onClick={handleOpen(a.picture)}*/ />
                                <CardContent className={classes.secondaryInfo}>{a.author && a.author}</CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </InfiniteScroll>;
    } else {
        return <div></div>;
    }
});
