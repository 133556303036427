import * as actions from "../actions/searchText";
import { ActionsOf } from "../utils";
import { init } from "./utils";

export function searchWord(
    state = init<string>(""),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_SEARCHWORD":
        return action.word;
    default:
        return state;
    }
}
