import React, { useState, useRef, useContext } from "react";
import MaterialTable, { Options, Action } from "material-table";
import { withRouter } from "react-router";
import { Artist } from "../models/Artist";
import { deleteArtist } from "../services/admin.service";
import { loadItems, useTitle } from "../store/utils";
import * as visitor from "../services/visitor.service";
import { routeTo, urls } from "../navigation";
import { withoutSorting } from "../components/edit-delete-column";
import { useSelector } from "../store";
import { translations } from "../translates";
import { IsAdmin } from "../components/Roles";
import { IconButton, Icon } from "@material-ui/core";
import { unknownArtistSlug } from "./constants";
import ImageApi from "../components/ImageApi";
import { PagingContext } from "../contexts";

export function Description({ text }: { text?: string }) {
    return <div className="block-with-text">
        {text}
    </div>;
}
export default withRouter(function (p) {
    useTitle("Artists");
    const tableRef = useRef<any>(null);
    const roles = useSelector(app => app.loginUser.roles);
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const isAdmin = roles![0] === "admin" || roles![0] === "global";
    const actions: (Action<Artist> | ((rowData: Artist) => Action<Artist>))[] | undefined = isAdmin ? [{
        icon: "delete",
        tooltip: "Delete",
        onClick: async (event, rowData) => {
            const confirmation = window.confirm("Are you sure you want to delete the selected items?");
            if (confirmation && Array.isArray(rowData)) {
                for (const i of rowData) {
                    await deleteArtist(i.id);
                }
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }
        },
    }, {
        icon: "add_circle",
        tooltip: "Add new Artist",
        isFreeAction: true,
        onClick: () => routeTo(p, urls.artistAdd),
    }] : [];
    const [pagination, setOptions] = useState<Options>({
        actionsColumnIndex: -1,
        selection: isAdmin,
        selectionProps: (rowData: any) => ({
            disabled: rowData.slug === unknownArtistSlug,
            color: "primary",
        }),
        searchFieldStyle: {
            display: "none",
        },
        // initialPage:paginationRedux.artist.page,
        pageSize: pagingContext.artistPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    const trans = translations.artistList;
    return <div className="ArtGallery-List-ArtistList table-list-pagination-bottom">
        <MaterialTable<Artist>
            title={trans.title}
            options={pagination}
            tableRef={tableRef}
            columns={[
                ...withoutSorting<Artist>({ title: translations.artItemList.image, render: (r) => r.picture ? <ImageApi src={r.picture} imageType="Thumbnail"></ImageApi> : <></>, sorting: false },
                    { title: trans.firstName, field: "firstName" },
                    { title: trans.lastName, field: "lastName" },
                    { title: trans.kind, field: "kind" },
                    { title: trans.country, render: e => e.country && e.country.name, field: "country.name" },
                    { title: trans.description, field: "description", render: e => <Description text={e.description}></Description> }),
                {
                    title: "",
                    cellStyle: { textAlign: "right" },
                    render: r => <div>
                        {r.slug !== unknownArtistSlug ?
                            <IsAdmin>
                                <IconButton title="Edit artist" onClick={e => routeTo(p, urls.artistEdit, { name: r.firstName!.replace(" ", "-"), id: r.id! })}><Icon>edit</Icon></IconButton>
                                {isAdmin ? <>
                                    <IconButton title="View artist history" onClick={e => routeTo(p, urls.history, { defaultEntity: "Artist", linkId: r.id })}><Icon>history</Icon></IconButton>
                                </> : <div />}
                                <IconButton    
                                    title="Delete artist" 
                                    onClick={async e => {
                                    const confirmation = window.confirm(translations.confirm(r.firstName + " " + r.lastName));
                                    if (confirmation) {
                                        await deleteArtist(r.id!);
                                    }
                                    if (tableRef.current) {
                                        tableRef.current.onQueryChange();
                                    }
                                }}><Icon>delete</Icon></IconButton>
                            </IsAdmin> : <div />}
                    </div>
                }
            ]}
            data={e => loadItems(visitor.searchArtist)({ ...e, page: pagingContext.artistPage.page })}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, artistPage: { ...pagingContext.artistPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, artistPage: { page: 0, pageSize: e } });
                setOptions({ ...pagination, pageSize: e });
            }}
            actions={actions}
        />
    </div>;
});