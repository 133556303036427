
import React from "react";
import { Grid, IconButton, Icon } from "@material-ui/core";
import { Location } from "../models/Location";
import { Room } from "../models/Room";
import { Floor } from "../models/Floor";
import { searchLocations, searchFloors, searchRooms, searchAutoCompleteData } from "../services/admin.service";
import { useDispatch, useSelector } from "../store";
import { setArtItemsFilter, ArtItemsFilter } from "../store/actions/artItemsFilter";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Artist } from "../models/Artist";
import { searchArtist } from "../services/visitor.service";
import { Formik } from "formik";
import { MultipleAutocompleteSelect } from "../components/multiple-autocomplete-selector";
import { AutocompleteData } from "../models/AutocompleteData";
import AutocompleteSelect from "../components/autocomplete-selector";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectBox: {
            zIndex: "auto",
            width: 264,
            "& .MuiTextField-root": {
                width: "100%"
            }
        }
    })
);
export interface ArtItemFilterRequest {
    artistIdList: number[];
    floorIdList: number[];
    locationIdList: number[];
    roomIdList: number[];
    techniqueList: string[];
    pictureQuality?: "LOW" | "MEDIUM" | "HIGH";
}
export const objectKeyLenghtGT0 = (obj: any) => { // greater than 0
    if (obj === null) {
        return false;
    }
    return Object.entries(obj).length > 0;
};
type MaybeUndefined<T> = T | undefined;
export const ArtItemFilter = (p: {
    onChange: (selected: ArtItemFilterRequest) => void;
    onSearch: () => void;
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const reduxData = useSelector(e => e.artItemsFilter);
    const [location,] = React.useState<MaybeUndefined<Location[]>>(reduxData.location);
    const [floor,] = React.useState<MaybeUndefined<Floor[]>>(reduxData.floor);
    const [room,] = React.useState<MaybeUndefined<Room[]>>(reduxData.room);
    const [artist,] = React.useState<MaybeUndefined<Artist[]>>(reduxData.artist);
    const [technique,] = React.useState<AutocompleteData[]>(reduxData.technique);
    const [pictureQuality,] = React.useState<MaybeUndefined<"LOW" | "MEDIUM" | "HIGH">>(reduxData.pictureQuality);
    const search = (e: ArtItemsFilter) => {
        p.onChange({
            locationIdList: e.location!.length ? e.location!.map(e => e.id) : [],
            floorIdList: e.floor!.length ? e.floor!.map(e => e.id) : [],
            roomIdList: e.room!.length ? e.room!.map(e => e.id) : [],
            artistIdList: e.artist!.length ? e.artist!.map(e => e.id) : [],
            techniqueList: e.technique!.length ? e.technique!.map(e => e.value) : [],
            pictureQuality: e.pictureQuality,
        });
        dispatch(setArtItemsFilter({ location: e.location, floor: e.floor, room: e.room, artist: e.artist, technique: e.technique, pictureQuality: e.pictureQuality }));
        p.onSearch();
    };
    return <Formik
        initialValues={{ location, floor, room, artist, technique, pictureQuality }} onSubmit={search}
        render={({ values, setValues, resetForm, setFieldValue }) => {

            const getLocationSuggestions = async (key: string) => {
                const result = await searchLocations({ onlyMyLocations: true, unpaged: true, key, sortDirection: "ASC", sortColumn: "name" });
                return result.data.content;
            };
            const getFloorSuggestions = async () => {
                if (values.location?.length !== 0) {
                    const result = await searchFloors({ locationIds: values.location?.map(o => o.id) ?? [] });
                    return result.data;
                } else {
                    return [];
                }
            };
            const getRoomSuggestions = async () => {
                if (values.floor?.length !== 0) {
                    const result = await searchRooms({ floorIds: values.floor?.map(o => o.id) ?? [] });
                    return result.data;
                } else {
                    return [];
                }

            };
            const getArtistSuggestions = async (key: string) => {
                const result = await searchArtist({ key, sortDirection: "ASC", sortColumn: "firstName", unpaged: true });
                return result.data.content;
            };
            const getTechniqueSuggestions = async (text: string) => {
                const result = await searchAutoCompleteData({ text, dataType: "TECHNIQUE" });
                return result.data;
            };
            const resetFields = () => {
                setValues({ location: [], floor: [], room: [], artist: [], technique: [], pictureQuality: null! });
            };
            function floorDisplay(floor: Floor) {
                let location = values.location?.find(l => l.id === floor.locationId);
                let locationText = location && values.location?.length! > 1 ? location.name + " - " : "";
                return locationText + floor.name;
            }
            function roomDisplay(room: Room) {
                let floor = values.floor?.find(f => f.id === room.floorId);
                let floorText = floor && values.floor?.length! > 1 ? floor.name + " - " : "";

                let location = values.location?.find(l => l.id === floor?.locationId);
                let locationText = location && values.location?.length! > 1 ? location.name + " - " : "";
                return locationText + floorText + (room.name ? room.number + " - " + room.name : room.number);
            }

            return <Grid container spacing={2} style={{ justifyContent: "space-between", marginBottom: "0.75rem", zIndex: 0 }}>
                <Grid item className={classes.selectBox}>
                    <MultipleAutocompleteSelect
                        selectedItems={values.location ? values.location : []}
                        label="Choose Location"
                        getSuggestions={getLocationSuggestions}
                        onSelect={e => {
                            setValues({ ...values, floor: [], room: [], location: e! });
                        }}
                        display={a => a ? a.name : ""}
                    />
                </Grid>
                <Grid item className={classes.selectBox}>
                    <MultipleAutocompleteSelect
                        label="Choose Floor"
                        getSuggestions={getFloorSuggestions}
                        getSuggestionsDeps={values.location}
                        selectedItems={values.floor ?? []}
                        onSelect={(e) => setValues({ ...values, floor: e })}
                        display={floorDisplay}
                    />
                </Grid>
                <Grid item className={classes.selectBox}>
                    <MultipleAutocompleteSelect
                        label="Choose Room"
                        selectedItems={values.room ?? []}
                        getSuggestions={getRoomSuggestions}
                        getSuggestionsDeps={values.floor}
                        display={roomDisplay}
                        onSelect={e => setValues({ ...values, room: e! })}
                    />
                </Grid>
                <Grid item className={classes.selectBox}>
                    <MultipleAutocompleteSelect
                        selectedItems={values.artist ? values.artist : []}
                        label="Choose Artists"
                        getSuggestions={getArtistSuggestions}
                        onSelect={(e) => setValues({ ...values, artist: e })}
                        display={e => e.firstName + " " + e.lastName}
                    />
                </Grid>
                <Grid item className={classes.selectBox}>
                    <MultipleAutocompleteSelect

                        selectedItems={values.technique ? values.technique : []}
                        label="Choose techniques"
                        getSuggestions={getTechniqueSuggestions}
                        onSelect={(e) => setValues({ ...values, technique: e })}
                        display={e => e.value}
                    />
                </Grid>
                <Grid item className={classes.selectBox}>
                    <AutocompleteSelect
                        selectedItem={values.pictureQuality}
                        required={false}
                        label="Choose picture Quality"
                        getSuggestions={async () => ["LOW", "MEDIUM", "HIGH"]}
                        onSelect={(e) => {
                            setValues({ ...values, pictureQuality: e as ArtItemsFilter["pictureQuality"] })
                        }}
                        display={e => e}
                    />
                </Grid>
                <Grid item style={{ textAlign: 'end' }}>
                    <IconButton title="Apply filters" onClick={(e) => search(values)}>
                        <Icon style={{ color: "rgba(0, 0, 0, 0.54)" }}>search</Icon>
                    </IconButton>
                    <IconButton title="Clear filters" onClick={() => {
                        resetFields();
                    }}>
                        <Icon style={{ color: "rgba(0, 0, 0, 0.54)" }}>delete</Icon>
                    </IconButton>
                </Grid>
            </Grid>;
        }}
    />;


};