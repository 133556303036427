import React, { useRef, useState, useContext } from 'react'
import MaterialTable, {MTableToolbar , Options } from 'material-table';
import { withoutSorting } from '../components/edit-delete-column';
import { loadSecurityItems } from '../store/utils';
import { SecurityLog } from "../models/SecurityLog";
import { searchSecurityLog } from '../services/admin.service';
import moment from 'moment';
import { Select, MenuItem, TextField, Button } from '@material-ui/core';
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider } from "@material-ui/pickers";
// import DateFnsUtils from '@date-io/date-fns';
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns"
import { PagingContext } from '../contexts';
import { DateRange } from '@material-ui/pickers/src/DateRangePicker/RangeTypes';
export const SecurityLogPage = () => {

    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: false,
        searchFieldStyle: {
            display: "none",
        },
        pageSize: pagingContext.securityPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100]
    });
    const [selectedDate, handleDateChange] = React.useState<DateRange>([null, null]);
    const [action, setAction] = useState<SecurityLog["action"]>("LOGIN");
    function renderTitle() {
        return <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
            <h6 className="MuiTypography-root MuiTypography-h6">Security Log</h6>
            <Select
                style={{ marginLeft: 20, marginRight: 40 }}
                value={action}
                onChange={async (e: React.ChangeEvent<any>) => {
                    setAction(e.target.value);
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }}
            >
                <MenuItem value="None">Show all logs</MenuItem>
                <MenuItem value={"LOGIN"}>Login logs</MenuItem>
                <MenuItem value={"LOGOUT"} >Logout logs</MenuItem>
            </Select>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} > */}
            {/* </MuiPickersUtilsProvider> */}
        </div>;
    }
    const tableRef = useRef<any>(null);
    return <div className="ArtGallery-List-SecurityLogList table-list-pagination-bottom">
        <MaterialTable<SecurityLog>
            title={renderTitle()}
            components={{
                Toolbar: props => (
                  <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                    <div className="securityLogContainer" style={{flex: 1}}><MTableToolbar {...props} /></div>
                    <div style={{display: "flex", paddingRight: 24, backgroundColor:"#eaeaea"}}>
                        <LocalizationProvider dateAdapter={DateFnsAdapter}>
                            <DateRangePicker
                                autoOk
                                label="Date"
                                clearable
                                value={selectedDate}
                                disableFuture
                                renderInput={(startProps, endProps) => (
                                    <>{ console.log(startProps, endProps)}
                                        {/* <DateRangeDelimiter translate="from">from </DateRangeDelimiter> */}
                                        <TextField {...startProps} label={"From Date"} variant="standard" helperText=""/>
                                        {/* <DateRangeDelimiter translate="to">to </DateRangeDelimiter> */}
                                        <DateRangeDelimiter translate="to"></DateRangeDelimiter>
                                        <TextField {...endProps}  label={"To Date"} variant="standard" helperText="" />
                                    </>
                                )}
                                onChange={(selectedDate) => {
                                    console.log(selectedDate);
                                    handleDateChange(selectedDate);
                                    if (tableRef.current) {
                                        tableRef.current.onQueryChange();
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <Button title="Clear date filter" disabled={selectedDate.every(item => item === null)} color="primary" onClick={() => {
                            handleDateChange([null, null]);
                            if (tableRef.current) {
                                tableRef.current.onQueryChange();
                            }
                        }}>Reset</Button> 
                  </div>
                  </div>
                ),
              }}
            tableRef={tableRef}
            options={pagination}
            columns={[
                ...withoutSorting<SecurityLog>(
                    { title: "User", render: (rowData) => <div>{rowData.user.username}</div> },
                    { title: "IP", field: "ip" },
                    { title: "Action", field: "action" },
                    { title: "Date", field: "date", defaultSort:"desc", render: e => <div>{moment(e.date).format('MMMM Do YYYY, h:mm:ss a')}</div> },
                    { title: "Info", field: "info" })
            ]}
            data={e => loadSecurityItems(searchSecurityLog, action === "LOGIN" || action === "LOGOUT" ? action : undefined, ...selectedDate)({ ...e, page: pagingContext.securityPage.page })}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, securityPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e })
            }}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, historyPage: { ...pagingContext.historyPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
        />
    </div>;
}