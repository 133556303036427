import { HistorySearchRequest } from "../models/HistorySearchRequest";
import { PageHistoryRecord } from "../models/PageHistoryRecord";
import { baseUrl, headers } from "./config";
import { AxiosResponse } from "axios";
import axios from "axios";
export function getEntities(): Promise<AxiosResponse<string[]>> {
    const url = `${baseUrl}/api/history/entities`;
    return axios.get<string[]>(url, { headers });
}
export function searchRecords(historySearchRequest: HistorySearchRequest): Promise<AxiosResponse<PageHistoryRecord>> {
    const url = `${baseUrl}/api/history/search`;
    return axios.post<PageHistoryRecord>(url, historySearchRequest, { headers });
}
