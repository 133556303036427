import React, { FunctionComponent } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { Formik, Form } from "formik";
import { Button, Grid, Card, CardContent, Typography } from "@material-ui/core";

import * as adminService from "../../services/admin.service";
import * as placementService from "../../services/placement.service";
import { CreatePlacementRequest } from "../../models/CreatePlacementRequest";

import { useSelector } from "../../store";

import DetailsForm from "./details-form";
import ArtItemSelector from "./art-item-selector";
import { route, urls } from "../../navigation";
import { useTitle, withSuppressLoading } from "../../store/utils";

export interface AddPlacementForm extends CreatePlacementRequest {
    proposedBy?: string;
    creator?: string;
}

const AddPlacement: FunctionComponent<RouteComponentProps> = ({ history }) => {
    const username = useSelector(s => s.loginUser.username);
    useTitle("Add Placement");

    const initialValues: AddPlacementForm = {
        placementType: "SEND",
        locationId: 1,
        proposedBy: username,
        destination: "",
        description: "",
        message: "",
        items: []
    };
    const allLocations = async (key: string) => {
        const result = await adminService.searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC" });
        if (username !== "admin") {
            const myLocations = (await withSuppressLoading(() => adminService.searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC", onlyMyLocations: true }))).data.content;
            return result.data.content.filter(all => !myLocations.some(my => my.id === all.id))

        }
        return result.data.content;
    };
    const myLocations = async (key: string) => {
        const result = await adminService.searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC", onlyMyLocations: true });
        return result.data.content;
    };
    const onAddPlacement = async (values: AddPlacementForm) => {
        // const borrowKey:Record<keyof {asd:string}, 1> 
        const { proposedBy, ...addValues } = values;
        if (addValues.items.length === 0) {
            window.alert("A placement should have at least an art item");
        } else {
            await placementService.createPlacement(addValues as CreatePlacementRequest);
            history.push(route(urls.placementsList));
        }
    };

    return (
        <Formik<AddPlacementForm>
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={onAddPlacement}
        >
            {formikProps => {
                const onSelectionChange = (ids: number[]) => {
                    formikProps.setFieldValue("items", ids);
                };
                return (
                    <Form>
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} style={{ justifyContent: "space-between" }}>
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="h5" gutterBottom>
                                            Create New Placement
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" type="submit">
                                            Create placement
                                        </Button>
                                    </Grid>
                                </Grid>

                                <Grid container justify="center" spacing={8}>
                                    <Grid item xs={12} md={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">Details</Typography>
                                        <DetailsForm
                                            locationsSuggestions={
                                                formikProps.values.placementType !== "SEND"
                                                    ? myLocations
                                                    : allLocations
                                            }
                                            formikProps={formikProps}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={9} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">Art Items selection</Typography>
                                        <ArtItemSelector
                                            locations={
                                                formikProps.values.placementType !== "SEND"
                                                    ? allLocations
                                                    : myLocations
                                            }
                                            placementType={formikProps.values.placementType}
                                            onChange={onSelectionChange}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withRouter(AddPlacement);
