import React, { useState, useContext } from "react";
import "./art-item-list.css";
import { withRouter } from "react-router";
import ImageApi from "../components/ImageApi";
import { useLoadData, withSuppressLoading, useTitle } from "../store/utils";
import { searchArtist } from "../services/visitor.service";
import { Grid, Card, makeStyles, CardHeader, CardMedia, CardContent, CardActions, IconButton, Icon } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import * as media from "../services/media.service";
import { routeTo, urls } from "../navigation";
import { Artist } from "../models/Artist";
import { IsAdmin } from "../components/Roles";
import { unknownArtistSlug } from "./constants";
import { PaginationContext } from "../contexts";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles(theme => ({
    footer: {
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 10000,
        paddingRight: "1rem",
        "@media screen and (max-width: 600px)": {
            bottom: 48
        }
    },
    insideFooter: {
        borderTop: "1px solid #bebebe",
        backgrounColor: "#e4e4e4",
        "& div": {
            padding: "0 1rem",
            "@media screen and (max-width: 600px)": {
                padding: 0
            }
        }
    },
    card: {
        "& :hover": {
            cursor: "pointer"
        },
        "& div.MuiCardImage": {
            transition: "transform 0.5s ease",
            transform: "scale(1)",
            "& :hover": {
                transform: "scale(1.035)",
                transition: "transform 0.5s ease",
            }
        }
    },
    cardHeader: {
        marginBottom: 8,
        filter: "drop-shadow(2px 2px 3px black)",
        "& div span": {
            lineHeight: "1.6rem",
        },
    },
    secondaryInfo: {
        filter: "drop-shadow(2px 2px 5px black)"
    },
    editbuttonsContainer: {
        "& button": {
            backgroundColor: "rgba(0, 0, 0, 0.15)"
        },
        "& button:hover": {
            backgroundColor: "rgba(12, 105, 177, 0.85)"
        },
        "& button > span": {
            padding: 6,
            "& span": {
                // filter: "drop-shadow(0 0 6px black)"
            }
        }
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    avatar: {
        backgroundColor: red[500],
    },
    progress: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100px!important",
        height: "100px!important"
    },
}));

export function CardMediaApi(p: React.ComponentProps<typeof CardMedia> & { imageType: "Normal" | "Thumbnail" }) {
    const [image, setImage] = useState<string>("");
    useLoadData(async () => {
        const result = await withSuppressLoading(() => media.getImage(p.image!, p.imageType));
        const blob = new Blob([result.data]);
        setImage(URL.createObjectURL(blob));
    }, [p.src]);

    return <CardMedia {...p} image={image}></CardMedia>;
}

export const ArtistTiles = withRouter(function (p) {
    const classes = useStyles();
    useTitle("Artists");
    const [pageSize,] = useContext(PaginationContext);
    const [artists, setArtists] = useState<{ data?: Artist[], page: number, total: number }>({
        page: 0,
        total: 0,
    });

    const loader = <div className="loader">Loading ...</div>;
    const [loading, setLoading] = useState<boolean>(false);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);


    function loadScroll() {
        setLoading(!loading);
    }
    async function loadArtists(q: { search?: string, page: number, pageSize: number }) {
        const result = await searchArtist({
            key: q.search || "",
            pageNumber: q.page,
            pageSize: q.pageSize,
        });
        if (result.data.content.length < pageSize.tilePageSize) {
            setHasMore(false);
        }
        setPage(page + 1);
        if (result.data.content.length < pageSize.tilePageSize) {
            setHasMore(false);
        }
        setArtists({
            ...artists,
            data: artists.data ? artists.data.concat(result.data.content) : result.data.content,
            page: q.page,
            total: result.data.totalElements,
        });
    }
    useLoadData(() => {
        return loadArtists({
            page: page,
            pageSize: pageSize.tilePageSize
        });
    }, [loading]);


    if (artists.data != null) {
        return <InfiniteScroll
            dataLength={artists.data.length}
            next={loadScroll}
            hasMore={hasMore}
            style={{ overflow: "none" }}
            loader={loader}
            endMessage={<p style={{color: "gray", padding: "20px 0", margin: 0}}>No more items</p>}>
            <div className="MuiTileGrid">
                <Grid container spacing={2}>
                    {artists.data.map(a =>
                        <Grid xs={12} sm={6} md={4} lg={3} item key={a.id}>
                            <Card className={classes.card}>
                                <CardHeader className={classes.cardHeader} title={a.firstName + a.lastName}></CardHeader>
                                <div className="MuiCardImage">
                                    {!a.picture ?
                                        <img src="/error_loading.png" alt="" /> :
                                        <ImageApi src={a.picture} imageType="Tile" onClick={() => routeTo(p, urls.artistView, { id: a.id, name: a.firstName + a.lastName })} /*onClick={handleOpen(a.picture)}*/ />
                                    }
                                </div>
                                <div className="MuiCardBackground" onClick={() => routeTo(p, urls.artistView, { id: a.id, name: a.firstName + a.lastName })} /*onClick={handleOpen(a.picture)}*/ />
                                <CardContent className={classes.secondaryInfo}>{a.kind}</CardContent>
                                <CardActions className={classes.editbuttonsContainer}>
                                    {a.slug !== unknownArtistSlug ? <IsAdmin>
                                        <IconButton title="Edit artist" onClick={e => routeTo(p, urls.artistEdit, { name: (a.firstName + a.lastName)!.replace(" ", "-"), id: a.id! })}><Icon>edit</Icon></IconButton>
                                        {/* <IconButton onClick={async e => {
                                        const confirmation = window.confirm(`Are you sure you want to delete ${a.fullName}?`);
                                        if (confirmation) {
                                            await deleteArtist(a.id!);
                                            setArtists({
                                                ...artists,
                                                total: artists.total - 1,
                                            });
                                        }
                                    }}><Icon>delete</Icon>
                                    </IconButton> */}
                                    </IsAdmin> : <div />}
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </InfiniteScroll>;
    } else {
        return <div></div>;
    }
});
