import * as actions from "../actions/location";
import { ActionsOf } from "../utils";
import { init } from "./utils";
import { Location } from "../../models/Location";

export function myLocations(
    state = init<Location[]>([]),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_LOCATIONS":
        return action.locations;
    default:
        return state;
    }
}
