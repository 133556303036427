import React, { FunctionComponent, useState } from "react";
import { IconButton, Paper, Divider, InputBase, Box, Grid } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { format } from "date-fns";

import { Message as TMessage } from "../../models/Message";
import { CSSProperties } from "@material-ui/styles";

// TODO: reusable styles?
const useStyles = makeStyles((theme: Theme) => {
    // TODO: how can we share styles?
    const message: CSSProperties = {
        borderRadius: "0.5rem",
        padding: "0.25rem 0.5rem"
    };
    return createStyles({
        root: {
            padding: "2px 4px",
            display: "flex",
            alignItems: "center"
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1
        },
        iconButton: {
            padding: 10
        },
        divider: {
            height: 28,
            margin: 4
        },
        systemMessage: {
            ...message,
            alignSelf: "center",
            maxWidth: "100%",
            textAlign: "center",
            backgroundColor: "rgba(0, 0, 0, 0.1);",
            border: "1px solid rgba(0, 0, 0, 0.1)"
        },
        guestMessage: {
            ...message,
            display: "flex",
            flexDirection: "column",
            maxWidth: "75%",
            minWidth: "50%",
            textAlign: "left",
            alignSelf: "flex-start",
            backgroundColor: "rgba(233, 77, 25, 0.1)",
            border: "1px solid rgba(233, 77, 25, 0.1)"
            // backgroundColor: "#fcf6e0"
        },
        ownerMessage: {
            ...message,
            alignSelf: "flex-end",
            maxWidth: "300px",
            minWidth: "50%",
            textAlign: "left",
            backgroundColor: "rgba(12, 105, 177, 0.15);",
            border: "1px solid rgba(12, 105, 177, 0.15)"
            // backgroundColor: "#ebf3fd"
        }
    });
});

interface MessagesProps {
    messages: TMessage[];
    username: string;
    onSend: (message: string) => void;
    readonly: boolean;
}

// TODO: types
const Messages: FunctionComponent<MessagesProps> = props => {
    const classes = useStyles();
    const { messages, username, onSend } = props;

    const [message, setMessage] = useState<string>("");
    const onSendMessage = () => {
        onSend(message);
        setMessage("");
    };
    function boldString(original: string, find: string, replace: string) {
        const re = new RegExp(find, "g");
        return original.replace(re, replace);
    }
    const renderMessage = (msg: TMessage, i: number) => {
        msg.message = boldString(msg.message!, "{{location}}", `${msg.locationName}`);
        msg.message = boldString(msg.message!, "{{user}}", `${msg.creatorFullName}`);
        const cls =
            msg.messageType === "SYSTEM"
                ? classes.systemMessage
                : msg.creator === username
                    ? classes.ownerMessage
                    : classes.guestMessage;

        return (
            <div key={i} style={{display: "flex", flexDirection: "column"}}>
                <Box
                    // key={i}
                    className={cls}
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                    style={{ marginBottom: "0.3rem"}}
                >
                    <div style={{opacity: .5, fontSize: "0.85rem"}}>
                        {msg.creationDate
                            ? format(new Date(msg.creationDate!), "dd/MM/yyyy HH:mm")
                            : ""}
                        {" from "}
                        <strong>{msg.messageType === "SYSTEM" ? "System" : msg.creator}</strong>
                    </div>
                    {msg.messageType !== "SYSTEM" && <div>{msg.creatorFullName}</div>}
                    <p style={{overflowWrap: "break-word", margin: 0}}>{msg.message}</p>
                </Box>
            </div>
        );
    };
    return (
        <Grid className={"inputStyle inputStyle--clearPadding"}>
            <Box display="flex" flexDirection="column">
                {messages!.map(renderMessage)}
            </Box>
            {props.readonly ? <></>:
                <Paper className={classes.root} style={{marginTop: "0.5rem"}}>
                    <InputBase
                        className={classes.input}
                        placeholder="Add message..."
                        onChange={(e: any) => setMessage(e.target.value)}
                        value={message}
                    />
                    <Divider className={classes.divider} orientation="vertical" />
                    <IconButton
                        title="Send message"
                        color="primary"
                        className={classes.iconButton}
                        aria-label="directions"
                        onClick={onSendMessage}
                    >
                        <SendIcon />
                    </IconButton>
                </Paper>}
        </Grid>
    );
};

export default Messages;
