import * as actions from "../actions/artItemsIds";
import { ActionsOf } from "../utils";
import { init } from "./utils";

export function artItemsIds(
    state = init<actions.ArtItemsObj>({ q: {} as any, ids: [] }),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_ART_ITEMS_IDS":
        return action.obj;
    default:
        return state;
    }
}
