import React, { useState, useEffect, useRef, useContext } from "react";
import "./art-item-list.css";
import MaterialTable, { Options, Column, Action } from "material-table";
import { ArtItem } from "../models/ArtItem";
import { withoutSorting } from "../components/edit-delete-column";
import { routeTo, urls } from "../navigation";
import { RouteComponentProps, withRouter } from "react-router";
import { deleteArtItem, generateArtItemCatalogHtml } from "../services/admin.service";
import ImageApi from "../components/ImageApi";
import { useTitle, loadArtItems } from "../store/utils";
import { searchArtItems } from "../services/visitor.service";
import { useSelector, useDispatch } from "../store";
import { translations } from "../translates";
import { IconButton, Icon } from "@material-ui/core";
import { ArtItemFilter, ArtItemFilterRequest } from "./art-item-location-filter";
import { setSelectedArtItems } from "../store/actions/selectedArtItems";
import { generateArtItemLabelsAsHtml } from "../services/admin.service";
import { Button, Dialog, makeStyles, DialogContent, FormControl, TextField, Typography, Grid } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { withSuppressLoading } from "../store/utils";
import { ArtItemCloneContext, PagingContext } from "../contexts";

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: "100%"
    },
    frameBox: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        "& iframe": {
            border: "none",
            "& html body main": {
                outline: "none",
                background: "red"
            }
            // transform: "scale(0.5)"
        }
    }
}));

type User = {
    username?: string | undefined;
    token?: string | undefined;
    roles?: ("admin" | "global" | "local" | "visitor")[] | undefined;
}

const columns = (p: RouteComponentProps<any>, ref: React.MutableRefObject<any>, isAdmin: boolean, user: User, locationIds: number[]): Column<ArtItem>[] => [
    ...withoutSorting<ArtItem>({ title: translations.artItemList.image, render: (r) => r.picture ? <ImageApi onClick={() => routeTo(p, urls.artItemView, { id: r.id })} src={r.picture} imageType="Thumbnail" ></ImageApi > : <></>, sorting: false },
        { title: translations.artItemList.inventory, field: "inventoryNumber", type: "numeric" },
        { title: translations.artItemList.artTitle, field: "name" },
        { title: translations.artItemList.artist, render: e => e.artist && e.artist.firstName + " " + e.artist.lastName, field: "artist.firstName" },
        // { title: translations.artItemList.artist, render: e => e.artist && e.artist.lastName, field: "artist.lastName" },
        { title: translations.artItemList.technique, render: e => e.technique && e.technique, field: "technique" },
        { title: translations.artItemList.owner, render: e => e.owner && e.owner.name, field: "owner.name" },
        { title: translations.artItemList.location, render: e => e.location && e.location.name, field: "location.name" },
        { title: translations.artItemList.floor, render: e => e.room && e.room.floor?.name, field: "room.floor.name" },
        { title: translations.artItemList.room, render: e => e.room && e.room.number, field: "room.number" },
        { title: translations.artItemList.status, field: "status" }),
    {
        title: "",
        cellStyle: { textAlign: "right" },
        render: r => <>
            {isAdmin || locationIds.some(e => e === r.location!.id) || (r.owner && r.owner!.name === user.username) ? <div>
                <IconButton title="Edit item" size="small" onClick={e => routeTo(p, urls.artItemEdit, { name: r.name.replace(" ", "-"), id: r.id })}><Icon>edit</Icon></IconButton>
                {isAdmin ? <>
                    <IconButton title="View item history" size="small" onClick={e => routeTo(p, urls.history, { defaultEntity: "ArtItem", linkId: r.id })}><Icon>history</Icon></IconButton>
                </> : <div />}
                <IconButton title="Delete item" size="small" onClick={async e => {
                    const confirmation = window.confirm(translations.confirm(r.name));
                    if (confirmation) {
                        await deleteArtItem(r.id);
                    }
                    if (ref.current) {
                        ref.current.onQueryChange();
                    }
                }}><Icon>delete</Icon></IconButton></div> :
                <div />}
        </>
    },
];

export default withRouter(function (p) {
    const classes = useStyles();
    const trans = translations.artItemList;
    const artItems = useSelector(e => e.selectedArtItems);
    const [index, setIndex] = useState<number>(1);
    const [open, setOpen] = useState(false);
    const [, setClone] = useContext(ArtItemCloneContext);
    const download = async () => {
        const ids = artItems.map(e => e.id);
        const result = await withSuppressLoading(() => generateArtItemLabelsAsHtml({ artItems: ids, skipIndex: index - 1 }));
        const file = new Blob([result.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        setHtml(fileURL);
    };
    const [isCatalog, setIsCatalog] = useState(false);
    const download_catalog = async () => {
        setHtml("");
        setIsCatalog(true);
        const ids = artItems.map(e => e.id);
        const result = await withSuppressLoading(() => generateArtItemCatalogHtml({ artItems: ids, skipIndex: index - 1 }));
        const file = new Blob([result.data], { type: "text/html" });
        const fileURL = URL.createObjectURL(file);
        setHtml(fileURL);
    }
    const [html, setHtml] = useState<string>("");
    const print = () => {
        const iframe = window && window.frames["label_html" as any];
        iframe.print();
    };
    const handleClose = () => {
        setIsCatalog(false);
        setOpen(false);
        setHtml("");
        setIndex(1);
    };
    useEffect(() => {
        download();
        // eslint-disable-next-line
    }, [index])
    useTitle("Art items");
    const tableRef = useRef<any>(null);
    const dispatch = useDispatch();
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";
    const actions: (Action<ArtItem> | ((rowData: ArtItem) => Action<ArtItem>))[] | undefined = isAdmin ? [
        {
            icon: "photo_album",
            tooltip: "Photo Album",
            onClick: async (e, rowData) => {
                if (!open) setOpen(true);
                await download_catalog();
            }
        },
        {
            icon: "print",
            tooltip: "Print selected items",
            onClick: async () => {
                if (!open) setOpen(true);
                await download();
            }
        },
        {
            icon: "delete",
            tooltip: "Delete selected items",
            onClick: async (event, rowData) => {
                const confirmation = window.confirm("Are you sure you want to delete the selected items?");
                if (confirmation && Array.isArray(rowData)) {
                    for (const i of rowData) {
                        await deleteArtItem(i.id);
                    }
                    if (tableRef.current) {
                        tableRef.current.onQueryChange();
                    }
                }
            }
        }, {
            icon: "add_circle",
            tooltip: "Add New Art Item",
            isFreeAction: true,
            onClick: () => {
                setClone(null!);
                routeTo(p, urls.artItemAdd)
            }
        }] : [{
            icon: "add_circle",
            tooltip: "Add New Art Item",
            isFreeAction: true,
            onClick: () => routeTo(p, urls.artItemAdd)
        }];
    const myLocations = useSelector(e => e.myLocations);
    const filters = useSelector(e => e.artItemsFilter);
    const [locationFilters, setLocationFilters] = useState<ArtItemFilterRequest>({
        artistIdList: filters.artist!.map(e => e.id),
        floorIdList: filters.floor!.map(e => e.id),
        locationIdList: filters.location!.map(e => e.id),
        roomIdList: filters.room!.map(e => e.id),
        techniqueList: filters.technique!.map(e => e.value),
        pictureQuality: filters.pictureQuality
    });
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        searchFieldStyle: {
            display: "none",
        },
        selection: isAdmin,
        pageSize: pagingContext.artItemPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    return <div className="ArtGallery-List-ArtItem table-list-pagination-bottom">
        {/* <DownloadPdf />  */}
        <ArtItemFilter onChange={e => setLocationFilters(e)} onSearch={() => {
            setPagingContext({ ...pagingContext, artItemPage: { page: 0, pageSize: 50 } });
            if (tableRef.current) {
                tableRef.current.onQueryChange();
            }
        }} />
        <MaterialTable
            options={pagination}
            columns={columns(p, tableRef, isAdmin, user, myLocations.map(e => e.id))}
            tableRef={tableRef}
            // data={loadArtItems(searchArtItems, locationFilters)}
            data={e => loadArtItems(searchArtItems, locationFilters)({ ...e, page: pagingContext.artItemPage.page })}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, artItemPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e });
            }}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, artItemPage: { ...pagingContext.artItemPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            title={trans.title}
            actions={actions}
            onSelectionChange={rowData => {
                dispatch(setSelectedArtItems(rowData));
            }}
        />
        {/* Printing modal */}
        <Dialog
            maxWidth="md"
            open={open}
            scroll="body"
            onClose={handleClose}>
            <DialogContent>
                {artItems.length === 0 ?
                    <Typography
                        color="error"
                    >
                        You don't have any selected items
                    </Typography> : null}
                <FormControl className={classes.formControl}>
                    <Grid container spacing={3} style={{ alignContent: "center", justifyContent: "space-between" }}>
                        <Grid item xs={12} sm={4} style={{ width: 250 }}>
                            {!isCatalog && <TextField style={{ width: "100%" }}
                                label="First item position on page"
                                type="number"
                                value={index}
                                // fullWidth
                                inputProps={{ min: 1, max: 8, step: 1 }}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                onChange={e => {
                                    const value = Number(e.target.value);
                                    if (value >= 1 && value <= 8)
                                        setIndex(Number(e.target.value));
                                }}
                            />}
                        </Grid>
                        {/* 
                        //This button can be ommitted since the layout updates onChange
                        <Grid item xs={12} sm={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={artItems.length === 0}
                                onClick={download}
                                startIcon={<CodeIcon />}
                            >
                                Create HTML
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} sm={4} style={{ display: "flex", padding: "12px", justifyContent: "center" }}>
                            <Button
                                style={{ padding: "4px 20px" }}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={artItems.length === 0}
                                onClick={print}
                                startIcon={<PrintIcon />}
                            >
                                Start Printing
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ display: "flex", padding: "12px", justifyContent: "flex-end" }}>
                            <Button
                                style={{ padding: "4px 20px" }}
                                variant="outlined"
                                color="primary"
                                size="small"
                                disabled={artItems.length === 0}
                                onClick={handleClose}
                            // startIcon={<PrintIcon />}
                            >
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </FormControl>
                <div className={classes.frameBox}>
                    <iframe id="label_html" name="label_html" title="Labels" style={{ width: "210mm", height: "297mm" }} src={html}>
                    </iframe>
                </div>
            </DialogContent>

        </Dialog>
    </div>;
});
