/* eslint-disable indent */
import { withRouter, RouteComponentProps } from "react-router";
import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, FormikErrors } from "formik";
import { Typography, Grid, TextField, Button, Card, CardContent, Theme, makeStyles, createStyles, FormLabel } from "@material-ui/core";
import { useLoadData, useTitle, withSuppressLoading } from "../store/utils";
import { ArtItem } from "../models/ArtItem";
import * as admin from "../services/admin.service";
import * as media from "../services/media.service";
import * as visitor from "../services/visitor.service";
import { CreateArtItemRequest } from "../models/CreateArtItemRequest";
import ImageApi from "../components/ImageApi";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { routeTo, urls } from "../navigation";
import { TextAutoCompleteSelector, handleTextAutoCompleteChange } from "../components/autocomplete-selector-text";
import AutocompleteSelect from "../components/autocomplete-selector";
import { getKeys, setAttr, confirmAndDelete, isValidText } from "../utils";
import { useSelector } from "../store";
import { dropDownPageSize } from "../services/config";
import { Floor } from "../models/Floor";
import { Room } from "../models/Room";
import { translations } from "../translates";
import { Prompt } from "react-router-dom";
import Rating from '@material-ui/lab/Rating';
import { ArtItemCloneContext } from "../contexts";
import { getLastArtItemPlacement } from "../services/placement.service";
import { Placement } from "../models/Placement";
type ArtItemWorkspace = Omit<ArtItem, "id">;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightIcon: {
            marginLeft: theme.spacing(1)
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        },
        legendBorrowed: {
            marginRight: "10px",
            color: "white !important",
            top: "-8px",
            position: "relative",
            textTransform: "uppercase",
            fontSize: "0.75rem",
            fontWeight: 500,
            padding: "0.75rem",
            backgroundColor: "#0C69B1", //krohneblue
        }
    })
);


export default withRouter(function (p: RouteComponentProps<{ id: string }>) {
    const displayModes = useSelector(s => s.displayMode);
    const classes = useStyles();
    const keysObject: Record<keyof CreateArtItemRequest, 1> = {
        artType: 1,
        artistId: 1,
        depth: 1,
        description: 1,
        framing: 1,
        height: 1,
        locationId: 1,
        roomId: 1,
        materials: 1,
        name: 1,
        ownerId: 1,
        picture: 1,
        technique: 1,
        width: 1,
        yearOfAcquisition: 1,
        yearOfCreation: 1,
        note: 1,
        pictureQuality: 1,
        edition: 1,
    };
    const keys = getKeys<CreateArtItemRequest>(keysObject);

    async function locationSuggestion(key: string) {
        return (await admin.searchLocations({
            key,
            unpaged: true,
            sortDirection: "ASC",
            sortColumn: "name"
        })).data.content;
    }
    async function myLocationSuggestion(key: string) {
        return (await admin.searchLocations({
            onlyMyLocations: true,
            key,
            unpaged: true,
            sortDirection: "ASC",
            sortColumn: "name"
        })).data.content;
    }

    async function floorSuggestion(locationId: number) {
        const result = (await admin.searchFloors({
            locationIds: [locationId],
        })).data;
        return result;
    }

    async function roomSuggestion(floorId: number) {
        return (await admin.searchRooms({
            floorIds: [floorId],
        })).data;
    }
    useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [modified, setModified] = useState<boolean>(false);
    const [artItem, setArtItem] = useState<ArtItemWorkspace>();
    const [floor, setFloor] = useState<Floor>({} as Floor);
    const [room, setRoom] = useState<Room>();
    const [imgSrc, setImgSrc] = useState<string>("");
    const setTitle = useTitle("");
    const trans = translations.artItemEdit;
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";
    const justAdmin = user.roles![0] === "admin";
    const [clone, setClone] = useContext(ArtItemCloneContext);
    const [placement, setPlacement] = useState<Placement>(null!);

    async function clonePicture(picture?: string) {
        if (picture && imgSrc) {

            const upload = await withSuppressLoading(() => media.getImage(picture, "Normal"));
            const file = new File([upload.data], "img");
            const result = await withSuppressLoading(() => media.uploadFile(file));
            return result.data.fileName;
        }
    }
    useLoadData(async () => {
        if (p.match.params.id) {

            const placementData = await getLastArtItemPlacement(+p.match.params.id);
            setPlacement(placementData.data);
            const result = await admin.getArtItem(+p.match.params.id);
            if (result.data.room) {
                setFloor(result.data.room.floor!);
                setRoom(result.data.room);
            }
            setTitle(`Edit Art Item - ${result.data.name}`);
            setArtItem(result.data);
            console.log(result.data);
        } else {
            if (clone !== null) {
                setArtItem({ ...clone, name: `${clone.name}-Copy` });
            } else {
                // TO BE MODIFIED ( getting current user )
                const currentCompanyQuery = await admin.searchCompany({ key: user.username });
                const currentCompany = currentCompanyQuery.data.content[0];
                // const defaultArtist = await visitor.searchArtist({ name: "ArtistUnkown" });
                setTitle("Add New Art Item");
                setArtItem({
                    // artist: defaultArtist.data.content[0],
                    // artistId:defaultArtist.data.content[0].id,
                    ownerId: currentCompany && currentCompany.id,
                    owner: currentCompany,
                    locationId: 0,
                    name: "",
                });
            }
        }
    });
    if (!artItem) {
        return <div></div>;
    }
    async function onSubmit(o: ArtItemWorkspace) {
        const artItemRequest = setAttr<CreateArtItemRequest>(keysObject, o);
        p.match.params.id ?
            await admin.updateArtItem(+p.match.params.id, artItemRequest) :
            await admin.createArtItem(artItemRequest);
        routeTo(p, urls.artItemList, displayModes);
    }

    async function getArtists(name: string) {

        const result = await visitor.searchArtist({
            name, pageNumber: 0, pageSize: dropDownPageSize,
            sortDirection: "ASC",
            sortColumn: "firstName",
        });
        return result.data.content;
    }
    async function getCompanies(key: string) {
        const result = await admin.searchCompany({
            key, pageNumber: 0, pageSize: dropDownPageSize, owner: true,
            sortDirection: "ASC",
            sortColumn: "name"
        });
        return result.data.content;
    }

    function validate(values: ArtItemWorkspace, key: keyof ArtItemWorkspace, errors: FormikErrors<ArtItemWorkspace>, valid: (text: string) => boolean): boolean {
        const value = values[key];
        if (typeof value === "string") {
            if (!valid(value)) {
                errors[key] = translations.errors.enterOnlyCharacters;
                return true;
            }
        }
        return false;
    }
    return <React.Fragment>
        <Prompt
            when={modified}
            message='You have unsaved changes, are you sure you want to leave?'
        />
        <div>
            <Formik<ArtItemWorkspace>
                initialValues={artItem} onSubmit={onSubmit}
                render={({ values, handleChange, setValues, errors, touched, isValid, dirty, isSubmitting }) => {
                    const qualities = ["LOW", "MEDIUM", "HIGH"];
                    const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                            const file = e.target.files[0];
                            const result = await media.uploadFile(file);
                            setValues({ ...values, picture: result.data.fileName, pictureQuality: result.data.pictureQuality });
                        }
                    };
                    const handleDownload = async (e: React.ChangeEvent<any>) => {
                        const download = document.createElement('a');
                        download.href = imgSrc;
                        download.download = `${values.name + '-' + values.inventoryNumber}.png`;
                        download.click();
                    };
                    if (!isSubmitting) {
                        setModified(dirty);
                    } else {
                        setModified(false);
                    }
                    return (
                        <Form className="ArtGallery-Form-ArtItemEdit">
                            <Card><CardContent>
                                <Grid container spacing={3} justify="space-between">
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="h5" gutterBottom>
                                            {p.match.params.id ? <>{trans.editing} {values.name} {values.artType}</> : <>Add new Art Item</>}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <div className="edit-buttons">
                                            <Button
                                                type="submit"
                                                fullWidth
                                                variant="contained"
                                                color="primary">
                                                {trans.publish}
                                            </Button>
                                            <Button
                                                onClick={(e) => {
                                                    routeTo(p, urls.artItemsView, [], displayModes);
                                                }}
                                                fullWidth
                                                variant="outlined"
                                                color="primary">
                                                {trans.discard}
                                            </Button>
                                            {!p.match.params.id ? <></> : <>
                                                <Button
                                                    onClick={async () => {
                                                        const imgClone = await clonePicture(values.picture);
                                                        setClone({ ...values, picture: imgClone } as any);
                                                        routeTo(p, urls.artItemAdd, displayModes);
                                                    }}
                                                    fullWidth
                                                    variant="outlined"
                                                    color="primary"
                                                    title="Create a copy from this item"
                                                >
                                                    Duplicate
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={confirmAndDelete(trans.confirmDelete, async () => {
                                                        await admin.deleteArtItem(+p.match.params.id!);
                                                        routeTo(p, urls.artItemList);
                                                    })}
                                                >
                                                    {trans.delete}
                                                </Button>
                                            </>}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container justify="center" spacing={8}>
                                    <Grid item xs={12} md={6} lg={4} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.mainInfo}
                                        </Typography>
                                        <TextField className="inputStyle"
                                            required
                                            label={trans.nameLabel}
                                            type="text"
                                            fullWidth
                                            value={values.name}
                                            id={keys.name}
                                            onChange={handleChange}
                                            error={validate(values, "name", errors, isValidText)}
                                            helperText={errors.name ? errors.name : null}
                                        />
                                        <Grid className="inputStyle inputStyle--clearPadding">
                                            <AutocompleteSelect
                                                label={trans.owner}
                                                required={true}
                                                initialSelectedItem={values.owner}
                                                display={a => a && a.name}
                                                getSuggestions={getCompanies} onSelect={a => setValues({
                                                    ...values,
                                                    ownerId: a && a.id,
                                                    // artist: a
                                                })}></AutocompleteSelect>
                                        </Grid>
                                        {values.inventoryNumber &&
                                            <TextField className="inputStyle"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                label={trans.inventoryNumber}
                                                type="number"
                                                fullWidth
                                                disabled={true}
                                                value={values.inventoryNumber!.toString().padStart(6, "0") || 0}
                                                id={"inventoryNumber"}
                                            />}
                                        {values.picture ? (
                                            <Grid className={`inputStyle inputStyle--clearPadding inputStyle--marginTop2rem ${classes.pictureContainer}`}>
                                                <ImageApi
                                                    src={values.picture}
                                                    imageType="Normal"
                                                    onSrcChange={src => {
                                                        setImgSrc(src);
                                                    }}
                                                ></ImageApi>
                                            </Grid>
                                        ) : (<></>)}
                                        <Grid container direction="row">
                                            <Grid item>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: "none" }}
                                                    id="raised-button-file"
                                                    onChange={handleCapture}
                                                    type="file"
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Button variant="contained" component="span">
                                                        {values.picture ? "Change Picture" : trans.uploadImage}
                                                        <CloudUploadIcon className={classes.rightIcon} />
                                                    </Button>
                                                </label>
                                            </Grid>
                                            <div style={{ flexGrow: 1 }}></div>
                                            {values.picture && <Grid item>
                                                <Button variant="contained" component="span" onClick={handleDownload}>
                                                    Download Picture
                                                </Button>

                                            </Grid>}
                                        </Grid>
                                        {values.pictureQuality ? <Grid>
                                            <div style={{ display: "flex", }}>
                                                <Typography variant="overline">
                                                    Picture quality
                                                </Typography>
                                                <div style={{ flexGrow: 1 }} />
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    {values.pictureQuality}
                                                    <Rating value={qualities.indexOf(values.pictureQuality) + 1} max={3} readOnly={true} />
                                                </div>
                                            </div>
                                        </Grid> : <></>}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.artItemLocationAssign}
                                        </Typography>
                                        {artItem.status === "BORROWED" &&
                                            <>
                                                <br></br>
                                                <Typography variant="overline">
                                                    Current Location
                                                </Typography>
                                                <Grid item className="inputStyle inputStyle--clearPadding">
                                                    <FormLabel className={classes.legendBorrowed}>BORROWED</FormLabel>
                                                    {placement && <FormLabel className={classes.legendBorrowed} onClick={() => {
                                                        routeTo(p, urls.placementsEdit, { id: placement.id })
                                                    }} style={{ cursor: "pointer", userSelect: "none" }} >{placement.destination ?? "Unknown Destination"}</FormLabel>}

                                                </Grid>
                                                <Typography variant="overline">
                                                    {/* {trans.artItemLocationAssign} */}
                                                    Previous Location
                                                </Typography>
                                            </>
                                        }
                                        <Grid item className="inputStyle inputStyle--clearPadding">
                                            <AutocompleteSelect
                                                getSuggestions={isAdmin ? locationSuggestion : myLocationSuggestion}
                                                label={trans.locationLabel}
                                                required={true}
                                                initialSelectedItem={values.location}
                                                display={o => o && o.name}
                                                onSelect={(o) => {
                                                    if (o && o.id !== values.locationId) {
                                                        setFloor({} as Floor);
                                                        setRoom({} as Room);
                                                        setValues({
                                                            ...values,
                                                            locationId: (o && o.id)!,
                                                        });
                                                    }
                                                }} />
                                        </Grid>
                                        {values.locationId ?
                                            <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                                <AutocompleteSelect
                                                    selectedItem={floor ? floor : undefined}
                                                    getSuggestions={() => floorSuggestion(values.locationId)}
                                                    label={trans.floorLabel}
                                                    required={true}
                                                    display={o => o && o.name!}
                                                    onSelect={(o) => {
                                                        if (o) setFloor(() => ({
                                                            ...o
                                                        }));
                                                    }}
                                                />
                                            </Grid>
                                            : <></>}
                                        {floor.id ?
                                            <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                                <AutocompleteSelect
                                                    selectedItem={room ? room : undefined}
                                                    getSuggestions={() => roomSuggestion(floor.id!)}
                                                    label={trans.roomLabel}
                                                    display={o => o && o.number!}
                                                    required={true}
                                                    onSelect={(o) => {
                                                        setRoom(o);
                                                        setValues({
                                                            ...values,
                                                            roomId: o && o.id!,
                                                        });
                                                    }}
                                                />
                                            </Grid> :
                                            <></>}
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.itemDetail}
                                        </Typography>
                                        <Grid item className="inputStyle inputStyle--clearPadding">
                                            <TextAutoCompleteSelector
                                                label={trans.typeLabel}
                                                required={false}
                                                initialSelectedItem={values.artType}
                                                getSuggestions={text => admin.searchAutoCompleteData({ text, dataType: "ART_TYPE" })}
                                                onSelect={handleTextAutoCompleteChange(keys.artType, values, setValues)}
                                            />
                                        </Grid>
                                        <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                            <AutocompleteSelect
                                                label={trans.artistLabel}
                                                required={true}
                                                initialSelectedItem={values.artist}
                                                display={a => a ? a.firstName + " " + a.lastName : ""}
                                                getSuggestions={getArtists} onSelect={a => setValues({
                                                    ...values,
                                                    artistId: a && a.id,
                                                    // artist: a
                                                })}></AutocompleteSelect>
                                        </Grid>
                                        <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                            <TextAutoCompleteSelector
                                                label={trans.tehniqueLabel}
                                                required={false}
                                                initialSelectedItem={values.technique}
                                                getSuggestions={text => admin.searchAutoCompleteData({ text, dataType: "TECHNIQUE" })}
                                                onSelect={handleTextAutoCompleteChange(keys.technique, values, setValues)} />
                                        </Grid>
                                        <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                            <TextAutoCompleteSelector
                                                label={trans.materialLabel}
                                                required={false}
                                                initialSelectedItem={values.materials}
                                                getSuggestions={text => admin.searchAutoCompleteData({ text, dataType: "MATERIAL" })}
                                                onSelect={handleTextAutoCompleteChange(keys.materials, values, setValues)} />
                                        </Grid>
                                        <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                            <TextAutoCompleteSelector
                                                label={trans.framingLabel}
                                                required={false}
                                                initialSelectedItem={values.framing}
                                                getSuggestions={text => admin.searchAutoCompleteData({ text, dataType: "FRAMING" })}
                                                onSelect={handleTextAutoCompleteChange(keys.framing, values, setValues)} />
                                        </Grid>
                                        <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                            <Grid container spacing={4}>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label={trans.heightLabel}

                                                        type="number"
                                                        value={values.height || ""}
                                                        id={keys.height}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label={trans.widthLabel}

                                                        type="number"
                                                        value={values.width || ""}
                                                        id={keys.width}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        label={trans.depthLabel}

                                                        type="number"
                                                        value={values.depth || ""}
                                                        id={keys.depth}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={"Edition"}
                                                    fullWidth
                                                    value={values.edition || ""}
                                                    id={keys.edition}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={4}>
                                            <Grid item xs>
                                                <TextField className="inputStyle inputStyle--clearPadding"
                                                    label={trans.yearOfCreationLabel}
                                                    type="number"
                                                    fullWidth
                                                    value={values.yearOfCreation || ""}
                                                    id={keys.yearOfCreation}
                                                    onChange={handleChange}
                                                    error={touched.yearOfCreation && errors.yearOfCreation ? true : false}
                                                    helperText={touched.yearOfCreation && errors.yearOfCreation ? errors.yearOfCreation : ""}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField className="inputStyle inputStyle--clearPadding"
                                                    label={trans.yearOfAquisitionLabel}
                                                    type="number"
                                                    fullWidth
                                                    value={values.yearOfAcquisition || ""}
                                                    id={keys.yearOfAcquisition}
                                                    onChange={handleChange}
                                                    error={touched.yearOfAcquisition && errors.yearOfAcquisition ? true : false}
                                                    helperText={touched.yearOfAcquisition && errors.yearOfAcquisition ? errors.yearOfAcquisition : ""}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.description}
                                        </Typography>
                                        <Grid item className="inputStyle inputStyle--clearPadding">
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                fullWidth
                                                label={trans.description}
                                                type="text"
                                                rows="13"
                                                rowsMax="50"
                                                value={values.description || ""}
                                                id={keys.description}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        {justAdmin && <Grid item className="inputStyle inputStyle--clearPadding">
                                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "5px" }}>
                                                <Typography variant="overline">
                                                    {trans.itemNotes}
                                                </Typography>
                                                <div style={{ flexGrow: 1 }}></div>
                                                {p.match.params.id && <Button
                                                    onClick={(e) => {
                                                        routeTo(p, urls.history, { defaultEntity: "ArtItem", linkId: +p.match.params.id })
                                                    }}
                                                    variant="outlined"
                                                    color="primary">
                                                    View History
                                                </Button>}
                                            </div>
                                            <TextField
                                                variant="outlined"
                                                multiline
                                                draggable={true}
                                                fullWidth
                                                label={trans.itemNotes}
                                                type="text"
                                                rows="2"
                                                rowsMax="50"
                                                value={values.note || ""}
                                                id={keys.note}
                                                onChange={handleChange}
                                            />
                                        </Grid>}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            </Card>
                        </Form>
                    );
                }} />
        </div>
    </React.Fragment>;
});