import React, {
    FunctionComponent,
    useState,
    useEffect,
    useMemo
} from "react";
import {
    Grid,
    Paper,
    IconButton,
    InputBase,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import * as visitorService from "../../services/visitor.service";
import { Location } from "../../models/Location";
import { ArtItem as IArtItem } from "../../models/ArtItem";

import ArtItem from "./art-item";
import AutocompleteSelect from "../../components/autocomplete-selector";
import { CreatePlacementRequest } from "../../models/CreatePlacementRequest";
import { useLoadData } from "../../store/utils";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // padding: "2px 4px",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            width: 400

        },
        iconButton: {
            padding: 10,
            // color: "white"
        },
        scrollableContainer: {
            // minHeight: 500,
            maxHeight: 500,
            overflowY: "auto"
        },
        globalSearch: {
            border: "1px solid lightgray ",
            borderRadius: "4px",
            "& div": {
                padding: 0,
                width: "100%",
                boxSizing: "border-box",
                backgroundColor: "transparent",
                verticalAlign: "middle",
                boxShadow: "0 0 0 transparent",
                transition: "background-color 0.35s ease-out"
            },
        },
        searchField: {
            margin: 0,
        },
        white: {
            // color: "white"
        }
    })
);


interface ArtItemSelectorProps {
    initialSelection?: IArtItem[];
    placementType?: CreatePlacementRequest["placementType"];
    locations: (key: string) => Promise<Location[]>;
    onChange?: (ids: number[]) => void;
}

// TODO: types
const ArtItemSelector: FunctionComponent<ArtItemSelectorProps> = ({
    initialSelection = [],
    locations,
    onChange,
    placementType,
}) => {
    const classes = useStyles();
    const [locationId, setLocationId] = useState<number>(0);
    const [searchKey, setSearchKey] = useState<string>("");
    const [word, setWord] = useState<string>("");
    const [locationItems, setLocationItems] = useState<IArtItem[]>([]);
    const [selectedItems, setSelectedItems] = useState<IArtItem[]>(
        initialSelection
    );
    useEffect(() => {
        if (initialSelection.length === 0)
            setSelectedItems([]);
        //eslint-disable-next-line
    }, [placementType]);
    useLoadData(async () => {
        if (locationId) {
            const result = await visitorService.searchArtItems({ locationIdList: [locationId], key: searchKey, unpaged: true, status: "AVAIL" });
            setLocationItems(result.data.content);
        }
    }, [locationId, searchKey, setLocationItems]);

    const search = () => {
        setSearchKey(word);
    };

    function onEnterIsPressed(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            search();
            e.preventDefault();
        }
    }

    // const onRemove = (item: IArtItem) => {
    //     const newSelectedItems = selectedItems.filter(
    //         i => i.id !== item.id
    //     );
    //     onChange && onChange(newSelectedItems.map(i => i.id));
    //     setSelectedItems(newSelectedItems);
    // };

    return useMemo(() => (<Grid
        container
        direction="row"
        style={{
            border: "1px solid lightgray",
            marginTop: "1rem",
            borderRadius: "0.35rem"
        }}
    >
        <Grid container style={{ backgroundColor: "#eee", alignItems: "center", padding: "0.5rem" }}>
            <Grid item xs={12} sm={6}>
                <div style={{ padding: "0.5rem" }}>
                    <AutocompleteSelect
                        getSuggestions={locations}
                        display={e => e ? e.name : ""}
                        label="Location"
                        onSelect={e => {
                            if (e) {
                                const newLocationId = e.id;
                                if (newLocationId !== locationId) {
                                    if (placementType !== "BORROW") {
                                        setSelectedItems([]);
                                    }
                                    setLocationId(e.id);
                                }
                            } else {
                                if (placementType !== "BORROW") {
                                    setSelectedItems([]);
                                }
                                setLocationId(0);
                            }
                            setWord("");
                            setSearchKey("");

                        }}
                        required={false}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.globalSearch} >
                <Paper className={classes.root} >
                    <IconButton
                        onClick={() => search()}
                        className={classes.iconButton}
                        aria-label="search"
                    >
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        name="item-selector-search"
                        placeholder="Search selected items..."
                        value={word}
                        inputProps={{ "aria-label": "Search" }}
                        onChange={e => setWord(e.target.value)}
                        onKeyPress={onEnterIsPressed}
                    />
                </Paper>
            </Grid>
        </Grid>
        <Grid container style={{ backgroundColor: "" }}>
            <Grid item xs={12} sm={6} className={classes.scrollableContainer} style={{ backgroundColor: "#eee", padding: "0.5rem" }}>
                {locationItems.map((item, i) => {
                    const isAdded = selectedItems.find(i => i.id === item.id);
                    // TODO: maybe extract function?
                    const onAdd = !isAdded
                        ? () => {
                            const newSelectedItems = [...selectedItems, item];
                            onChange && onChange(newSelectedItems.map(i => i.id));
                            setSelectedItems(newSelectedItems);
                        }
                        : undefined;
                    return <ArtItem item={item} onAdd={onAdd} key={item.name + i + "ADD"} />;
                })}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.scrollableContainer} style={{ backgroundColor: "#eee", padding: "0.5rem" }}>
                {selectedItems.map((item, index) => {
                    const onRemove = () => {
                        const newSelectedItems = selectedItems.filter(
                            i => i.id !== item.id
                        );
                        onChange && onChange(newSelectedItems.map(i => i.id));
                        setSelectedItems(newSelectedItems);
                    };
                    return <ArtItem item={item} onRemove={onRemove} key={item.id + index + " REMOVE" + Math.random()} />
                })}
                {/* Note: the Math.random() is there to ensure that the component will have an unique key , without it the app will have an unwanted behavior */}
            </Grid>
        </Grid>
    </Grid>),
        // eslint-disable-next-line
        [locationItems, selectedItems, searchKey, word]);
};

export default ArtItemSelector;
