import * as actions from "../actions/artItemsFilter";
import { ActionsOf } from "../utils";
import { init } from "./utils";

export function artItemsFilter(
    state = init<actions.ArtItemsFilter>({ floor: [], location: [], room: [], artist: [], technique: [], pictureQuality: null! }),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
        case "SET_ART_ITEMS_FILTER":
            return action.obj;
        default:
            return state;
    }
}
