import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useTitle, useLoadData, withSuppressLoading } from "../store/utils";
import { makeStyles, Typography, FormLabel, Grid, Dialog, CircularProgress, Button, Icon, IconButton } from "@material-ui/core";
import { ArtItem } from "../models/ArtItem";
import { getArtItem } from "../services/admin.service";
import ImageApi from "../components/ImageApi";
import { getImage, getQRCode } from "../services/media.service";
import { useSelector, useDispatch } from "../store";
import { routeTo, urls } from "../navigation";
import { searchArtItems } from "../services/visitor.service";
import { setArtItemsIds } from "../store/actions/artItemsIds";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { Placement } from "../models/Placement";
import { getLastArtItemPlacement } from "../services/placement.service";

const useStyles = makeStyles(theme => ({
    progress: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100px!important",
        height: "100px!important"
    },
    viewRoot: {
        "& label": {
            color: "gray",
            textTransform: "capitalize",
            fontSize: "1rem",
            fontWeight: 400
        },
        "& span": {
        },
        "& legend": {
            color: "#0C69B1", //krohneblue
            margin: "0.75rem 0 0.25rem",
            textTransform: "uppercase",
            fontSize: "0.9rem",
            fontWeight: 500,
            borderBottom: "1px solid #c0c0c0",
            paddingBottom: 7
        }
    },
    title: {
        justifyContent: "space-between", display: "flex", alignItems: "center"
    },
    legendBorrowed: {
        top: "8px",
        position: "relative",
        color: "white !important",
        textTransform: "uppercase",
        fontSize: "1rem",
        fontWeight: 500,
        padding: "0.75rem",
        backgroundColor: "#0C69B1", //krohneblue
    },
    qrCodeImageImageContainer: {
        margin: 0,
        padding: 0,
        width: "25%",
        maxHeight: "85vh",
        display: "flex",
        justifyContent: "center"
    },
    qrCodeImg: {
        backgroundColor: "lightgray",
        objectFit: "contain",
    },
    imageContainer: {
        margin: 0,
        padding: 0,
        width: "75%",
        minHeight: "300px",
        maxHeight: "85vh",
        display: "flex",
        justifyContent: "center"
    },
    mainImg: {
        backgroundColor: "lightgray",
        width: "100%",
        height: "100%",
        objectFit: "contain", // use "scale-down" to see picture at real size (not as the size of the) else "containt" to scale to parent size
    }
}));

export default withRouter(function (p: RouteComponentProps<{ id: string, name: string }>) {
    useTitle("Art items");
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";
    const isVisitor = user.roles![0] === "visitor";
    const classes = useStyles();
    const artItemIds = useSelector(app => app.artItemsIds);
    const dispatch = useDispatch();
    const [artItem, setArtItem] = React.useState<ArtItem>();
    const [dialogImage, setDialogImage] = React.useState<string>();
    const [loadItems, setLoadItems] = React.useState<boolean>(false);
    const [hasMore, setHasMore] = React.useState<boolean>(true);
    const [qrCode, setQrCode] = React.useState<string>();
    const [placement, setPlacement] = React.useState<Placement>(null!);
    useLoadData(async () => {

        const placementData = await getLastArtItemPlacement(+p.match.params.id);
        setPlacement(placementData.data);
        let result = await getArtItem(+p.match.params.id);
        if (result.data.id === artItemIds.ids[artItemIds.ids.length - 1] && hasMore) {
            const newPage = { ...artItemIds.q, page: artItemIds.q.page + 1 };
            const nextSeries = await searchArtItems({
                pageNumber: newPage.page,
                pageSize: newPage.pageSize,
                key: newPage.search,
            });
            if (nextSeries.data.content.length < newPage.pageSize) {
                setHasMore(false);
            }
            if (nextSeries.data.content.length) {
                dispatch(setArtItemsIds({ q: newPage, ids: artItemIds.ids.concat(nextSeries.data.content.map(e => e.id)) }));
            }
        }
        let qr = await getQRCode(p.location.pathname.substr(1));
        const myBlob = new Blob([qr.data]);
        setQrCode(URL.createObjectURL(myBlob));
        setArtItem(result.data);
    }, [loadItems]);

    const handleOpen = (imageId: string | undefined) => async () => {
        if (imageId) {
            try {
                setLoaded(false);
                if (!open) setOpen(true);
                const result = await withSuppressLoading(() => getImage(imageId, "Normal"));
                const blob = new Blob([result.data]);
                setDialogImage(URL.createObjectURL(blob));
                setLoaded(true);
            } catch (e) {
                setOpen(false);
            }
        }
    };

    async function handleOpenQRCode() {
        setLoaded(false);
        setOpen(true);
        setDialogImage(qrCode);
        setLoaded(true);
    }

    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const route = (type: "PREV" | "NEXT") => () => {
        const currentIndex = artItemIds.ids.indexOf(+p.match.params.id);
        switch (type) {
            case "PREV":
                routeTo(p, urls.artItemView, { id: artItemIds.ids[currentIndex - 1] });
                break;
            case "NEXT":
                routeTo(p, urls.artItemView, { id: artItemIds.ids[currentIndex + 1] });
                break;
        }
        setLoadItems(!loadItems);
    };
    const handleClose = () => {
        setDialogImage("");
        setOpen(false);
    };
    if (!artItem) {
        return null;
    }
    return (
        <div className="ArtGallery-ArtItem-View">
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
                fullScreen
                open={open}
                onClose={handleClose}>
                <div className="PopupImageContainer">
                    <CircularProgress className={classes.progress} style={loaded ? { display: "none" } : { display: "block" }} />
                    <img onClick={handleClose} alt="" src={dialogImage} />
                </div>
            </Dialog>
            <Grid container className={classes.viewRoot} spacing={6}>
                <Grid item className={classes.imageContainer} xs={12} sm={12} md={8} lg={9} style={{ backgroundColor: "" }}>
                    {!artItem.picture ?
                        <img src="/error_loading.png" alt="" /> :
                        <ImageApi className={classes.mainImg} src={artItem.picture!} imageType="Tile" onClick={handleOpen(artItem.picture!)} />}
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Grid container spacing={3}>
                        {artItemIds.ids.length ? <>
                            {artItemIds.ids.indexOf(+p.match.params.id) !== 0 ?
                                <Grid item xs={6} style={{ marginBottom: "1rem" }}>
                                    <Button
                                        title="Go to previous art item"
                                        onClick={route("PREV")}
                                        fullWidth
                                        variant="outlined"
                                        startIcon={<ArrowBack />}
                                        color="primary">
                                        Previous
                                    </Button>
                                </Grid> : <Grid item xs={6}></Grid>}
                            {+p.match.params.id !== artItemIds.ids[artItemIds.ids.length - 1] ?
                                <Grid item xs={6} style={{ marginBottom: "1rem" }}>
                                    <Button
                                        title="Go to next art item"
                                        onClick={route("NEXT")}
                                        fullWidth
                                        variant="outlined"
                                        endIcon={<ArrowForward />}
                                        color="primary">
                                        Next
                                    </Button>
                                </Grid> : <Grid item xs={6}></Grid>}
                        </> : null}
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom className={classes.title} style={{ color: artItem.name ? "" : "gray" }}>
                                {artItem.name ? artItem.name : "~ Without name ~"}
                                {/* {`${artItem.name} by ${artItem.artist && artItem.artist.fullName || "Unknown Artist"}`} */}
                                {(isAdmin || (artItem.owner && artItem.owner!.name === user.username)) &&
                                    <IconButton title="Edit this art item" onClick={e => routeTo(p, urls.artItemEdit, { name: artItem.name, id: artItem.id })}><Icon>edit</Icon></IconButton>
                                }
                            </Typography>
                        </Grid>
                        <Grid item className={classes.qrCodeImageImageContainer} xs={12} sm={8} lg={9} style={{ backgroundColor: "" }}>
                            {/* {!qrCode ?
                            <img src="/error_loading.png" alt="" /> :
                            <img className={classes.qrCodeImg} src={qrCode!} alt="" />} */}
                        </Grid>
                        {!isVisitor &&
                            <Grid item style={{ alignSelf: "center" }} xs={6}>
                                <Typography>
                                    <label>Inventory Number:</label> <span>{artItem.inventoryNumber?.toString().padStart(6, "0")}</span>
                                </Typography>
                            </Grid>
                        }
                        <Grid item style={{ display: "flex", justifyContent: "flex-end" }} xs={6}> <Button
                            title="Get the QR Code of this item"
                            onClick={handleOpenQRCode}
                            variant="outlined"
                            color="primary">
                            QR Code
                        </Button>
                        </Grid>
                        {/* Location */}
                        {(!isVisitor && artItem.status === "BORROWED") &&
                            <Grid item xs={12}>
                                <FormLabel component="legend" className={classes.legendBorrowed}>Current Location</FormLabel>
                                <div style={{
                                    display: "flex",
                                }}>
                                    <FormLabel className={classes.legendBorrowed}>BORROWED</FormLabel>
                                    {/* <div style={{ flexGrow: 1 }}></div> */}
                                    {placement && <FormLabel className={classes.legendBorrowed} onClick={() => {
                                        routeTo(p, urls.placementsEdit, { id: placement.id })
                                    }} style={{ cursor: "pointer", userSelect: "none", marginLeft: "5px" }} >{placement.destination ?? "Unknown Destination"}</FormLabel>}
                                </div>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {!isVisitor ? <>
                                <FormLabel component="legend">{artItem.status === "BORROWED" ? "Previous Location" : "Current Location"}</FormLabel>
                                <Typography>
                                    <label>Location Name:</label> <span>{artItem.location!.name}</span>
                                </Typography>
                                {artItem.location!.address &&
                                    <Typography>
                                        <label>Address:</label> <span>{artItem.location!.address}</span>
                                    </Typography>}
                                <Typography>
                                    <label>City:</label> <span>{artItem.location!.city}</span>
                                </Typography>
                                {artItem.location!.region &&
                                    <Typography>
                                        <label>State/Province/Region:</label> <span>{artItem.location!.name}</span>
                                    </Typography>}
                                {(artItem.location!.postcode && Number(artItem.location!.postcode) > 0) &&
                                    <Typography>
                                        <label>Postal Code:</label> <span>{artItem.location!.postcode}</span>
                                    </Typography>}
                                {artItem.status === "AVAIL" ? <>
                                    <Typography>
                                        <label>Floor:</label> <span>{artItem.room && artItem.room.floor!.name}</span>
                                    </Typography>
                                    <Typography>
                                        <label>Room:</label> <span>{artItem.room && (artItem.room.name ? `${artItem.room.name}-${artItem.room.number}` : `${artItem.room.number}`)}</span>
                                    </Typography></> : <><label><Typography>{artItem.status === "ARCHIVED" && " Art item is archived "}</Typography></label></>}
                            </> : <></>}
                        </Grid>

                        {/* Owner */}
                        {!isVisitor ? <>
                            {!artItem.owner ? <></> :
                                <Grid item xs={12}>
                                    <FormLabel component="legend">Owner</FormLabel>
                                    <Typography>
                                        <label>Name:</label> <span>{artItem.owner.name!}</span>
                                    </Typography>
                                    {/* <Typography>
                                        <label>Email:</label> <span>{artItem.owner.email}</span>
                                    </Typography> */}
                                </Grid>
                            }
                        </> : <></>}

                        {/* Artist */}
                        {!artItem.artist ? <></> :
                            <Grid item xs={12}>
                                <FormLabel component="legend">Artist</FormLabel>
                                <Typography>
                                    <label>Name:</label> <span>{artItem.artist!.firstName + " " + artItem.artist!.lastName}</span>
                                </Typography>
                                {artItem.artist!.kind &&
                                    <Typography>
                                        <label>Kind:</label> <span>{artItem.artist!.kind}</span>
                                    </Typography>}
                                {/* {artItem.artist!.description &&
                                    <Typography>
                                        <label>Description:</label> {artItem.artist!.description}
                                    </Typography>} */}
                            </Grid>}

                        {/* Details */}
                        <Grid item xs={12}>
                            <FormLabel component="legend">Details</FormLabel>
                            {(Number(artItem.yearOfAcquisition) > 0) &&
                                <Typography>
                                    <label>Year of Acquisition:</label> <span>{artItem.yearOfAcquisition?.toString()}</span>
                                </Typography>
                            }
                            {(Number(artItem.yearOfCreation) > 0) &&
                                <Typography>
                                    <label>Year of Creation:</label> <span>{artItem.yearOfCreation?.toString()}</span>
                                </Typography>}
                            {artItem.framing &&
                                <Typography>
                                    <label>Framing:</label> <span>{artItem.framing}</span>
                                </Typography>}
                            {artItem.materials &&
                                <Typography>
                                    <label>Material:</label> <span>{artItem.materials}</span>
                                </Typography>}
                            {artItem.technique &&
                                <Typography>
                                    <label>Technique:</label> <span>{artItem.technique}</span>
                                </Typography>}
                            {(Number(artItem.width) > 0) &&
                                <Typography>
                                    <label>Width:</label> <span>{artItem.width}</span>
                                </Typography>}
                            {(Number(artItem.height) > 0) &&
                                <Typography>
                                    <label>Height:</label> <span>{artItem.height}</span>
                                </Typography>}
                            {(Number(artItem.depth) > 0) &&
                                <Typography>
                                    <label>Depth:</label> <span>{artItem.depth}</span>
                                </Typography>}
                        </Grid>

                        {/* Description */}
                        <Grid item xs={12}>
                            {artItem.description && <>
                                <FormLabel component="legend">Description</FormLabel>
                                <Typography>
                                    <span>{artItem.description}</span>
                                </Typography>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
});
