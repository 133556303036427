import XRegExp from "xregexp";
export function appendFormData(data: FormData, key: string, value: string | File | File[]) {
    if (value instanceof Array) {
        value.forEach(f => data.append(f.name, f));
    } else {
        data.append(key, value);
    }
}
export const isValidText = (text: string) => {
    // eslint-disable-next-line quotes
    if (text.length) {
        const pattern = XRegExp("[\\p{Latin}\\p{Common}]");
        return pattern.test(text);
    } 
    return true;
};
export function o<T>(o: T) {
    return o;
}
export function getKeys<T>(p: Record<keyof T, 1>): { [P in keyof T]-?: P } {
    for (const k in p) {
        p[k] = k as any;
    }
    return p as any;
}

export function setAttr<T>(p: Record<keyof T, 1>, n: T): T {
    for (const k in p) {
        p[k] = n[k] as any;
    }
    return p as any;
}

export function confirmAndDelete(msg: string, yes: () => Promise<void>) {
    return async function () {
        const confirmation = window.confirm(msg);
        if (confirmation) {
            await yes();
        }
    };
}

export function delay(ms: number) {
    return new Promise<void>(r => setTimeout(r, ms));
}