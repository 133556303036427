import { AuthRequest } from "../models/AuthRequest";
import { AuthResponse } from "../models/AuthResponse";
import { ResponseEntity } from "../models/ResponseEntity";
import { RegisterRequest } from "../models/RegisterRequest";
import { baseUrl, headers } from "./config";
import { AxiosResponse } from "axios";
import axios from "axios";
export function login(data: AuthRequest): Promise<AxiosResponse<AuthResponse>> {
    const url = `${baseUrl}/api/auth/login`;
    return axios.post<AuthResponse>(url, data, { headers });
}
export function logout(): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/auth/logout`;
    return axios.post<ResponseEntity>(url, null, { headers });
}

export function ping(): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/auth/ping`;
    return axios.get<ResponseEntity>(url, { headers });
}
export function register(registerRequest: RegisterRequest): Promise<AxiosResponse<ResponseEntity>> {
    const url = `${baseUrl}/api/auth/register`;
    return axios.post<ResponseEntity>(url, registerRequest, { headers });
}
