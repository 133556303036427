import reduxThunk, { ThunkDispatch } from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { appReducer, AppState, AppActions } from "./reducers";
import { 
    useSelector as reduxUseSelector,
    useDispatch as reduxUseDispatch
} from "react-redux";


export const store = createStore(appReducer, applyMiddleware(reduxThunk));

export const useSelector = <TSelector>(selector: (state: AppState) => TSelector) => reduxUseSelector(selector);
export const useDispatch = () => reduxUseDispatch<typeof store["dispatch"]>() as ThunkDispatch<AppState, void, AppActions>;

export function thunk<T>(fn: (dispatch: ThunkDispatch<AppState, void, AppActions>, getState: ()=> AppState) => T) {
    return fn;
}
