import React, { useState, useRef, useContext } from "react";
import { withRouter } from "react-router";
import MaterialTable, { Options } from "material-table";
import * as admin from "../services/admin.service";
import { loadItems, useTitle } from "../store/utils";
import { User } from "../models/User";
import { withoutSorting } from "../components/edit-delete-column";
import { routeTo, urls } from "../navigation";
import { translations } from "../translates";
import { PagingContext } from "../contexts";
import { IsAdmin } from "../components/Roles";
import { IconButton, Icon } from "@material-ui/core";

export default withRouter(function (p) {
    useTitle("Users");
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: true,
        searchFieldStyle: {
            display: "none",
        },
        pageSize: pagingContext.userPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });

    const tableRef = useRef<any>(null);
    const trans = translations.userList;
    return <div className="ArtGallery-List-UserList table-list-pagination-bottom">
        <MaterialTable<User>
            title={trans.title}
            tableRef={tableRef}
            options={pagination}
            columns={[
                ...withoutSorting<User>({ title: trans.firstName, field: "firstName" },
                    { title: trans.lastName, field: "lastName" },
                    { title: trans.userName, field: "username" },
                    { title: "Email", field: "email" },
                    { title: "Role", render: e => e.roles.length > 0 ? e.roles[0].name : null, field: "roles.name" }),
                {
                    title: "",
                    cellStyle: { textAlign: "right" },
                    render: r => <div>
                        <IsAdmin>
                            <IconButton title="Edit user"  onClick={e => routeTo(p, urls.userEdit, { name: r.username || r.firstName, id: r.id! })}><Icon>edit</Icon></IconButton>
                            <IconButton title="View user history"  onClick={e => routeTo(p, urls.history, { defaultEntity: "User", linkId: r.id })}><Icon>history</Icon></IconButton>
                            <IconButton 
                                title="Delete user" 
                                onClick={async e => {
                                const confirmation = window.confirm(translations.confirm(r.username));
                                if (confirmation) {
                                    await admin.deleteUser(r.id!);
                                }
                                tableRef.current.onQueryChange();
                            }}><Icon>delete</Icon></IconButton>
                        </IsAdmin>
                    </div>
                }
            ]}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, userPage: { ...pagingContext.userPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, userPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e })
            }}
            data={e => loadItems(admin.searchUsers)({ ...e, page: pagingContext.userPage.page })}
            actions={[{
                icon: "delete",
                tooltip: "Delete",
                onClick: async (event, rowData) => {
                    const confirmation = window.confirm("Are you sure you want to delete the selected items?");
                    if (confirmation && Array.isArray(rowData)) {
                        for (const i of rowData) {
                            await admin.deleteUser(i.id);
                        }
                        if (tableRef.current) {
                            tableRef.current.onQueryChange();
                        }
                    }
                }
            }, {
                icon: "add_circle",
                tooltip: "Add User",
                isFreeAction: true,
                onClick: () => routeTo(p, urls.userAdd),
            }]}

        />
    </div>;
});