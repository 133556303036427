/* eslint-disable indent */
import React, { useState } from "react";
import * as admin from "../services/admin.service";
import { useLoadData, useTitle } from "../store/utils";
import { withRouter, RouteComponentProps } from "react-router";
import {
    Button, Card, CardContent, Typography, Grid, TextField,
    FormControl, InputLabel, Select, MenuItem, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
} from "@material-ui/core";
import { routeTo, urls } from "../navigation";
import { Formik, Form, FormikErrors } from "formik";
import { getKeys, setAttr, confirmAndDelete, isValidText } from "../utils";
import { CreateUserRequest } from "../models/CreateUserRequest";
import { User } from "../models/User";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { translations } from "../translates";
import { Prompt } from "react-router-dom";
import { UserLocationEdit } from "./user-edit-location";

function userToCreateUserRequest(user: User): CreateUserRequest {
    return {
        ...user,
        roles: user.roles.map(r => r.name)
    };
}

export default withRouter(function (p: RouteComponentProps<{ name?: string, id?: string }>) {
    const setTitle = useTitle("");
    const keysObject: Record<keyof CreateUserRequest, 1> = {
        email: 1,
        enabled: 1,
        firstName: 1,
        lastName: 1,
        password: 1,
        roles: 1,
        username: 1,
    };
    const keys = getKeys<CreateUserRequest>(keysObject);
    const roles = ["admin", "global", "local", "visitor"];

    React.useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [locationsError, setLocationsError] = useState<boolean>(false);
    const [modified, setModified] = useState<boolean>(false);
    const [user, setUser] = useState<User>();
    const [password, setNewPassword] = useState<string>("");
    const trans = translations.userEdit;
    const [onSubmitHandlers,] = useState({
        setSaveLocations: (id: number) => Promise.resolve(),
        hasLocations: () => true as boolean,
    });

    useLoadData(async () => {
        if (p.match.params.id) {
            const result = await admin.getUser(+p.match.params.id);
            setTitle(`Edit User - ${result.data.username}`);
            setUser(result.data);
        } else {
            setTitle("Add User");
            setUser({
                email: "",
                enabled: true,
                firstName: "",
                // password: "",
                roles: [{ name: "admin" }],
                username: "",
                lastName: "",
                id: undefined!,
            });
        }
    });
    function validate(values: CreateUserRequest, key: keyof CreateUserRequest, errors: FormikErrors<CreateUserRequest>, valid: (text: string) => boolean): boolean {
        const value = values[key];
        if (typeof value === "string") {
            if (!valid(value)) {
                if (key !== "roles")
                    errors[key] = translations.errors.enterOnlyCharacters;
                return true;
            }
        }
        return false;
    }

    function validatePassword(values: CreateUserRequest, errors: FormikErrors<CreateUserRequest>): boolean {
        // const key = "password";
        const value = values.password;
        if (typeof value === "string") {
            if (password !== value) {
                errors.password = translations.errors.thePasswordsMustMatch;
                return true;
            }
        }
        return false;
    }

    if (!user) {
        return <div></div>;
    }
    async function onSubmit(obj: CreateUserRequest) {
        const o = setAttr<CreateUserRequest>(keysObject, obj);
        if (o.roles[0] === "local")
            try {
                if (onSubmitHandlers.hasLocations()) {
                    let user = p.match.params.id ?
                        (await admin.updateUser(o, +p.match.params.id)).data :
                        (await admin.createUser(o)).data;
                    routeTo(p, urls.userList);
                    await onSubmitHandlers.setSaveLocations(user.id);
                } else {
                    setLocationsError(true);
                }
            } catch (e) {
                // some handle in case of errors :) 
            }
        else {
            p.match.params.id ?
                await admin.updateUser(o, +p.match.params.id) :
                await admin.createUser(o);
            routeTo(p, urls.userList);
        }
    }


    if (user == null) {
        return <div>
            {trans.loading}
        </div>;
    } else return <React.Fragment>
        <Prompt
            when={modified}
            message='You have unsaved changes, are you sure you want to leave?'
        /><Formik<CreateUserRequest>
            initialValues={userToCreateUserRequest(user)}
            onSubmit={onSubmit}
            render={({ values, handleChange, setValues, errors, dirty, isSubmitting }) => {
                if (!isSubmitting) {
                    setModified(dirty);
                } else {
                    setModified(false);
                }
                return (
                    <Form className="ArtGallery-Form-EditUser">
                        <Card>
                            <CardContent>
                                <Grid container spacing={3} justify="space-between">
                                    <Grid item xs={12} sm={3}>
                                        <Typography variant="h5" gutterBottom>
                                            {p.match.params.name ? (<>
                                                {trans.modify} {user.username}</>
                                            ) : (
                                                    <>{trans.createNewUser}</>
                                                )}
                                        </Typography>
                                    </Grid>
                                    <Grid item >
                                        <div className="edit-buttons">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                            >
                                                {trans.publish}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => routeTo(p, urls.userList)}
                                            >
                                                {trans.discard}
                                            </Button>
                                            {!user.id ? <></> :
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    onClick={confirmAndDelete(trans.confirmDelete, async () => {
                                                        await admin.deleteUser(user.id);
                                                        routeTo(p, urls.userList);
                                                    })}
                                                >
                                                    {trans.delete}
                                                </Button>}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container justify="center" spacing={8}>
                                    <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.infoUser}
                                        </Typography>
                                        <TextField
                                            required
                                            label={trans.firstNameLabel}
                                            fullWidth
                                            value={values.firstName}
                                            id={keys.firstName}
                                            onChange={handleChange}
                                            error={validate(values, "firstName", errors, isValidText)}
                                            helperText={errors.firstName ? errors.firstName : null}
                                            className="inputStyle"
                                        />
                                        <TextField
                                            required
                                            label={trans.lastNameLabel}
                                            fullWidth
                                            value={values.lastName}
                                            id={keys.lastName}
                                            onChange={handleChange}
                                            error={validate(values, "lastName", errors, isValidText)}
                                            helperText={errors.lastName ? errors.lastName : null}
                                            className="inputStyle"
                                        />
                                        <TextField
                                            required
                                            label={trans.emailLabel}
                                            type="email"
                                            fullWidth
                                            value={values.email}
                                            id={keys.email}
                                            onChange={handleChange}
                                            className="inputStyle"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.loginInfoUser}
                                        </Typography>
                                        {p.match.params.id ?
                                            <>
                                                <TextField
                                                    required
                                                    type="text"
                                                    label={trans.usernameLabel}
                                                    fullWidth
                                                    value={values.username}
                                                    id={keys.username}
                                                    onChange={handleChange}
                                                    error={validate(values, "username", errors, isValidText)}
                                                    helperText={errors.username ? errors.username : null}
                                                    className="inputStyle"
                                                />
                                                <ExpansionPanel className="inputStyle">
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>{trans.changePassword}</Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <Grid container>
                                                            {/* <Grid item xs={12}>
                                                                <TextField
                                                                    type="password"
                                                                    label={trans.currentPasswordLabel}
                                                                    fullWidth
                                                                />
                                                        </Grid> */}
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    type="password"
                                                                    label={trans.newPasswordLabel}
                                                                    value={password}
                                                                    fullWidth
                                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    type="password"
                                                                    label={trans.confirmNewPasswordLabel}
                                                                    value={values.password || ""}
                                                                    id={keys.password}
                                                                    fullWidth
                                                                    onChange={handleChange}
                                                                    error={validatePassword(values, errors)}
                                                                    helperText={errors.password ? errors.password : null}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>

                                            </>
                                            :
                                            <>

                                                <TextField
                                                    required
                                                    type="text"
                                                    disabled={p.match.params.id ? true : false}
                                                    label={trans.usernameLabel}
                                                    fullWidth
                                                    value={values.username}
                                                    id={keys.username}
                                                    onChange={handleChange}
                                                    error={validate(values, "username", errors, isValidText)}
                                                    helperText={errors.username ? errors.username : null}
                                                    className="inputStyle"
                                                />

                                                <TextField
                                                    required
                                                    autoComplete='off'
                                                    inputProps={{
                                                        type: "password",
                                                        autoComplete: "new-password"
                                                    }}
                                                    label={trans.passwordLabel}
                                                    fullWidth
                                                    value={values.password || ""}
                                                    id={keys.password}
                                                    onChange={handleChange}
                                                    className="inputStyle"
                                                />

                                            </>}
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                        <Typography variant="overline">
                                            {trans.accessInfoUser}
                                        </Typography>
                                        <Grid>
                                            <FormControl style={{ width: "100%", marginTop: "1rem" }} className="inputStyle">
                                                <InputLabel>{trans.role}</InputLabel>
                                                <Select
                                                    value={values.roles[0] || "admin"}
                                                    id={keys.roles}
                                                    onChange={(e: React.ChangeEvent<any>) => {
                                                        setValues({
                                                            ...values,
                                                            roles: [e.target.value],
                                                        });
                                                    }}
                                                >
                                                    {roles.map(role =>
                                                        <MenuItem value={role} key={role}>{role}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* <Grid className="inputStyle inputStyle--clearPadding">
                                        <AutocompleteSelect
                                            getSuggestions={companySuggestions}
                                            label={trans.companyLabel}
                                            initialSelectedItem={user.}
                                            display={o => o ? o.name : ""}
                                            required={true}
                                            key="user-company"
                                            onSelect={(o) => {
                                                setValues({
                                                    ...values,
                                                    companyId: (o && o.id)!,
                                                });
                                        }}
                                    />
                                    </Grid> */}
                                    {values.roles[0] === "local" ?
                                    <UserLocationEdit
                                        id={p.match.params.id}
                                        setSaveLocations={fn => onSubmitHandlers.setSaveLocations = fn}
                                        hasLocation={fn => onSubmitHandlers.hasLocations = fn}
                                        error={locationsError}
                                    />:null}
                                    </Grid>
                                </Grid>
                             
                            </CardContent>
                        </Card >

                    </Form>
                );
            }}
        /></React.Fragment>;
});