import React, {
    useState,
    FunctionComponent,
    ChangeEvent
} from "react";
import {
    Grid,
    Link,
    Dialog,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem,
    DialogActions,
    Button,
    FormControl,
    InputLabel
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import * as adminService from "../../services/admin.service";
import * as placementService from "../../services/placement.service";
import { ArtItem as IArtItem } from "../../models/ArtItem";
import { Floor as IFloor } from "../../models/Floor";
import { Room as IRoom } from "../../models/Room";

import ArtItem from "./art-item";
import { useLoadData } from "../../store/utils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: "100%"
        },
        scrollableContainer: {
            minHeight: 500,
            maxHeight: 500,
            overflowY: "auto"
        }
    })
);

interface ArtItemPickerProps {
    placementId: number;
    items?: IArtItem[];
    locationId: number;
    onChange?: (id?: number) => void;
}

const ArtItemAssign: FunctionComponent<ArtItemPickerProps> = ({
    placementId,
    locationId,
    items = [],
    onChange
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState<IArtItem | undefined>();

    const [floors, setFloors] = useState<IFloor[]>([]);
    const [floorId, setFloorId] = useState<number>(0);
    const [rooms, setRooms] = useState<IRoom[]>([]);
    const [roomId, setRoomId] = useState<number>(0);

    useLoadData(async function getFloors() {
        const floorsResponse = await adminService.searchFloors({ locationIds: [locationId] });
        setFloors(floorsResponse.data);
    }, [locationId]);

    useLoadData(async function getRooms() {
        const response = await adminService.searchRooms({ floorIds: [floorId] });
        setRooms(response.data);
    }, [floorId]);

    const onAssignLocation = (id: number) => () => {
        const item = items.find(i => i.id === id);
        setCurrentItem(item);
        setOpen(true);
    };

    const onFloorChange = (
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        setFloorId(e.target.value as number);
    };

    const onRoomChange = (
        e: ChangeEvent<{ name?: string | undefined; value: unknown }>
    ) => {
        setRoomId(e.target.value as number);
    };

    const handleDialogClose = () => {
        setOpen(false);
        setFloorId(0);
    };

    const handleDialogSave = async () => {
        await placementService.assignNewLocation({
            placementId,
            artItemId: currentItem!.id,
            roomId
        });
        onChange && onChange(currentItem!.id);
        setCurrentItem(undefined);
        handleDialogClose();
    };

    const dialog = currentItem && (
        <Dialog open={open} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">
                {currentItem.name} - Assign location
            </DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="floor">Floor</InputLabel>
                    <Select
                        name="floor"
                        value={floorId}
                        onChange={onFloorChange}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value={0} disabled>
                            Choose floor
                        </MenuItem>
                        {floors.map(f => (
                            <MenuItem value={f.id} key={f.id}>{f.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="room">Room</InputLabel>
                    <Select
                        name="room"
                        value={roomId}
                        onChange={onRoomChange}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value={0} disabled>
                            Choose room
                        </MenuItem>
                        {rooms.map(r => (
                            <MenuItem value={r.id} key={r.id}> {r.name ? `${r.name}-${r.number}` : r.number}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleDialogSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {dialog}
            <Grid container direction="column">
                <Grid item className={classes.scrollableContainer}>
                    {items.map((item,index) => {
                        return (
                            <React.Fragment key={index}>
                                <ArtItem item={item} key={item.id + "-assignToLocation"}  />
                                <Link onClick={onAssignLocation(item.id)} key={item.id + index}>Assign</Link>
                            </React.Fragment>
                        );
                    })}
                </Grid>
            </Grid>
        </>
    );
};

export default ArtItemAssign;
