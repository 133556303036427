/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import * as admin from "../services/admin.service";
import * as media from "../services/media.service";
import { useLoadData, useTitle, withSuppressLoading } from "../store/utils";
import { Location } from "../models/Location";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { withRouter, RouteComponentProps } from "react-router";
import { Button, Card, CardContent, Typography, Grid, TextField, Dialog, CircularProgress } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageApi from "../components/ImageApi";
import { routeTo, urls } from "../navigation";
import { Formik, Form, FormikErrors } from "formik";
import { CreateLocationRequest } from "../models/CreateLocationRequest";
import { getKeys, confirmAndDelete, isValidText } from "../utils";
import { AllFloorsEdit } from "./floor-edit";
import { translations } from "../translates";
import { Prompt } from "react-router-dom";

const keys = getKeys<CreateLocationRequest>({
    allowedAsSource: 1,
    address: 1,
    city: 1,
    name: 1,
    picture: 1,
    postcode: 1,
    region: 1,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightIcon: {
            marginLeft: theme.spacing(1)
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        },
        progress: {
            position: "fixed",
            top: "50%",
            left: "50%",
            width: "100px!important",
            height: "100px!important"
        },
    })
);

export default withRouter(function (p: RouteComponentProps<{ name?: string, id?: string }>) {
    const setTitle = useTitle("");

    useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [modified, setModified] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [loaded, setLoaded] = React.useState(false);
    const [imageSource, setImageSource] = React.useState<string>();
    const [location, setLocation] = useState<Location>();
    const [onSubmitHandlers,] = useState({
        saveRooms: (id: number) => Promise.resolve(),
    });
    const [imgSrc, setImgSrc] = useState<string>("");
    const trans = translations.locationEdit;

    async function doSave(o: CreateLocationRequest) {
        const id = await onSubmit(o);
        await onSubmitHandlers.saveRooms(id);
        routeTo(p, urls.locationList);
    }
    useLoadData(async () => {
        if (p.match.params.id) {
            const result = await admin.getLocation(+p.match.params.id);
            setTitle(`Edit Location - ${result.data.name}`);
            setLocation(result.data);
        } else {
            setTitle("Add Location");
            setLocation({
                id: undefined!,
                postcode: "",
                city: "",
                name: "",
            });
        }
    });
    ///  edit location
    const classes = useStyles();
    let onSubmitPromise: Promise<number>;
    function onSubmit(o: CreateLocationRequest) {
        onSubmitPromise = (async function () {
            const id = p.match.params.id;
            if (id) {
                await admin.updateLocation(o, +id);
                return +id;
            } else {
                const r = await admin.addLocation(o);
                return r.data.id;
            }
        })();
        return onSubmitPromise;
    }
    // async function companySuggestions(key: string) {
    //     return (await admin.searchCompany({
    //         key,
    //         pageNumber: 0,
    //         pageSize: dropDownPageSize
    //     })).data.content;
    // }
    function validate(values: CreateLocationRequest, key: keyof CreateLocationRequest, errors: FormikErrors<CreateLocationRequest>, valid: (text: string) => boolean): boolean {
        const value = values[key];
        if (typeof value === "string") {
            if (!valid(value)) {
                errors[key] = "Please enter only characters";
                return true;
            }
        }
        return false;
    }

    const handleClose = () => {
        setImageSource("");
        setOpen(false);
    };
    const handleOpen = (imageId: string | undefined) => async () => {
        if (imageId) {
            try {
                setLoaded(false);
                if (!open) setOpen(true);
                const result = await withSuppressLoading(() => media.getImage(imageId, "Normal"));
                const blob = new Blob([result.data]);
                setImageSource(URL.createObjectURL(blob));
                setLoaded(true);
            } catch (e) {
                setOpen(false);
            }
        }
    };

    ///
    if (!location) {
        return <div></div>;
    }
    if (location == null) {
        return <div>
            Loading ...
        </div>;
    } else return (
        <>
            <React.Fragment>
                <Prompt
                    when={modified}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Dialog
                    PaperProps={{
                        style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        },
                    }}
                    fullScreen
                    open={open}
                    onClose={handleClose}>
                    <div className="PopupImageContainer">
                        <CircularProgress className={classes.progress} style={loaded ? { display: "none" } : { display: "block" }} />
                        <img onClick={handleClose} alt="" src={imageSource} />
                    </div>
                </Dialog>
                <Formik<CreateLocationRequest>
                    initialValues={location}
                    onSubmit={doSave}
                    render={({ values, handleChange, setValues, isValid, errors, dirty, isSubmitting }) => {
                        const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.files) {
                                const file = e.target.files[0];
                                const result = await media.uploadFile(file);
                                setValues({ ...values, picture: result.data.fileName });
                            }
                        };
                        const handleDownload = async (e: React.ChangeEvent<any>) => {
                            const download = document.createElement('a');
                            download.href = imgSrc;
                            download.download = `${values.name}.png`;
                            download.click();
                        };
                        if (!isSubmitting) {
                            setModified(dirty);
                        } else {
                            setModified(false);
                        }
                        return (
                            <Form className="ArtGallery-Form-EditLocation">
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2} justify="space-between">
                                            <Grid item xs={12} sm={3}>
                                                <Typography variant="h5" gutterBottom>
                                                    {p.match.params.id ? (<div>
                                                        {trans.modify} {location.name}</div>
                                                    ) : (
                                                        <div>{trans.createNewLocation}</div>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="edit-buttons">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                    // onClick={() => doSave(values)}
                                                    >
                                                        {trans.publish}
                                                    </Button>
                                                    <Button variant="outlined" color="primary" onClick={() => routeTo(p, urls.locationList)}>
                                                        {trans.discard}
                                                    </Button>
                                                    {!location.id ? <></> :
                                                        <Button
                                                            variant="outlined"
                                                            color="secondary"
                                                            onClick={confirmAndDelete(trans.confirmDelete, async () => {
                                                                await admin.deleteLocation(location.id);
                                                                routeTo(p, urls.locationList);
                                                            })}
                                                        >
                                                            {trans.delete}
                                                        </Button>}
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="center" spacing={8}>
                                            <Grid item xs={12} md={6} lg={3} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    Location Main Info
                                                </Typography>
                                                <TextField
                                                    required className={"inputStyle"}
                                                    label={trans.locationNameLabel}
                                                    fullWidth
                                                    value={values.name}
                                                    id={keys.name}
                                                    onChange={handleChange}
                                                    error={validate(values, "name", errors, isValidText)}
                                                    helperText={errors.name ? errors.name : null}
                                                />
                                                {/* <Grid className={"inputStyle inputStyle--clearPadding"}>
                                            <AutocompleteSelect
                                                getSuggestions={companySuggestions}
                                                label={trans.companyLabel}
                                                initialSelectedItem={location.company!}
                                                display={o => (o && o.name) || ""}
                                                required={true}
                                                onSelect={(o) => {
                                                    setValues({
                                                        ...values,
                                                        companyId: (o && o.id)!,
                                                    });
                                                }} />
                                        </Grid> */}
                                                {values.picture ? (
                                                    <Grid className={`inputStyle inputStyle--clearPadding inputStyle--marginTop2rem ${classes.pictureContainer}`}>
                                                        <ImageApi
                                                            src={values.picture}
                                                            imageType="Tile"
                                                            onSrcChange={src => {
                                                                setImgSrc(src);
                                                            }}
                                                        ></ImageApi>
                                                    </Grid>
                                                ) : (<></>)}
                                                <Grid container direction="row">
                                                    <Grid item>
                                                        <input
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                            id="raised-button-file"
                                                            onChange={handleCapture}
                                                            multiple
                                                            type="file"
                                                        />
                                                        <label htmlFor="raised-button-file">
                                                            <Button variant="contained" component="span">
                                                                {values.picture ? "Change Picture" : trans.uploadImage}
                                                                <CloudUploadIcon className={classes.rightIcon} />
                                                            </Button>
                                                        </label>
                                                    </Grid>
                                                    <div style={{ flexGrow: 1 }}></div>
                                                    {values.picture && <Grid item>
                                                        <Button variant="contained" component="span" onClick={handleDownload}>
                                                            Download Picture
                                                        </Button>

                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={3} xl={3} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    Location on map
                                                </Typography>
                                                <TextField className={"inputStyle inputStyle--clearPadding"}
                                                    label={trans.addressLabel}
                                                    fullWidth
                                                    value={values.address || ""}
                                                    id={keys.address}
                                                    onChange={handleChange}
                                                    error={validate(values, "address", errors, isValidText)}
                                                    helperText={errors.address ? errors.address : null}
                                                />
                                                <TextField className={"inputStyle inputStyle--clearPadding"}
                                                    required
                                                    label={trans.cityLabel}
                                                    fullWidth
                                                    value={values.city}
                                                    id={keys.city}
                                                    onChange={handleChange}
                                                    error={validate(values, "city", errors, isValidText)}
                                                    helperText={errors.city ? errors.city : null}
                                                />
                                                <TextField className={"inputStyle inputStyle--clearPadding"}
                                                    label={trans.regionLabel}
                                                    fullWidth
                                                    value={values.region || ""}
                                                    id={keys.region}
                                                    onChange={handleChange}
                                                    error={validate(values, "region", errors, isValidText)}
                                                    helperText={errors.region ? errors.region : null}
                                                />
                                                <TextField className={"inputStyle inputStyle--clearPadding"}
                                                    label={trans.postalCodeLabel}
                                                    fullWidth
                                                    type="number"
                                                    value={values.postcode}
                                                    id={keys.postcode}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={6} xl={6} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    Floors Builder
                                                </Typography>
                                                <Grid item className={"inputStyle inputStyle--clearPadding"}>
                                                    <AllFloorsEdit locationId={location.id} setSaveRooms={fn => onSubmitHandlers.saveRooms = fn} openImage={handleOpen} ></AllFloorsEdit>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Form>
                        );
                    }}
                />
            </React.Fragment>
        </>);
});
