import React, { useState, useRef, useContext } from "react";
import { withRouter } from "react-router";
import MaterialTable, { Options, Action } from "material-table";
import * as admin from "../services/admin.service";
import { Location } from "../models/Location";
import { loadItems, useTitle } from "../store/utils";
import { routeTo, urls } from "../navigation";
import { IconButton, Icon } from "@material-ui/core";
import { translations } from "../translates";
import { IsAdmin } from "../components/Roles";
import { useSelector } from "../store";
import { LocationSearchRequest } from "../models/LocationSearchRequest";
import { withoutSorting } from "../components/edit-delete-column";
import { PagingContext } from "../contexts";

export default withRouter(function (p) {
    useTitle("Locations");
    const roles = useSelector(app => app.loginUser.roles);
    const [pagingContext, setPagingContext] = useContext(PagingContext);
    const isAdmin = roles![0] === "admin" || roles![0] === "global";
    const actions: (Action<Location> | ((rowData: Location) => Action<Location>))[] | undefined = isAdmin ? [{
        icon: "delete",
        tooltip: "Delete",
        onClick: async (event: any, rowData) => {
            const confirmation = window.confirm("Are you sure you want to delete the selected items?");
            if (confirmation && Array.isArray(rowData)) {
                for (const i of rowData) {
                    await admin.deleteLocation(i.id);
                }
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }
        }
    }, {
        icon: "add_circle",
        tooltip: "Add Location",
        isFreeAction: true,
        onClick: () => routeTo(p, urls.locationAdd),
    }] : [];
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: isAdmin,
        searchFieldStyle: {
            display: "none",
        },
        pageSize: pagingContext.locationPage.pageSize,
        pageSizeOptions: [5, 10, 20, 50, 100],
    });
    const trans = translations.locationList;
    const tableRef = useRef<any>(null);
    return <div className="ArtGallery-List-LocationsList table-list-pagination-bottom">
        <MaterialTable<Location>
            options={pagination}
            tableRef={tableRef}
            columns={[
                ...withoutSorting<Location>({ title: trans.name, field: "name" },
                    { title: trans.city, field: "city" },
                    { title: trans.region, field: "region" },
                    { title: trans.adress, field: "address" }),
                {
                    title: "",
                    cellStyle: { textAlign: "right" },
                    render: r => <>
                        <IsAdmin>
                            <IconButton onClick={e => routeTo(p, urls.locationView, { id: r.id })} title="View location"><Icon>visibility</Icon></IconButton>
                            <IconButton onClick={e => routeTo(p, urls.locationEdit, { name: r.name, id: r.id })} title="Edit location"><Icon>edit</Icon></IconButton>
                            {isAdmin ? <>
                                <IconButton title="View location history" onClick={e => routeTo(p, urls.history, { defaultEntity: "Location", linkId: r.id })}><Icon>history</Icon></IconButton>
                            </> : <div />}
                            <IconButton onClick={async e => {
                                const confirmation = window.confirm(translations.confirm(r.name));
                                if (confirmation) {
                                    await admin.deleteLocation(r.id);
                                }
                                if (tableRef.current) {
                                    tableRef.current.onQueryChange();
                                }
                            }} title="Delete location"><Icon>delete</Icon></IconButton>
                        </IsAdmin>
                    </>
                },
            ]}
            onChangePage={e => {
                setPagingContext({ ...pagingContext, locationPage: { ...pagingContext.locationPage, page: e } });
                if (tableRef.current) {
                    tableRef.current.onQueryChange();
                }
            }}
            onChangeRowsPerPage={e => {
                setPagingContext({ ...pagingContext, locationPage: { page: 0, pageSize: e } });
                setPagination({ ...pagination, pageSize: e })
            }}
            data={e => loadItems((q: LocationSearchRequest) => admin.searchLocations({
                ...q,
                onlyMyLocations: true,
            }))({ ...e, page: pagingContext.locationPage.page })}
            title={trans.title}
            actions={actions}
        />
    </div>;
});