import React, { useState, useEffect } from "react";
import Downshift, { GetItemPropsOptions, DownshiftState, StateChangeOptions } from "downshift";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem, { MenuItemProps } from "@material-ui/core/MenuItem";
import { withSuppressLoading, useLoadData } from "../store/utils";

type RenderInputProps = TextFieldProps & {
    classes: ReturnType<typeof useStyles>;
    ref?: React.Ref<HTMLDivElement>;
    name?: string;
    required: boolean
};

interface RenderSuggestionProps<T> {
    highlightedIndex: number | null;
    index: number;
    itemProps: MenuItemProps<"div", { button?: never }>;
    selectedItem: T;
    suggestion: T;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        container: {
            // zIndex:999, // zindex used to caused problems of overlapping with other elements
            flexGrow: 1,
            position: "relative",
        },
        paper: {
            overflow: "auto",
            maxHeight: "250px",
            width: "120%",
            position: "absolute",
            zIndex: 2,
            marginTop: theme.spacing(1),
            left: 0,
            right: 0,
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
        },
        inputRoot: {
            flexWrap: "wrap",
        },
        inputInput: {
            width: "auto",
            flexGrow: 1,
        },
        divider: {
            height: theme.spacing(2),
        },
    }),
);

type PromiseArrayReturnType<T extends (searchTerm: string) => Promise<Array<{ name: string }>>> =
    T extends (searchTerm: string) => Promise<infer Item> ? Item : never;


interface SuggestionProps<T> {
    highlightedIndex: number | null;
    selectedItem: T;
}
function AutocompleteSelectMenu<T>(p: {
    searchedItem: string,
    fn: (searchTerm: string) => Promise<Array<T>>,
    display: (o: T) => string;
    suggestionProps: SuggestionProps<T>,
    getItemProps: (options: GetItemPropsOptions<any>) => any
}) {
    const [suggestions, setSuggestions] = useState<T[]>([]);
    const { highlightedIndex, selectedItem } = p.suggestionProps;
    const classes = useStyles();

    function renderSuggestion(suggestionProps: RenderSuggestionProps<T>) {
        const { suggestion, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        const isSelected = selectedItem === suggestion;
        return (
            <MenuItem
                {...itemProps}
                key={p.display(suggestion)}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: isSelected ? 500 : 400,
                }}
            >
                {p.display(suggestion)}
            </MenuItem>
        );
    }

    useLoadData(async () => {
        const result = await withSuppressLoading(() => p.fn(p.searchedItem));
        setSuggestions(result);
    }, [p.searchedItem]);
    return (
        <Paper className={classes.paper} square >
            {suggestions.map((suggestion, index) =>
                renderSuggestion({
                    suggestion,
                    index,
                    itemProps: p.getItemProps({
                        item: suggestion,
                        index,
                        key: `${p.display(suggestion)} - ${index}`
                    }),
                    highlightedIndex,
                    selectedItem,
                }),
            )}
        </Paper>
    );
}

export default function AutocompleteSelect<T extends (searchTerm: string) => Promise<Array<any>>>(props: {
    getSuggestions: T;
    initialSelectedItem?: PromiseArrayReturnType<T>[number];
    selectedItem?: PromiseArrayReturnType<T>[number];
    display: (o: PromiseArrayReturnType<T>[number]) => string;
    onSelect: (s: undefined | PromiseArrayReturnType<T>[number]) => void;
    inputChanged?: (s: undefined | PromiseArrayReturnType<T>[number]) => void;
    name?: string;
    label: string;
    disabled?: boolean;
    required: boolean;
    error?: {
        expression: boolean,
        helperText: string;
    },
    placeholder?: string;
    stateReducer?: (
        state: DownshiftState<PromiseArrayReturnType<T>[number]>,
        changes: StateChangeOptions<PromiseArrayReturnType<T>[number]>
    ) => Partial<StateChangeOptions<PromiseArrayReturnType<T>[number]>>;
}) {
    const classes = useStyles();
    const [item, setItem] = useState<PromiseArrayReturnType<T>[number]>(props.selectedItem);
    useEffect(() => {
        setItem(props.selectedItem);
    }, [props.selectedItem]);
    function renderInput(p: RenderInputProps) {
        p.inputProps!.value = p.inputProps!.value || "";
        const { InputProps, classes, ref, ...other } = p;
        return (
            <TextField
                InputProps={{
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    ...InputProps,
                }}
                {...other}
            />
        );
    }
    const handleSelect = (selected: any) => {
        props.onSelect(selected);
    };
    return (
        <div className={classes.root}>
            <Downshift
                initialSelectedItem={props.initialSelectedItem ? props.initialSelectedItem : ""}
                selectedItem={item}
                stateReducer={props.stateReducer}
                onChange={handleSelect} itemToString={props.display}>
                {({
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    selectedItem,
                    openMenu,
                    clearSelection,
                }) => {
                    const { onBlur, onFocus, ...inputProps } = getInputProps({
                        onBlur: "",
                        onFocus: openMenu,
                        placeholder: props.placeholder || "Make a selection",
                        onChange: (e: any) => {
                            if (e.target.value === "") {
                                clearSelection();
                            }

                        },
                    });
                    return (
                        <div className={classes.container}>
                            {renderInput({
                                fullWidth: true,
                                classes,
                                label: props.label,
                                InputLabelProps: getLabelProps({ shrink: true } as any),
                                InputProps: { onBlur, onFocus },
                                inputProps,
                                disabled: props.disabled,
                                error: props.error ? props.error.expression : false,
                                helperText: props.error && props.error.expression ? props.error.helperText : "",
                                required: props.required,

                            })}
                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <AutocompleteSelectMenu
                                        searchedItem={inputValue!}
                                        fn={props.getSuggestions}
                                        display={props.display}
                                        suggestionProps={{ highlightedIndex, selectedItem }}
                                        getItemProps={getItemProps} />
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        </div>
    );
}