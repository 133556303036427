import * as displayMode from "./display-modes";
import * as user from "./user";
import * as location from "./location";
import * as search from "./searchText";
import * as artItemsIds from "./artItemsIds";
import * as artItemsFilter from "./artItemsFilter";
import * as selectedArtItems from "./selectedArtItems";
import { StateFromMap, ActionFromMap } from "../utils";
import { combineReducers } from "redux";


const reducers = {
    ...displayMode,
    ...user,
    ...location,
    ...search,
    ...artItemsIds,
    ...artItemsFilter,
    ...selectedArtItems,
};

export interface AppState extends StateFromMap<typeof reducers> {}
export type AppActions =  ActionFromMap<typeof reducers>;

export const appReducer = combineReducers<AppState, AppActions>(reducers as any);