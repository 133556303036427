import React, { useState, SetStateAction, ChangeEvent } from "react";
import { Tabs, Tab, Button, IconButton, Icon, TextField, Grid, Typography, Theme, makeStyles, createStyles } from "@material-ui/core";
import { useLoadData } from "../store/utils";
import * as admin from "../services/admin.service";
import * as media from "../services/media.service";
import ImageApi from "../components/ImageApi";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Room } from "../models/Room";
import { FloorWithRooms, RoomWithDeleted, saveAllFloors } from "../services/service.helpers";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightIcon: {
            marginLeft: theme.spacing(1)
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        }
    })
);

function createArraySetter<T>(index: number, setter: (v: SetStateAction<T[] | undefined>) => void) {
    return function (newValue: SetStateAction<T>) {
        setter(original => {
            const clone = [...original!];
            clone[index] = newValue instanceof Function ? newValue(clone[index]) : newValue;
            return clone;
        });
    };
}
export function AllFloorsEdit(p: { locationId: number, openImage: (imageId: string | undefined) => () => Promise<void>, setSaveRooms: (fn: (id: number) => Promise<void>) => void }) {
    const [floors, setFloors] = useState<FloorWithRooms[] | undefined>();
    const [currentFloor, setCurrentFloor] = useState<number>();
    const [imgSrc, setImgSrc] = useState<string>("");
    // const classes = useStyles();
    useLoadData(async () => {
        if (p.locationId) {
            const result = await admin.searchFloors({
                locationIds: [p.locationId]
            });
            setFloors(result.data);
            setCurrentFloor(result.data.length ? 0 : undefined);
        } else {
            setFloors([{ id: 0, name: "New Floor", locationId: p.locationId }]);
            setCurrentFloor(0);
        }
    }, [p.locationId]);

    p.setSaveRooms(async function (id: number) {
        await saveAllFloors(id, floors!);
    });
    if (typeof currentFloor === "undefined") {
        return <div></div>;
    }
    function validateCurrentFloor(i: number): boolean {
        let ok = true;
        if (floors![i].rooms) {
            for (const room of floors![i].rooms!) {
                if (!room.isDeleted) {
                    if (!room.number) {
                        ok = false;
                    }
                }
            }
        }
        if (!ok) {
            window.alert("You have unfinished fields in this floor ,submitting the form will not save this floor. Please fill the required fields");
        }
        return ok;
    }
    if (floors) {
        const addFloor = () => {
            setFloors([...floors!, { id: 0, name: "New Floor", locationId: p.locationId, rooms: [], isDeleted: false }]);
            setCurrentFloor(floors!.length);
        };
        const removeFloor = () => {
            floors[currentFloor].isDeleted = true;
            // const newFloors = floors.filter((floor, i) => i !== currentFloor);
            // setFloors([...newFloors]);
            const f = floors.map(o => o.isDeleted).indexOf(false);
            const u = floors.map(o => o.isDeleted).indexOf(undefined);
            if (u > -1) {
                setCurrentFloor(u);
            } else {
                setCurrentFloor(f);
            }
        };

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 96px" }}>
                        <Tabs
                            textColor="secondary"
                            variant="scrollable"
                            value={currentFloor}
                            aria-label="nav tabs example"
                            scrollButtons="auto"
                        >
                            {floors.map((o, i) => { return !o.isDeleted && <Tab key={i} label={o.name} value={i} onClick={() => { if (validateCurrentFloor(currentFloor)) { setCurrentFloor(i); } }}></Tab>; })}
                        </Tabs>
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            {floors.filter(o => o.isDeleted !== true).length > 1 ? <IconButton aria-label="remove" onClick={removeFloor} ><Icon>remove_circle</Icon></IconButton> : <div />}
                            <IconButton aria-label="add" onClick={addFloor}><Icon>add_circle</Icon></IconButton>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12}>
                    {currentFloor == null ? <></> :
                        <FloorEdit floor={floors[currentFloor]} setFloor={createArraySetter(currentFloor, setFloors)} openImage={p.openImage}></FloorEdit>}
                </Grid>
            </Grid>
        );
    } else {
        return <div></div>;
    }
}

export function FloorEdit({ floor, setFloor, openImage }: { floor: FloorWithRooms, openImage: (imageId: string | undefined) => () => Promise<void>, setFloor: (value: SetStateAction<FloorWithRooms>) => void }) {
    const classes = useStyles();
    const [imgSrc, setImgSrc] = useState<string>("");
    const handleCapture = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const file = e.target.files[0];
            const result = await media.uploadFile(file);
            setFloor({ ...floor, picture: result.data.fileName });
        }
    };
    const handleDownload = async (e: React.ChangeEvent<any>) => {
        const download = document.createElement('a');
        download.href = imgSrc;
        download.download = `${floor.name}.png`;
        download.click();
    };
    useLoadData(async () => {
        if (floor.rooms) return;
        const rooms = await admin.searchRooms({ floorIds: [floor.id] });
        if (floor.rooms) return;
        if (rooms.data.length) {
            setFloor({ ...floor, rooms: rooms.data });
        } else {
            setRooms([{ floorId: 0, id: 0, name: "", number: "" }]);

        }
    }, [floor.id]);

    const setRooms = (r: SetStateAction<Room[] | undefined>) => setFloor(floor => ({
        ...floor,
        rooms: r instanceof Function ? r(floor.rooms!) : r
    }));

    return <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
            <TextField className="inputStyle"
                value={floor.name}
                label={"Floor Name"}
                fullWidth
                onChange={e => setFloor({ ...floor, name: e.target.value })}>
            </TextField>
            {floor.picture && (
                <Grid item className={`inputStyle inputStyle--clearPadding ${classes.pictureContainer}`}>
                    <ImageApi src={floor.picture} imageType="Normal" onClick={openImage(floor.picture)} onSrcChange={src => {
                        setImgSrc(src);
                    }}></ImageApi>
                </Grid>
            )
            }
            <Grid container direction="row">
                <Grid item></Grid>
                <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file-floor"
                    onChange={handleCapture}
                    multiple
                    type="file"
                />
                <label htmlFor="raised-button-file-floor">
                    <Button variant="contained" component="span">Upload Picture<CloudUploadIcon className={classes.rightIcon} /></Button>
                </label>
                <div style={{ flexGrow: 1 }}></div>
                {floor.picture && <Grid item>
                    <Button variant="contained" component="span" onClick={handleDownload}>
                        Download Picture
                    </Button>

                </Grid>}
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6} >
            {!floor.rooms ? <></> : <AllRoomsEdit rooms={floor.rooms} setRooms={setRooms}></AllRoomsEdit>}
        </Grid>
    </Grid>;
}

function AllRoomsEdit({ rooms, setRooms }: { rooms: RoomWithDeleted[], setRooms: (rooms: SetStateAction<Room[] | undefined>) => void }) {
    return <>
        <div style={{ display: "flex", justifyItems: "left", alignItems: "center", marginBottom: "1rem" }}>
            <Typography variant="subtitle2" style={{ marginRight: "0.25rem" }}>Rooms on this floor*</Typography>
            <IconButton onClick={e => setRooms([...rooms, { floorId: 0, id: 0, name: "", number: "" }])}><Icon>add_circle</Icon></IconButton>
        </div>

        {rooms.length === 0
            ? <tr><td colSpan={4}>Add the rooms on this floor.</td></tr>
            : rooms
                .map((room, index) => [room, index] as const)
                .filter(([r]) => !r.isDeleted)
                .map(([r, i]) => <RoomEdit key={i} index={i} room={r} setRoom={createArraySetter(i, setRooms)} nrRooms={rooms.filter(e => e.isDeleted !== true).length}></RoomEdit>)}
    </>;
}


function RoomEdit({ index, room, setRoom, nrRooms }: { index: number, room: RoomWithDeleted, setRoom: (rooms: SetStateAction<RoomWithDeleted>) => void, nrRooms: number }) {
    const setValue = (key: keyof Room) => (e: ChangeEvent<HTMLInputElement>) => {
        if (e && e.target) {
            const value = e.target.value;
            setRoom(room => ({ ...room, [key]: value }));
        }
    };
    return <>
        <div style={{ display: "flex", justifyItems: "left", alignItems: "center", marginBottom: "1rem" }}>
            <TextField required fullWidth value={room.number} onChange={setValue("number")}></TextField>
            {nrRooms > 1 ?
                <IconButton onClick={e => setRoom(room => ({ ...room, isDeleted: true }))}><Icon>remove_circle</Icon></IconButton> : <></>}
        </div>
    </>;
}
