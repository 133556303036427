import * as actions from "../actions/selectedArtItems";
import { ActionsOf } from "../utils";
import { init } from "./utils";
import { ArtItem } from "../../models/ArtItem";

export function selectedArtItems(
    state = init<ArtItem[]>([]),
    action: ActionsOf<typeof actions>
): typeof state {
    switch (action.type) {
    case "SET_SELECTED_ART_ITEMS":
        return action.obj;
    default:
        return state;
    }
}
