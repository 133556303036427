/* eslint-disable indent */
import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import {
    Typography,
    Grid,
    TextField,
    makeStyles,
    Theme,
    createStyles
} from "@material-ui/core";
import { useLoadData, useTitle } from "../store/utils";
import * as admin from "../services/admin.service";
import * as media from "../services/media.service";
import { Artist } from "../models/Artist";
import AutocompleteSelect from "../components/autocomplete-selector";
import { Button, Card, CardContent } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { routeTo, urls } from "../navigation";
import { useSelector } from "../store";
import { Formik, Form } from "formik";
import { CreateArtistRequest } from "../models/CreateArtistRequest";
import { getKeys, confirmAndDelete } from "../utils";
import {
    TextAutoCompleteSelector,
    handleTextAutoCompleteChange
} from "../components/autocomplete-selector-text";
import ImageApi from "../components/ImageApi";
import { translations } from "../translates";
import { Prompt } from "react-router-dom";
import { withSuppressLoading } from './../store/utils';

const keys = getKeys<CreateArtistRequest>({
    firstName: 1,
    lastName: 1,
    countryId: 1,
    description: 1,
    kind: 1,
    picture: 1,
    slug: 1
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rightIcon: {
            marginLeft: theme.spacing(1)
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        }
    })
);

export default withRouter(function (p: RouteComponentProps<{ id?: string }>) {
    const classes = useStyles();
    const setTitle = useTitle("");
    const displayModes = useSelector(s => s.displayMode);

    React.useEffect(() => {
        if (modified) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = null;
        }
    });
    const [modified, setModified] = useState<boolean>(false);
    const [artist, setArtist] = useState<Omit<Artist, "country " | "id">>();
    const artistId = !p.match.params.id ? undefined : (+p.match.params.id);
    const trans = translations.artistEdit;
    const [imgSrc, setImgSrc] = useState<string>("");

    async function clonePicture(picture?: string) {
        if (picture && imgSrc) {

            const upload = await withSuppressLoading(() => media.getImage(picture, "Normal"));
            const file = new File([upload.data], "img");
            const result = await withSuppressLoading(() => media.uploadFile(file));
            return result.data.fileName;
        }
    }

    useLoadData(async () => {
        if (artistId) {
            const result = await admin.getArtist(artistId);
            setTitle(`Edit Artist - ${result.data.firstName + result.data.lastName}`);
            setArtist(result.data);
        } else {
            setTitle("Add Artist");
            setArtist({
                countryId: 0,
                description: "",
                firstName: "",
                lastName: "",
                kind: "",
                picture: "",
                slug: ""
            });
        }
    });

    async function onSubmit(o: CreateArtistRequest) {
        if (p.match.params.id) {
            await admin.updateArtist(+p.match.params.id, o);
        } else {
            await admin.createArtist(o);
        }
        routeTo(p, urls.artistList, displayModes);
    }

    async function countrySuggestions(key: string) {
        return (await admin.searchCountries({
            key,
            unpaged: true
        })).data.content;
    }

    if (artist == null) {
        return <div></div>;
    } else {
        return (
            <React.Fragment>
                <Prompt
                    when={modified}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <Formik<CreateArtistRequest>
                    initialValues={artist}
                    onSubmit={onSubmit}
                    render={({ values, handleChange, isValid, setValues, dirty, isSubmitting }) => {
                        const handleCapture = async (
                            e: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            if (e.target.files) {
                                const file = e.target.files[0];
                                const result = await media.uploadFile(file);
                                setValues({ ...values, picture: result.data.fileName });
                            }
                        };
                        const handleDownload = async (e: React.ChangeEvent<any>) => {
                            const download = document.createElement('a');
                            download.href = imgSrc;
                            download.download = `${values.firstName + ' ' + values.lastName}.png`;
                            download.click();
                        };
                        if (!isSubmitting) {
                            setModified(dirty);
                        } else {
                            setModified(false);
                        }
                        return (
                            <Form className="ArtGallery-Form-ArtistEdit">
                                <Card >
                                    <CardContent>
                                        <Grid container spacing={3} style={{ justifyContent: "space-between" }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h5" gutterBottom>
                                                    {p.match.params.id ? (
                                                        <div>
                                                            {trans.editing} {artist.firstName + artist.lastName}
                                                        </div>
                                                    ) : (
                                                        <div>{trans.addNew}</div>
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <div className="edit-buttons">
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        {trans.publish}
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() =>
                                                            routeTo(p, urls.artistsView, [], displayModes)
                                                        }
                                                    >
                                                        {trans.discard}
                                                    </Button>
                                                    {!artistId ? <></> :
                                                        <Button
                                                            variant="outlined" color="secondary"
                                                            onClick={confirmAndDelete(trans.confirmDelete, async () => {
                                                                await admin.deleteArtist(artistId!);
                                                                routeTo(p, urls.artistList);
                                                            })}
                                                        >
                                                            {trans.delete}
                                                        </Button>}
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" spacing={8}>
                                            <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    {trans.pictureLabel}
                                                </Typography>
                                                {values.picture ? (
                                                    <Grid className={`inputStyle inputStyle--clearPadding inputStyle--marginTop2rem ${classes.pictureContainer}`}>
                                                        <ImageApi
                                                            src={values.picture}
                                                            imageType="Tile"
                                                            onSrcChange={src => {
                                                                setImgSrc(src);
                                                            }}
                                                        ></ImageApi>
                                                    </Grid>
                                                ) : (<></>)}
                                                <Grid container direction="row">
                                                    <Grid item>
                                                        <input
                                                            accept="image/*"
                                                            style={{ display: "none" }}
                                                            id="raised-button-file"
                                                            onChange={handleCapture}
                                                            type="file"
                                                            className="inputStyle"
                                                        />
                                                        <label htmlFor="raised-button-file">
                                                            <Button variant="contained" component="span">
                                                                {values.picture ? "Change Picture" : trans.uploadImage}
                                                                <CloudUploadIcon className={classes.rightIcon} />
                                                            </Button>
                                                        </label>

                                                    </Grid>
                                                    <div style={{ flexGrow: 1 }}></div>
                                                    {values.picture && <Grid item>
                                                        <Button variant="contained" component="span" onClick={handleDownload}>
                                                            Download Picture
                                                        </Button>

                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    {trans.infoLabel}
                                                </Typography>
                                                <Grid item>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        label={trans.firstName}
                                                        value={values.firstName}
                                                        id={keys.firstName}
                                                        onChange={handleChange}
                                                        className="inputStyle"
                                                    />
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        label={trans.lastName}
                                                        value={values.lastName}
                                                        id={keys.lastName}
                                                        onChange={handleChange}
                                                        className="inputStylw"
                                                    />
                                                </Grid>
                                                <Grid item className="inputStyle inputStyle--clearPadding">
                                                    <TextAutoCompleteSelector
                                                        required={false}
                                                        label={trans.kind}
                                                        initialSelectedItem={values.kind}
                                                        getSuggestions={text =>
                                                            admin.searchAutoCompleteData({
                                                                text,
                                                                dataType: "ARTIST_KIND"
                                                            })
                                                        }
                                                        onSelect={handleTextAutoCompleteChange(
                                                            "kind",
                                                            values,
                                                            setValues
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item className="inputStyle inputStyle--clearPadding inputStyle--marginTop2rem">
                                                    <AutocompleteSelect
                                                        getSuggestions={countrySuggestions}
                                                        label={trans.country}
                                                        required={true}
                                                        display={o => o && o.name}
                                                        initialSelectedItem={artist.country}
                                                        onSelect={o => {
                                                            setValues({
                                                                ...values,
                                                                countryId: (o && o.id)!
                                                            });
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4} style={{ backgroundColor: "" }} className="inputBoxStyle">
                                                <Typography variant="overline">
                                                    {trans.descriptionLabel}
                                                </Typography>
                                                <Grid item xs={12} className="inputStyle inputStyle--clearPadding">
                                                    <TextField
                                                        variant="outlined"
                                                        label={trans.description}
                                                        multiline
                                                        fullWidth
                                                        rows="13"
                                                        rowsMax="50"
                                                        value={values.description || ""}
                                                        id={keys.description}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Form>
                        );
                    }}
                />
            </React.Fragment>
        );
    }
});
