import { login } from "../services/auth.service";
import { AuthRequest } from "../models/AuthRequest";
import { headers } from "../services/config";

export function delayedLoad<T>(data: T) {
    return new Promise<T>(r => setTimeout(() => r(data), 2000));
}
export type PagedFilter<T> = {
    startPage: number,
    pageSize: number
    filter?: T
}
export async function userLogin(options: AuthRequest) {
    try {
        const result = (await login(options)).data;
        Object.assign(headers, { Authorization: `Bearer ${result.token}` });
        return { username: options.username, token: result.token as string, roles: result.role as Array<"admin"> };
    } catch (e) {
        throw new Error(e);
    }
}
