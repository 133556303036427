import React, { useState, useContext } from "react";
import "./art-item-list.css";
import { ArtItem } from "../models/ArtItem";
import { withRouter } from "react-router";
import ImageApi from "../components/ImageApi";
import { useLoadData, withSuppressLoading, useTitle } from "../store/utils";
import { searchArtItems } from "../services/visitor.service";
import { Grid, Card, makeStyles, CardHeader, CardMedia, CardContent, CardActions, IconButton, Icon } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import * as media from "../services/media.service";
import { routeTo, urls } from "../navigation";
import { useSelector, useDispatch } from "../store";
import { PaginationContext } from "../contexts";
import InfiniteScroll from "react-infinite-scroll-component";
import { setArtItemsIds } from "../store/actions/artItemsIds";
import { ArtItemFilter, ArtItemFilterRequest, objectKeyLenghtGT0 } from "./art-item-location-filter";

const useStyles = makeStyles(theme => ({
    footer: {
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 10000,
        paddingRight: "1rem",
        "@media screen and (max-width: 600px)": {
            bottom: 48
        }
    },
    insideFooter: {
        backgrounColor: "#e4e4e4",
        borderTop: "1px solid #bebebe",
        "& div": {
            padding: "0 1rem",
            "@media screen and (max-width: 600px)": {
                padding: 0
            }
        }
    },
    card: {
        "& :hover": {
            cursor: "pointer"
        },
        "& div.MuiCardImage": {
            transition: "transform 0.5s ease",
            transform: "scale(1)",
            "& :hover": {
                transform: "scale(1.035)",
                transition: "transform 0.5s ease",
            }
        }
    },
    cardHeader: {
        marginBottom: 8,
        filter: "drop-shadow(2px 2px 3px black)",
        "& div span": {
            lineHeight: "1.6rem",
        },
    },
    secondaryInfo: {
        filter: "drop-shadow(2px 2px 5px black)"
    },
    editbuttonsContainer: {
        "& button": {
            backgroundColor: "rgba(0, 0, 0, 0.15)"
        },
        "& button:hover": {
            backgroundColor: "rgba(12, 105, 177, 0.85)"
        },
        "& button > span": {
            padding: 6,
            "& span": {
                // filter: "drop-shadow(0 0 6px black)"
            }
        }
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    avatar: {
        backgroundColor: red[500],
    },
    progress: {
        position: "fixed",
        top: "50%",
        left: "50%",
        width: "100px!important",
        height: "100px!important"
    },
}));

export function CardMediaApi(p: React.ComponentProps<typeof CardMedia> & { imageType: "Normal" | "Thumbnail" }) {
    const [image, setImage] = useState<string>("");
    useLoadData(async () => {
        const result = await withSuppressLoading(() => media.getImage(p.image!, p.imageType));
        const myBlob = new Blob([result.data]);
        setImage(URL.createObjectURL(myBlob));
    }, [p.src]);

    return <CardMedia {...p} image={image}></CardMedia>;
}

export const ArtItemTiles = withRouter(function (p) {
    useTitle("Art items");
    const classes = useStyles();
    const dispatch = useDispatch();
    const [pageSize,] = useContext(PaginationContext);
    const [artItems, setArtItems] = useState<{ data?: ArtItem[], page: number, total: number }>({
        page: 0,
        total: 0,
    });

    const locationFilters = useSelector(e => e.artItemsFilter);
    // const [locationFilters, setLocationFilters] = useState<IArtItemFilter>();
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";
    const isVisitor = user.roles![0] === "visitor";
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const loader = <div className="loader">Loading ...</div>;


    function loadScroll() {
        setLoading(!loading);
    }

    async function loadArtItems(q: { search?: string, page: number, pageSize: number }) {
        const filters: ArtItemFilterRequest = {
            locationIdList: objectKeyLenghtGT0(locationFilters.location) ? locationFilters.location!.map(e => e.id) : [],
            floorIdList: objectKeyLenghtGT0(locationFilters.floor) ? locationFilters.floor!.map(e => e.id) : [],
            roomIdList: objectKeyLenghtGT0(locationFilters.room) ? locationFilters.room!.map(e => e.id) : [],
            artistIdList: objectKeyLenghtGT0(locationFilters.artist) ? locationFilters.artist!.map(e => e.id) : [],
            techniqueList: locationFilters.technique && locationFilters.technique?.length ? locationFilters.technique.map(e => e.value) : [],
            pictureQuality: locationFilters.pictureQuality,
        };
        const result = await searchArtItems({
            ...filters,
            key: q.search || "",
            pageNumber: q.page,
            pageSize: q.pageSize,
        });
        setPage(page + 1);
        if (result.data.content.length < pageSize.tilePageSize) {
            setHasMore(false);
        }
        dispatch(setArtItemsIds({ q, ids: artItems.data ? artItems.data.concat(result.data.content).map(e => e.id) : result.data.content.map(e => e.id) }));
        setArtItems({
            ...artItems,
            data: artItems.data ? artItems.data.concat(result.data.content) : result.data.content,
            page: q.page,
            total: result.data.totalElements,
        });
    }
    useLoadData(() => {
        return loadArtItems({
            page: page,
            pageSize: pageSize.tilePageSize
        });
    }, [loading]);

    if (artItems.data != null) {
        return <InfiniteScroll
            dataLength={artItems.data.length}
            next={loadScroll}
            hasMore={hasMore}
            style={{ overflow: "none" }}
            loader={loader}
            endMessage={<p style={{color: "gray", padding: "20px 0", margin: 0}}>No more items</p>}>
            <div className="MuiTileGrid">
                {user.roles![0] !== "visitor" && <ArtItemFilter onChange={e => e} onSearch={() => {
                    setPage(0);
                    setArtItems({
                        data: [],
                        page: 0,
                        total: 0
                    });
                    loadScroll();
                }} />}
                <Grid container spacing={2}>
                    {artItems.data.map(a =>
                        <Grid xs={12} sm={6} md={4} lg={3} item key={a.id}>
                            <Card className={classes.card}>
                                <CardHeader className={classes.cardHeader} title={a.name}></CardHeader>
                                <div className="MuiCardImage">
                                    {!a.picture ?
                                        <img src="/error_loading.png" alt="" /> :
                                        <ImageApi src={a.picture} imageType="Tile" onClick={() => routeTo(p, urls.artItemView, { id: a.id, name: a.name })} /*onClick={handleOpen(a.picture)}*/ />
                                    }
                                </div>
                                {isVisitor ? <></> : <>
                                    {a.location ?
                                        (a.status === "BORROWED" ? <div className="MuiCardLocation Borrowed">BORROWED</div> : <div title={a.location.name} className="MuiCardLocation">
                                            {a.location.name.length > 20 ? `${a.location.name.slice(0, 20)}...` : a.location.name}</div>)
                                        : ((<div className="MuiCardLocation GrayedOut">UNASSIGNED</div>))
                                    }
                                </>}
                                <div className="MuiCardBackground" onClick={() => routeTo(p, urls.artItemView, { id: a.id, name: a.name })} /*onClick={handleOpen(a.picture)}*/ />
                                <CardContent className={classes.secondaryInfo}>{a.artist && a.artist.firstName + " " + a.artist.lastName}</CardContent>
                                <CardActions className={classes.editbuttonsContainer}>
                                    {isAdmin || (a.owner && a.owner!.name === user.username) ? <>
                                        <IconButton title="Edit item" onClick={e => routeTo(p, urls.artItemEdit, { name: a.name, id: a.id })}><Icon>edit</Icon></IconButton>
                                        {/* <IconButton onClick={async e => {
                                        const confirmation = window.confirm(`Are you sure you want to delete ${a.name}?`);
                                        if (confirmation) {
                                            await deleteArtItem(a.id!);
                                            setArtItems({
                                                ...artItems,
                                                total: artItems.total - 1,
                                            });
                                        }
                                    }}><Icon>delete</Icon></IconButton> */}
                                    </>
                                        : <></>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </InfiniteScroll>;
    } else {
        return <div></div>;
    }
});
