import React, { useState, useEffect } from "react";
import { Grid, Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ViewList from "@material-ui/icons/ViewList";
import ViewModule from "@material-ui/icons/ViewModule";
import { useSelector, useDispatch } from "../store";
import { DisplayMode } from "../store/utils";
import { setDisplayMode } from "../store/actions/display-mode";
import { routeTo, urls } from "../navigation";
import { withRouter } from "react-router";
import { deleteLoginUser } from "../store/actions/user";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { setSearchWord } from "../store/actions/searchText";
import { Paper, IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { logout } from "../services/auth.service";
import { logs } from "../services/admin.service";
import { AccountCircle, PowerSettingsNew, GetApp} from "@material-ui/icons";
// import { DownloadPdf } from "./downloadPdf";
import { saveAs } from "file-saver";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400
    },
    header: {
        backgroundColor: "#0C69B1",
        color: "white",
        alignItems: "center",
        height: "auto",
        padding: "0 4%",
        "@media (max-width: 600px)": {
            position: "fixed",
            top: 0,
            padding: "0.5rem 4%",
            zIndex: 100
        }
    },
    headerVisible: {
        top: 0,
        transition: "top 0.3s ease-out"
    },
    headerHidden: {
        top: -120,
        transition: "top 0.3s ease-out"
    },
    downloadPdf: {
        textAlign: "center",
        "& div": {
            borderRadius: "6px"
        },
        "& button": {
            border: "0 solid transparent"
        },
    },
    viewModeContainer: {
        transform: "scale(0.8)",
        transition: "transform 0.3s ease-out",
        "&:hover": {
            transform: "scale(0.9)"
        },

    },
    toggleContainer: {
        textAlign: "center",
        "& div": {
            borderRadius: "6px"
        },
        "& button": {
            border: "0 solid transparent"
        },
        "@media (max-width: 600px)": {
            display: "flex",
            justifyContent: "flex-end",
            // transform: "scale(1)",
            // "&:hover": {
            //     transform: "scale(1)"
        }
    },
    siteTitle: {
        // verticalAlign: "middle",
        // fontSize: "24pt"
    },
    globalSearch: {
        border: "2px solid rgba(255, 255, 255, 0.5)",
        borderRadius: "4px",
        "& div": {
            padding: 0,
            width: "100%",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            verticalAlign: "middle",
            boxShadow: "0 0 0 transparent",
            transition: "background-color 0.35s ease-out"
        },
        "& div:hover": {
            backgroundColor: "#025ca1"
        },
        "@media (max-width: 600px)": {
            margin: "0.35rem 0"
        }
    },
    globalSearchInput: {
        "& [placeholder]": {
            color: "#ffffff"
        }
    },
    userButtonHeader: {
        marginLeft: "1rem",
        paddingLeft: "0.75rem",
        color: "white",
        display: "inline",
        "@media (max-width: 960px)": {
            marginLeft: "0.25rem",
        }
    },
    viewToggleBtn: {
        "&[aria-pressed=\"true\"]": {
            backgroundColor: "rgba(255, 255, 255, 0.25)",
            color: "rgb(255, 255, 255)"
        },
        "&[aria-pressed=\"false\"]": {
            color: "rgba(255, 255, 255, 0.25)",
            backgroundColor: "rgba(255, 255, 255, 0)"
        },
        "&:hover": {
            backgroundColor: "#0a5997",
            color: "rgb(255, 255, 255)"
        }
    },
    siteSearchInput: {
        // marginLeft: theme.spacing(0.2),
        // flex: 1
    },
    iconButton: {
        padding: 10,
        color: "white"
    },
    siteLogo: {
        textIndent: "-100000px",
        backgroundImage: "url('/KROHNE_artgallery_logo.svg')",
        width: "200px",
        height: "50px",
        padding: "0.75rem 0",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        "@media (min-width: 600px) and (max-width: 960px)": {
            width: "150px",
            height: "35px",
        },
        "@media (max-width: 600px)": {
            backgroundImage: "url('/KROHNE_artgallery_logo_vertical.svg')",
            width: "80px",
            height: "42px",
        }
    },
    white: {
        color: "white"
    }
}));

const currentDisplayMode = (
    displayModes: Map<string, DisplayMode>
): [string, DisplayMode | undefined, string] => {
    let location = window.location.pathname;
    const page = location.split("/")[2];
    location = location.substring(0, location.lastIndexOf("/") + 1);
    return [location, displayModes.get(location), page];
};

export default withRouter(function (p) {
    const dispatch = useDispatch();
    const displayModes = useSelector(s => s.displayMode);
    const roles = useSelector(app => app.loginUser.roles);
    const classes = useStyles();
    let username = useSelector(app => app.loginUser.username);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState(true);
    const pathToken = p.location.pathname.split("/")[2];
    const searchToken = urls.searchView().split("/")[2];
    const [searchWord, setWord] = useState<string>("");

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpen(!open);
        setAnchorEl(event.currentTarget);
    };
    useEffect(() => {
        if (pathToken === searchToken) {
            const word = p.location.pathname.split("/")[3];
            setWord(word);
            dispatch(setSearchWord(word));
        }
    }, [pathToken, dispatch, searchToken, p.location.pathname]);
    const closeMenu = () => {
        setOpen(!open);
        setAnchorEl(null);
    };

    
    function getDateAndTime(): string {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        let sec = date.getSeconds();
        let time =
        year +
        "-" +
        (month < 10 ? "0" + month : month) +
        "-" +
        (day < 10 ? "0" + day : day) +
        " " +
        (hour < 10 ? "0" + hour : hour) +
        "-" +
        (min < 10 ? "0" + min : min) +
        "-" +
        (sec < 10 ? "0" + sec : sec);
        return time;
    }

    const handleDownloadLog = async () => {
        try {
            const response = await logs();
            let result = await response.data;
            if (result) {
                const blob = new Blob([result], {type: "text/plain;charset=utf-8"});
                saveAs(blob, `Log ArtGallery ${getDateAndTime()}.log`);
            }

        } catch (e) { 
            console.log(e);
        }
        closeMenu();
    };

    const handleClose = async () => {
        try {
            await logout();
            // eslint-disable-next-line no-empty
        } catch (e) { }
        dispatch(deleteLoginUser());
        routeTo(p, urls.login);
        closeMenu();
    };
    const handleViewMode = (
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        newViewMode: any
    ) => {
        const oldDisplayMode = currentDisplayMode(displayModes);
        if (newViewMode !== oldDisplayMode[1] && newViewMode != null) {
            const newDisplayMap = new Map(displayModes);
            newDisplayMap.set(oldDisplayMode[0], newViewMode);
            dispatch(setDisplayMode(newDisplayMap));

            routeTo(p, () => oldDisplayMode[0] + newViewMode);
        }
    };
    function onEnterIsPressed(e: React.KeyboardEvent<HTMLDivElement>) {
        if (e.key === "Enter") {
            searchAll();
        }
    }
    const searchDisplayMode = (
        displayModes: Map<string, DisplayMode>
    ): [string, DisplayMode | undefined] => {
        let location = urls.searchAllView({ word: searchWord });
        location = location.substring(0, location.lastIndexOf("/") + 1);
        return [location, displayModes.get(location)];
    };
    const searchAll = () => {
        if (searchWord.length) {
            const searchDisplay = searchDisplayMode(displayModes);
            if (!searchDisplay[1]) {
                const newDisplayMap = new Map(displayModes);
                newDisplayMap.set(urls.searchAllView({ word: searchWord }), DisplayMode.Tiles);
                dispatch(setDisplayMode(newDisplayMap));
                routeTo(p, () => urls.searchAllView({ word: searchWord }) + DisplayMode.Tiles);
            } else {
                routeTo(p, urls.searchAllView, { word: searchWord }, displayModes);
            }
        } else {
            routeTo(p, urls.artItemsView, [], displayModes);
        }
        dispatch(setSearchWord(searchWord));
    };
    if (!roles) {
        return <div></div>;
    }

    // Accomodate header for collapsing in mobile view!!!
    // let [position, setPosition] = useState(window.pageYOffset)
    // let [visible, setVisible] = useState(true) 
    // useEffect(()=> {
    //     const handleScroll = () => {
    //        let moving = window.pageYOffset

    //        setVisible(position > moving);
    //        setPosition(moving)
    //     };
    //     window.addEventListener("scroll", handleScroll);
    //     return(() => {
    //        window.removeEventListener("scroll", handleScroll);
    //     })
    // })

    switch (roles[0]) {
    case "visitor":
        return (
        // <Grid container className={`${classes.header} ${visible ? classes.headerVisible : classes.headerHidden}`}>
            <Grid container className={`${classes.header}`}>
                    <Grid item xs={8} sm={5} md={4} lg={3} className={classes.siteTitle}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    style={{ fontSize: "1rem" }}
                                    className={classes.siteLogo}
                                >
                                    Art Gallery
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                    className={classes.userButtonHeader}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <AccountCircle />
                                    {open ? <ExpandMore /> : <ExpandLess />}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={closeMenu}
                                >
                                    <div style={{ padding: "6px 16px" }}>Logged in as: <strong>{username}</strong></div>
                                    <MenuItem onClick={handleClose}><PowerSettingsNew style={{ marginRight: "0.5rem" }} />Logout</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={3} md={4} lg={6}></Grid>
                    <Grid item xs={12} sm={4} md={4} lg={3} className={classes.globalSearch}>
                        <Paper className={classes.root}>
                            <IconButton
                                onClick={() => searchAll()}
                                className={classes.iconButton}
                                aria-label="search"
                                style={{ padding: 6 }}
                            >
                                <SearchIcon />
                            </IconButton>
                            <InputBase
                                className={`${classes.siteSearchInput} ${classes.white}`}
                                placeholder="Find in this site..."
                                value={searchWord}
                                inputProps={{ "aria-label": "Search" }}
                                onChange={e => setWord(e.target.value)}
                                onKeyPress={e => onEnterIsPressed(e)}
                            />
                        </Paper>
                    </Grid>
            </Grid>
        );
    default:
        break;
    }
    return (
        // <Grid container className={`${classes.header} ${visible ? classes.headerVisible : classes.headerHidden}`}>
        <Grid container className={`${classes.header}`}>
            <Grid item xs={8} sm={5} md={4} lg={3} className={classes.siteTitle}>
                <Grid container alignItems="center">
                    <Grid item>
                        <Typography
                            variant="h1"
                            component="h2"
                            style={{ fontSize: "1rem" }}
                            className={classes.siteLogo}
                        >
                            Art Gallery
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            className={classes.userButtonHeader}
                            style={{ display: "flex", alignItems: "center" }}
                            title="User"
                        >
                            <AccountCircle />
                            {open ? <ExpandMore /> : <ExpandLess />}
                        </Button>
                        
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={closeMenu}
                        >
                            <div style={{ padding: "6px 16px" }}>Logged in as: <strong>{username}</strong></div>
                            <MenuItem onClick={handleDownloadLog}><GetApp style={{ marginRight: '0.5rem' }} />Download Log</MenuItem>
                            <MenuItem onClick={handleClose}><PowerSettingsNew style={{ marginRight: "0.5rem" }} />Logout</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4} sm={3} md={4} lg={6}>
                <div className={classes.toggleContainer}>
                    {currentDisplayMode(displayModes)[1] ? (
                        <div className={classes.viewModeContainer}>
                            <ToggleButtonGroup
                                value={currentDisplayMode(displayModes)[1]}
                                exclusive
                                onChange={handleViewMode}
                                aria-label="view mode"
                                style={{
                                    backgroundColor: "transparent",
                                    color: "white",
                                    border: "2.25px solid rgba(255, 255, 255, 0.5)"
                                }}
                            >
                                <ToggleButton
                                    value={DisplayMode.List}
                                    aria-label="List view"
                                    className={classes.viewToggleBtn}
                                    title="List view"
                                    >
                                    <ViewList />
                                </ToggleButton>
                                <ToggleButton
                                    value={DisplayMode.Tiles}
                                    aria-label="Tile view"
                                    className={classes.viewToggleBtn}
                                    title="Tile view"
                                    >
                                    <ViewModule />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                    ) : (
                        <div />
                    )}
                    <div className={classes.downloadPdf}>
                        {/* {currentDisplayMode(displayModes)[1] ==="list" && currentDisplayMode(displayModes)[2] === "art-item" ? <DownloadPdf /> : <div />} */}
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={3} className={classes.globalSearch}>
                <Paper className={classes.root}>
                    <IconButton
                        onClick={() => searchAll()}
                        className={classes.iconButton}
                        aria-label="search"
                        style={{ padding: 6 }}
                    >
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        className={`${classes.siteSearchInput} ${classes.white}`}
                        placeholder="Find in this site..."
                        value={searchWord}
                        inputProps={{ "aria-label": "Search" }}
                        onChange={e => setWord(e.target.value)}
                        onKeyPress={e => onEnterIsPressed(e)}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
});
