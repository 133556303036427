import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#005DA8",
            main: "#005DA8",
            dark: "#0287C0",
            contrastText: "#fff"
        },
        secondary: {
            light: "#E94D19",
            main: "#E94D19",
            dark: "#E94D19",
            contrastText: "#fff"
        }
    },
    typography: {
        h5: {
            fontSize: "1.5rem",
            fontWeight: 300
        },
        overline: {
            color: "#005DA8"
        }
    },
    overrides: {
        MuiCard: {
          root: {
            overflow: 'visible',
          },
        },
        MuiTab: {
            wrapper: {
                flexDirection: "row"
            }
        }
      },
});

//console.log(theme);
//console.log(window.innerWidth);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
