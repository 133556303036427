import React, { FunctionComponent } from "react";
import { Typography, IconButton, Grid } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";

import { ArtItem as IArtItem } from "../../models/ArtItem";
import ImageApi from "../../components/ImageApi";
import { urls } from "../../navigation";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // fontSize: 10
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: "0.3rem",
            padding: "0.3rem",
            marginBottom: "0.3rem",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            display: "grid",
            gridTemplateColumns: "90px 1fr 48px",
            gridGap: "0.5rem"
        },
        pictureContainer: {
            border: "1px solid lightgray",
            borderRadius: "0.25rem",
            backgroundColor: "#f7f7f7",
            overflow: "hidden",
            "& > img": {
                objectFit: "contain",
                width: "100%",
                height: "auto",
                objectPosition: "center"
            }
        }
    })
);

interface ArtItemProps {
    item: IArtItem;
    onAdd?: any;
    onRemove?: any;
    onSelect?: any;
    onDeselect?: any;
}

const ArtItem: FunctionComponent<ArtItemProps> = props => {
    const classes = useStyles();

    const {
        item,
        onAdd,
        onRemove,
    } = props;
    const { picture, name, location, room, id } = item;
    return (
        <Grid container className={classes.root}>
            {/* <Grid item>
                {onSelect && (
                    <IconButton onClick={onSelect}>
                        <CheckBoxOutlineBlankIcon />
                    </IconButton>
                )}
                {onDeselect && (
                    <IconButton onClick={onDeselect}>
                        <CheckBoxIcon />
                    </IconButton>
                )}
            </Grid> */}
            <Grid item className={classes.pictureContainer}>
                {picture && <ImageApi onClick={() => window.open(urls.artItemView({ id }), "_blank")} src={picture} imageType="Thumbnail" style={{ height: 90, width: 90 }}></ImageApi>}
            </Grid>
            <Grid item>
                <Typography variant="subtitle1">{name}</Typography>
                {location && <Typography variant="subtitle2">Location: {location.name}</Typography>}
                {room && <Typography variant="subtitle2">Room: {room.name ? `${room.name}-${room.number}` : room.number} </Typography>}
                {room && room.floor && (
                    <Typography variant="subtitle2">Floor: {room.floor.name}</Typography>
                )}
            </Grid>
            <Grid item style={{ alignSelf: "center" }}>
                {onAdd && (
                    <IconButton onClick={onAdd}>
                        <AddIcon />
                    </IconButton>
                )}
                {onRemove && (
                    <IconButton onClick={onRemove}>
                        <RemoveIcon />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    );
};

export default ArtItem;
