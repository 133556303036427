import React, { useState } from "react";
import "./art-item-list.css";
import MaterialTable, { Options } from "material-table";
import { withRouter } from "react-router";
import ImageApi from "../components/ImageApi";
import { loadSearchItems } from "../store/utils";
import { searchItems } from "../services/visitor.service";
import { useSelector } from "../store";
import { GalleryItem } from "../models/GalleryItem";
import { routeTo, urls } from "../navigation";
import { withoutSorting } from "../components/edit-delete-column";

export default withRouter(function (p) {
    const tableRef = React.createRef<any>();
    const word = useSelector(app => {
        if (tableRef.current) {
            tableRef.current.onQueryChange();
        }
        return app.searchWord;
    });
    const [pagination, setPagination] = useState<Options>({
        actionsColumnIndex: -1,
        selection: false,
        search: false,
        pageSize: 5
    });
    const routeToView = (e: GalleryItem) => {
        // dispatch(setSearchWord(""));
        switch (e.itemType) {
        case "ARTIST":
            routeTo(p, urls.artistView, { id: e.id!, name: e.name! });
            break;
        case "ART_ITEM":
            routeTo(p, urls.artItemView, { id: e.id!, name: e.name! });
            break;
        }
    };
    return <div className="ArtGallery-List-ArtItem table-list-pagination-bottom">
        <MaterialTable<GalleryItem>
            tableRef={tableRef}
            options={pagination}
            columns={[
                ...withoutSorting<GalleryItem>({ title: "Picture", render: (r) => r.picture ? <ImageApi src={r.picture} imageType="Thumbnail" onClick={() => routeToView(r)}></ImageApi> : <img src="/error_loading.png" alt="" width="90" height="90" onClick={() => routeToView(r)} />, sorting: false },
                    { title: "Name", field: "name" },
                    { title: "Location Name", field: "locationName" },
                    { title: "Type", field: "itemType" })
            ]}
            data={loadSearchItems(searchItems, word)}
            onChangeRowsPerPage={e => setPagination({ ...pagination, pageSize: e })}
            title="Searching all Items"
        />
    </div>;

});
