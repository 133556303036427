import React from "react";
import { ArtItem } from "./models/ArtItem";

export const defaultPages = {
    tilePageSize: 12
};

export const tablePages = {
    artItemPage: {
        page: 0,
        pageSize: 50,
    },
    placementPage: {
        page: 0,
        pageSize: 50,
    },
    companyPage: {
        page: 0,
        pageSize: 50,
    },
    locationPage: {
        page: 0,
        pageSize: 50,
    },
    artistPage: {
        page: 0,
        pageSize: 50,
    },
    userPage: {
        page: 0,
        pageSize: 50,
    },
    historyPage: {
        page: 0,
        pageSize: 50,
    },
    securityPage: {
        page: 0,
        pageSize: 50,
    },
    atributesPage: {
        page: 0,
        pageSize: 50,
    },
}

export const PaginationContext = React.createContext<[typeof defaultPages, React.Dispatch<typeof defaultPages>]>([defaultPages, null!]);

export const PagingContext = React.createContext<[typeof tablePages, React.Dispatch<typeof tablePages>]>([tablePages, () => { }]);

export const ArtItemCloneContext = React.createContext<[ArtItem, React.Dispatch<ArtItem>]>([null!, () => { }]);


