import React, { FunctionComponent } from "react";
import {
    Grid,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    Typography,
    FormLabel
} from "@material-ui/core";

import { Location } from "../../models/Location";
import { FormikProps } from "formik";
import { AddPlacementForm } from "./add";
import AutocompleteSelect from "../../components/autocomplete-selector";
import { useSelector } from "../../store";
import { TextAutoCompleteSelector } from "../../components/autocomplete-selector-text";
import { searchAutoCompleteData } from "../../services/admin.service";

// TODO: formik typings
interface DetailsFormProps {
    formikProps: FormikProps<AddPlacementForm>;
    locationsSuggestions: (key: string) => Promise<Location[]>;
}

const DetailsForm: FunctionComponent<DetailsFormProps> = ({
    locationsSuggestions,
    formikProps
}) => {
    const user = useSelector(app => app.loginUser);
    const isAdmin = user.roles![0] === "admin" || user.roles![0] === "global";

    const {
        values,//: { placementType, locationId, proposedBy, description, message },
        errors,
        touched,
        handleChange,
        setFieldTouched,
        setFieldValue,
        setValues,
    } = formikProps;
    const change = (name: keyof AddPlacementForm, e: any) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} className="inputStyle">
                <FormControl component="fieldset">
                    <Typography variant="subtitle2">Type of Placement</Typography>
                    <RadioGroup
                        aria-label="placementType"
                        name="placementType"
                        value={values.placementType}
                        onChange={(e) => change("placementType", e)}
                        style={{ flexDirection: "row" }}
                    >
                        <FormControlLabel
                            value="SEND"
                            control={<Radio />}
                            label="Send"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="REQUEST"
                            control={<Radio />}
                            label="Request"
                            labelPlacement="end"
                        />
                        {isAdmin && <FormControlLabel
                            value="BORROW"
                            control={<Radio />}
                            label="Borrow"
                            labelPlacement="end"
                        />}
                    </RadioGroup>
                </FormControl>
                <Grid item>
                    {values.placementType === "BORROW" ?
                        <FormLabel component="legend" style={{ fontSize: "14px", marginTop: "12px" }}>Suited for a short or temporary period of time placement at a museum,or an exhibition</FormLabel> : null}
                    <FormControl style={{ backgroundColor: "", width: "100%" }} className="inputStyle">
                        {values.placementType === "BORROW" ? <>
                            <TextField className="inputStyle"
                                variant="outlined"
                                fullWidth
                                required
                                type="email"
                                id="receiverEmail"
                                name="receiverEmail"
                                label="Receiver email address"
                                placeholder="Add email..."
                                onChange={change.bind(null, "receiverEmail")}
                            />
                            <TextAutoCompleteSelector
                                label="Borrow Destination"
                                required={false}
                                getSuggestions={text => searchAutoCompleteData({ text, dataType: "BORROW_DESTINATION" })}
                                onSelect={e => setFieldValue("destination", e ?? "")}
                            />
                        </> :
                            <AutocompleteSelect
                                getSuggestions={locationsSuggestions}
                                display={e => e ? e.name : ""}
                                label={values.placementType === "SEND" ? "Send to Location:" : "Request for Location:"}
                                onSelect={e => {
                                    if (e) {
                                        setValues({ ...values, locationId: e!.id });
                                        setFieldTouched("locationId", true, false);
                                    }
                                }}
                                required={false}
                            />}
                    </FormControl>
                </Grid>
                <Grid item>
                    <TextField style={{ backgroundColor: "", width: "100%" }}
                        className="inputStyle"
                        disabled
                        id="proposed-by"
                        name="proposedBy"
                        label={values.placementType === "SEND" || values.placementType === "BORROW" ? "Proposed by:" : "Requested by:"}
                        value={values.proposedBy || values.creator}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField className="inputStyle"
                        variant="outlined"
                        multiline
                        rows="3"
                        rowsMax="10"
                        fullWidth
                        id="description"
                        name="description"
                        helperText={touched.description ? errors.description : ""}
                        error={touched.description && Boolean(errors.description)}
                        label="Description"
                        placeholder="Add description..."
                        value={values.description}
                        onChange={change.bind(null, "description")}
                    />
                </Grid>
                <Grid item xs={12}>
                    {values.placementType !== "BORROW" ? <TextField className="inputStyle"
                        id="message"
                        name="message"
                        fullWidth
                        helperText={touched.message ? errors.message : ""}
                        error={touched.message && Boolean(errors.message)}
                        label="Messages"
                        placeholder="Add a first message..."
                        value={values.message}
                        onChange={change.bind(null, "message")}
                        variant="outlined"
                    /> : null}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DetailsForm;
