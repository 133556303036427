import React, {
    FunctionComponent,
    useState,
    useEffect,
} from "react";
import {
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    DialogActions,
    Button,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import { Location } from "../../models/Location";
import { ArtItem as IArtItem } from "../../models/ArtItem";

import ArtItem from "./art-item";
import { Floor } from "../../models/Floor";
import { Room } from "../../models/Room";
import AutocompleteSelect from "../../components/autocomplete-selector";
import { searchLocations, searchFloors, searchRooms } from "../../services/admin.service";
import { Form } from "formik";
import { assignNewLocation, returnBorrowedItems } from "../../services/placement.service";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollableContainer: {
            // maxHeight: 500,
            overflowY: "auto",
            " & > div > div": {
                display: "grid",
                gridTemplateColumns: "1fr auto",
                gridGap: "1rem"
            }
        },
        "@media (max-width: 777px)": {
            scrollableContainer: {
                " & > div": {
                    marginBottom: "1.5rem"
                },
                " & > div > div": {
                    display: "grid",
                    gridTemplateColumns: "1fr",
                    gridGap: "0"
                }
            },
        },
        form: {
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "auto",
        },
        searchField: {
            margin: 0,
        },
        dialogPaper: {
            maxWidth: "75vh",
            minHeight: "75vh"
        },
        parent: {
            // textAlign: "center",
            // height: "auto",
            // width: "auto",
            display: "grid",
            gridGap: "0.75rem",
            "@media (max-width: 777px)": {
                display: "flex",
                justifyContent: "flex-end"
            }
        },
        btn1: {
            // textAlign: "left",
            margin: 0,
            "@media (max-width: 777px)": {
                margin: "0"
            }
        },
        btn2: {
            // textAlign: "left",
            margin: 0,
            "@media (max-width: 777px)": {
                margin: "0 0 0 1rem"
            }
        },
        formControl: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            width: "100%",
            "& div[role='listbox'] > div": {
                width: "100%",
                maxHeight: "500px"
            },
            "& div#downshift-2-menu > div": {
                maxHeight: "300px"
            }
        },
        assignBox: {
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: "0.3rem",
            padding: "0.3rem",
            marginBottom: "0.3rem",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
        },
        innerField: {
            width: "100%"
        }
    })
);

interface ArtItemSelectorProps {
    initialSelection: IArtItem[];
    placementId: number;
    onChange: () => void;
}
export const ArtItemBorrowAssign: FunctionComponent<ArtItemSelectorProps> = ({
    initialSelection = [],
    placementId,
    onChange
}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState<IArtItem | undefined>();
    const [selectedItems, setSelectedItems] = useState<IArtItem[]>(
        initialSelection
    );
    const [location, setLocation] = useState<Location>();
    const [floor, setFloor] = useState<Floor>();
    const [room, setRoom] = useState<Room>();

    const locationSuggestions = async (key: string) => {
        const result = await searchLocations({ key, unpaged: true, sortColumn: "name", sortDirection: "ASC" });
        return result.data.content;
    };
    useEffect(() => {
        setSelectedItems(initialSelection);
    }, [initialSelection]);
    const floorSuggestions = async (key: string) => {
        if (location) {
            const result = await searchFloors({ locationIds: [location.id] });
            return result.data;
        } else {
            return [] as Floor[];
        }
    };
    const roomSuggestions = async (key: string) => {
        if (floor) {
            const result = await searchRooms({ floorIds: [floor.id] });
            return result.data;
        } else {
            return [] as Room[];
        }
    };
    const onAssignLocation = (item: IArtItem) => () => {
        setCurrentItem(item);
        setOpen(true);
    };
    const returnAllArtItems = async () => {
        const artItemIds = selectedItems.map(item => item.id);
        await returnBorrowedItems({ artItemIds, placementId });
        onChange();

    };
    const returnArtItem = (item: IArtItem) => async () => {
        await returnBorrowedItems({ artItemIds: [item.id], placementId });
        onChange();
    };

    const handleDialogClose = () => {
        setLocation(undefined);
        setFloor(undefined);
        setRoom(undefined);
        setOpen(false);
    };
    const handleDialogSave = async () => {
        await assignNewLocation({
            placementId: placementId,
            roomId: room!.id,
            artItemId: currentItem!.id,

        });
        handleDialogClose();
        onChange();
    };
    const dialog = currentItem && (
        <Dialog open={open} fullWidth={true} maxWidth="md" classes={{ paper: classes.dialogPaper }} >
            <DialogTitle id="form-dialog-title">
                Assign New Location
            </DialogTitle>
            <DialogContent>
                <Form className={classes.form}>
                    <FormControl className={classes.formControl}>
                        <AutocompleteSelect
                            getSuggestions={locationSuggestions}
                            display={o => o && o.name}
                            label="Select Location"
                            required={false}
                            onSelect={o => {
                                if (o) {
                                    setLocation(o);
                                    setFloor(undefined);
                                    setRoom(undefined);
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <AutocompleteSelect
                            getSuggestions={floorSuggestions}
                            selectedItem={floor || {} as Floor}
                            display={o => o && o.name}
                            label="Select Floor"
                            required={false}
                            onSelect={o => {
                                if (o) {
                                    setFloor(o);
                                    setRoom(undefined);
                                }
                            }}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <AutocompleteSelect
                            getSuggestions={roomSuggestions}
                            selectedItem={room || {} as Room}
                            display={o => o && o.number}
                            label="Select Room"
                            required={false}
                            onSelect={o => {
                                if (o) {
                                    setRoom(o);
                                }
                            }}
                        />
                    </FormControl>
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Close
                </Button>
                <Button disabled={room === undefined} onClick={handleDialogSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {dialog}
            <Grid item xs={12} sm={12}>
                {selectedItems.filter(e => e.status === "BORROWED").length > 0 &&
                    <Button
                        style={{ margin: "1rem 0 0 0" }}
                        variant="contained"
                        // color="primary"
                        onClick={returnAllArtItems}
                    >
                        Return all items to their previous location
                    </Button>
                }
            </Grid>
            <Grid
                container
                direction="row"
                style={{
                    border: "1px solid lightgray",
                    marginTop: "1rem",
                    borderRadius: "0.35rem"
                }}
            >
                <Grid container>
                    <Grid item xs={12} className={classes.scrollableContainer} style={{ backgroundColor: "#eee", padding: "0.5rem" }}>
                        {selectedItems.map((item, index) => <Grid container key={index}>
                            <Grid item xs={12}>
                                <ArtItem item={item} key={item.id + index + " REMOVE" + Math.random()} />
                                {/* Note: the Math.random() is there to ensure that the component will have an unique key , without it the app will have an unwanted behavior */}
                                {item.status === "BORROWED" &&
                                    <div className={classes.parent}>
                                        <div className={classes.btn1} style={{ alignSelf: "end" }}>
                                            <Button
                                                // style={{margin: "0.5rem 1rem 0.25rem 0"}}
                                                variant="contained"
                                                // color="primary"
                                                onClick={returnArtItem(item)}
                                            >
                                                Return to previous location
                                        </Button>
                                        </div>
                                        <div className={classes.btn2} style={{ alignSelf: "start" }}>
                                            <Button
                                                // style={{margin: "0.25rem 0 0.5rem"}}
                                                variant="contained"
                                                // color="primary"
                                                onClick={onAssignLocation(item)}
                                            >
                                                Assign New Location
                                        </Button>
                                        </div>
                                    </div>
                                }
                            </Grid>
                        </Grid >)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

