import React, { useContext } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

import { MTableToolbar } from "material-table/dist/components/m-table-toolbar";
import { CSSProperties, useState } from "react";
import { PagingContext, tablePages } from "./contexts";


// let mTable =MTableToolbar;
// var renderSearch = Object.getOwnPropertyDescriptor(mTable.prototype, "renderSearch")!;

interface MTableToolbarProps {
    searchText: string;
    onSearchChanged: (value: string) => void,
    searchFieldStyle: CSSProperties;
    title: string;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "2px 4px",
            marginRight: 20,
            display: "flex",
            alignItems: "center",
            width: 400,
        },
        input: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        iconButton: {
            padding: 10,
        },
    }),
);
function TableSearch(props: MTableToolbarProps) {
    const classes = useStyles();
    const [search, setSearch] = useState("");

    const [, setPagingContext] = useContext(PagingContext);
    const clearContext = () => {
        setPagingContext(tablePages);
    }
    const onEnterIsPressed = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            clearContext();
            props.onSearchChanged(search);
        }
    };

    return <Paper className={classes.root}>
        <IconButton onClick={() => {
            clearContext();
            props.onSearchChanged(search)
        }} className={classes.iconButton} aria-label="search">
            <SearchIcon />
        </IconButton>
        <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ "aria-label": "Search" }}
            onChange={e => setSearch(e.target.value)}
            onKeyPress={onEnterIsPressed}
        />
    </Paper>;
}

Object.defineProperty(MTableToolbar.prototype, "renderSearch", {
    value: function (this: { props: MTableToolbarProps }) {
        return <TableSearch {...this.props}></TableSearch>;
    }
});


